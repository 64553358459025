import { 
  collection, 
  addDoc, 
  updateDoc, 
  doc, 
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  serverTimestamp
} from 'firebase/firestore';
import { increment } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase/config';

export const modManagementService = {
  async uploadMod(file, metadata) {
    const storageRef = ref(storage, `mods/${metadata.version}/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);

    const modData = {
      ...metadata,
      fileUrl: downloadURL,
      fileName: file.name,
      fileSize: file.size,
      uploadedAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      downloadCount: 0
    };

    if (metadata.modId) {
      // New version of existing mod
      const versionRef = await addDoc(collection(db, 'mods', metadata.modId, 'versions'), {
        ...modData,
        version: metadata.version
      });

      await updateDoc(doc(db, 'mods', metadata.modId), {
        latestVersion: metadata.version,
        updatedAt: serverTimestamp()
      });

      return versionRef.id;
    } else {
      // New mod
      const modRef = await addDoc(collection(db, 'mods'), modData);
      return modRef.id;
    }
  },

  async addModFromUrl(url, metadata) {
    const modData = {
      ...metadata,
      fileUrl: url,
      uploadedAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      downloadCount: 0
    };

    if (metadata.modId) {
      // New version of existing mod
      const versionRef = await addDoc(collection(db, 'mods', metadata.modId, 'versions'), {
        ...modData,
        version: metadata.version
      });

      await updateDoc(doc(db, 'mods', metadata.modId), {
        latestVersion: metadata.version,
        updatedAt: serverTimestamp()
      });

      return versionRef.id;
    } else {
      // New mod
      const modRef = await addDoc(collection(db, 'mods'), modData);
      return modRef.id;
    }
  },

  async getModVersions(modId) {
    const versionsSnapshot = await getDocs(
      query(
        collection(db, 'mods', modId, 'versions'),
        orderBy('uploadedAt', 'desc')
      )
    );
    return versionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async getModDependencies(modId) {
    const modDoc = await getDoc(doc(db, 'mods', modId));
    const modData = modDoc.data();
    
    if (!modData.dependencies?.length) return [];

    const dependencies = await Promise.all(
      modData.dependencies.map(async depId => {
        const depDoc = await getDoc(doc(db, 'mods', depId));
        return { id: depId, ...depDoc.data() };
      })
    );

    return dependencies;
  },

  generateContentManagerUrl(downloadUrl, modName) {
    // Convert the download URL to a CM-compatible format using browser's btoa
    const encodedUrl = btoa(downloadUrl).replace(/=/g, '');
    return `acmanager://install/?url=${encodedUrl}&name=${encodeURIComponent(modName)}`;
  },

  async updateModMetadata(modId, metadata) {
    await updateDoc(doc(db, 'mods', modId), {
      ...metadata,
      updatedAt: serverTimestamp()
    });
  },

  async incrementDownloadCount(modId) {
    await updateDoc(doc(db, 'mods', modId), {
      downloadCount: increment(1)
    });
  }
};
