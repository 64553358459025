import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Divider,
  Alert,
} from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useOnboarding } from '../contexts/OnboardingContext';

export default function Auth() {
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login, signup, loginWithGoogle } = useAuth();
  const { currentUser } = useAuth();
  const { isComplete } = useOnboarding();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    username: '',
  });

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.onboarding?.welcomeTourCompleted) {
        navigate('/onboarding/welcome');
      } else if (!currentUser.onboarding?.profileCompleted) {
        navigate('/onboarding/profile');
      } else if (!currentUser.onboarding?.modRecommendationsCompleted) {
        navigate('/onboarding/recommendations');
      } else {
        navigate('/');
      }
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (isLogin) {
        await login(formData.email, formData.password);
      } else {
        await handleSignup(formData);
      }
      // navigate('/');
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const handleSignup = async (values) => {
    try {
      await signup(values.email, values.password, values.username);
      // Navigation will be handled by the useEffect above
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await loginWithGoogle();
      // Navigation will be handled by the useEffect above
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          {isLogin ? 'Login' : 'Sign Up'}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          {!isLogin && (
            <TextField
              fullWidth
              label="Username"
              margin="normal"
              value={formData.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
              required
            />
          )}

          <TextField
            fullWidth
            type="email"
            label="Email"
            margin="normal"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
          />

          <TextField
            fullWidth
            type="password"
            label="Password"
            margin="normal"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            required
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={loading}
          >
            {isLogin ? 'Login' : 'Sign Up'}
          </Button>
        </form>

        <Box sx={{ my: 2 }}>
          <Divider>OR</Divider>
        </Box>

        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleSignIn}
          sx={{ mb: 2 }}
        >
          Continue with Google
        </Button>

        <Typography align="center">
          {isLogin ? "Don't have an account? " : 'Already have an account? '}
          <Button onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? 'Sign Up' : 'Login'}
          </Button>
        </Typography>
      </Paper>
    </Container>
  );
}
