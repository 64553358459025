import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button,
  Paper,
  Alert,
  Divider,
  useTheme,
} from '@mui/material';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import ModCard from '../components/mods/ModCard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';

export default function ModDatabase() {
  const theme = useTheme();
  const [categoryMods, setCategoryMods] = useState({
    cars: [],
    driftcarpacks: [],
    tracks: []
  });
  const [topDownloadedMods, setTopDownloadedMods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search') || '';

  useEffect(() => {
    const fetchMods = async () => {
      setLoading(true);
      setError('');
      try {
        // Fetch top downloaded mods
        const topDownloadsQuery = query(
          collection(db, 'mods'),
          where('status', '==', 'approved'),
          orderBy('downloads', 'desc'),
          limit(3)
        );
        
        const topDownloadsSnapshot = await getDocs(topDownloadsQuery);
        const topMods = topDownloadsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            title: data.title || '',
            description: data.description || '',
            category: data.category || '',
            thumbnailUrl: data.screenshots && data.screenshots.length > 0 
              ? data.screenshots[0] 
              : '/placeholder-mod.jpg',
            downloads: data.downloads || 0,
            uploadDate: data.uploadedAt ? new Date(data.uploadedAt.toDate()).toLocaleDateString() : 'Unknown',
            author: data.author || 'Unknown',
            status: data.status || 'pending'
          };
        });
        setTopDownloadedMods(topMods);

        // Fetch latest mods by category
        const categories = ['cars', 'driftcarpacks', 'tracks'];
        const modsByCategory = {};

        for (const category of categories) {
          let baseQuery;
          if (searchQuery) {
            baseQuery = query(
              collection(db, 'mods'),
              where('category', '==', category),
              where('status', '==', 'approved'),
              where('searchTerms', 'array-contains', searchQuery.toLowerCase()),
              orderBy('uploadedAt', 'desc'),
              limit(3)
            );
          } else {
            baseQuery = query(
              collection(db, 'mods'),
              where('category', '==', category),
              where('status', '==', 'approved'),
              orderBy('uploadedAt', 'desc'),
              limit(3)
            );
          }

          const snapshot = await getDocs(baseQuery);
          modsByCategory[category] = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              title: data.title || '',
              description: data.description || '',
              category: data.category || '',
              thumbnailUrl: data.screenshots && data.screenshots.length > 0 
                ? data.screenshots[0] 
                : '/placeholder-mod.jpg',
              downloads: data.downloads || 0,
              uploadDate: data.uploadedAt ? new Date(data.uploadedAt.toDate()).toLocaleDateString() : 'Unknown',
              author: data.author || 'Unknown',
              status: data.status || 'pending'
            };
          });
        }

        setCategoryMods(modsByCategory);
      } catch (error) {
        console.error('Error fetching mods:', error);
        setError('Failed to load mods. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchMods();
  }, [searchQuery]);

  const categoryIcons = {
    cars: <DirectionsCarIcon sx={{ fontSize: 32, color: 'primary.main' }} />,
    driftcarpacks: <TimeToLeaveIcon sx={{ fontSize: 32, color: 'primary.main' }} />,
    tracks: <SportsMotorsportsIcon sx={{ fontSize: 32, color: 'primary.main' }} />
  };

  const categoryTitles = {
    cars: 'Car Mods',
    driftcarpacks: 'Drift Car Packs',
    tracks: 'Track Mods'
  };

  const SectionHeader = ({ title, icon, action }) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3,
        mt: 6,
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: -8,
          left: 0,
          width: '100%',
          height: '2px',
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 50%, transparent 100%)`
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {icon}
        <Typography variant="h4" component="h2" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </Box>
      {action}
    </Box>
  );

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        background: theme.palette.mode === 'dark' 
          ? 'linear-gradient(180deg, #1a1a1a 0%, #2d2d2d 100%)'
          : 'linear-gradient(180deg, #f5f5f5 0%, #ffffff 100%)',
        pt: 4,
        pb: 8
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ 
          textAlign: 'center', 
          mb: 6,
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: -16,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '200px',
            height: '4px',
            background: `linear-gradient(90deg, transparent 0%, ${theme.palette.primary.main} 50%, transparent 100%)`
          }
        }}>
          <Typography 
            variant="h2" 
            component="h1" 
            sx={{ 
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 2
            }}
          >
            Mod Database
          </Typography>
          <Typography variant="h6" color="text.secondary">
            Discover and download the best mods for AC Drifting Pro
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {/* Top Downloaded Mods Section */}
        {topDownloadedMods.length > 0 && (
          <Box sx={{ mb: 8 }}>
            <SectionHeader
              title="Most Popular Mods"
              icon={<TrendingUpIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
              action={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                    Sorted by downloads
                  </Typography>
                  <TrendingUpIcon color="primary" />
                </Box>
              }
            />
            
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3,
                background: theme.palette.mode === 'dark' 
                  ? 'linear-gradient(145deg, rgba(30,30,30,0.8) 0%, rgba(60,60,60,0.4) 100%)'
                  : 'linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)',
                backdropFilter: 'blur(10px)',
                borderRadius: 2
              }}
            >
              <Grid container spacing={3}>
                {topDownloadedMods.map((mod) => (
                  <Grid item xs={12} sm={6} md={4} key={mod.id}>
                    <ModCard mod={mod} />
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Box>
        )}

        {/* Latest Mods by Category */}
        {Object.entries(categoryMods).map(([category, mods]) => (
          <Box key={category} sx={{ mb: 8 }}>
            <SectionHeader
              title={`Latest ${categoryTitles[category]}`}
              icon={categoryIcons[category]}
              action={
                <Button 
                  component={RouterLink} 
                  to={`/mods/${category}`}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  sx={{ 
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                    '&:hover': {
                      background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                    }
                  }}
                >
                  View All
                </Button>
              }
            />
            
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3,
                background: theme.palette.mode === 'dark' 
                  ? 'linear-gradient(145deg, rgba(30,30,30,0.8) 0%, rgba(60,60,60,0.4) 100%)'
                  : 'linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)',
                backdropFilter: 'blur(10px)',
                borderRadius: 2
              }}
            >
              <Grid container spacing={3}>
                {mods.length > 0 ? (
                  mods.map((mod) => (
                    <Grid item xs={12} sm={6} md={4} key={mod.id}>
                      <ModCard mod={mod} />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Box sx={{ 
                      p: 4, 
                      textAlign: 'center',
                      background: theme.palette.mode === 'dark' 
                        ? 'rgba(0,0,0,0.2)' 
                        : 'rgba(0,0,0,0.05)',
                      borderRadius: 1
                    }}>
                      <Typography color="text.secondary">
                        No mods found in this category
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Box>
        ))}
      </Container>
    </Box>
  );
}
