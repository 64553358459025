import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Avatar,
  Rating,
  Snackbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '@mui/material/styles';
import SubscriptionPlan from '../components/subscription/SubscriptionPlan';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PayPalSubscribeButton from '../components/subscription/PayPalSubscribeButton';
import SpeedIcon from '@mui/icons-material/Speed';
import StarIcon from '@mui/icons-material/Star';
import SupportIcon from '@mui/icons-material/Support';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

// Testimonials data
const testimonials = [
  {
    name: "Alex Thompson",
    avatar: "https://i.pravatar.cc/150?img=11",
    rating: 5,
    text: "The premium features have completely transformed my drifting experience. The unlimited download speeds and instant access to new mods keep me ahead of the competition.",
    role: "Professional Drifter"
  },
  {
    name: "Sarah Chen",
    avatar: "https://i.pravatar.cc/150?img=5",
    rating: 5,
    text: "Being able to download mods instantly without any waiting period has made practicing and improving so much more efficient. Best investment for any serious drifter!",
    role: "Drift Competition Winner"
  },
  {
    name: "Mike Rodriguez",
    avatar: "https://i.pravatar.cc/150?img=8",
    rating: 5,
    text: "The premium membership gives me early access to the latest mods. It's like being part of an exclusive club where you get the best content first.",
    role: "Content Creator"
  },
  {
    name: "Emma Wilson",
    avatar: "https://i.pravatar.cc/150?img=9",
    rating: 5,
    text: "The ad-free experience and unlimited speeds make downloading and testing new mods a breeze. It's a must-have for any serious AC drifting enthusiast.",
    role: "Drift Team Leader"
  }
];

const plans = {
  free: {
    type: 'free',
    price: 0,
    period: 'month',
    features: [
      { text: 'Limited download speed (1MB/s)', enabled: true },
      { text: 'Ads displayed', enabled: true },
      { text: '60 Seconds waiting period between downloads', enabled: true },
      { text: 'Basic mod access', enabled: true },
      { text: 'Community support', enabled: true },
    ],
  },
  premium: {
    type: 'premium',
    price: 9.99,
    period: 'month',
    features: [
      { text: 'Unlimited download speed', enabled: true },
      { text: 'Ad-free experience', enabled: true },
      { text: 'Instant downloads', enabled: true },
      { text: 'Early access to new mods', enabled: true },
      { text: 'Priority support', enabled: true },
      { text: 'Exclusive premium mods', enabled: true },
      { text: 'Download history and favorites', enabled: true },
    ],
  },
};

export default function Premium() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const theme = useTheme();
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth', { state: { from: '/premium' } });
    }
  }, [currentUser, navigate]);

  const handlePlanSelect = (planType) => {
    if (!currentUser) {
      navigate('/auth', { state: { from: '/premium' } });
      return;
    }

    setSelectedPlan(planType);
    if (planType === 'premium') {
      setShowPaymentDialog(true);
    }
  };

  const handleSubscriptionSuccess = (details) => {
    const safeDetails = {
      status: 'success',
      timestamp: new Date().toISOString(),
      type: details.type
    };
    console.log('Subscription status:', safeDetails);
    
    setShowPaymentDialog(false);
    setAlert({
      open: true,
      message: 'Successfully subscribed to Premium! Redirecting to your profile...',
      severity: 'success'
    });
    setTimeout(() => {
      navigate('/profile');
    }, 2000);
  };

  const handleSubscriptionError = (error) => {
    console.error('Subscription error: Please check your payment details and try again');
    setAlert({
      open: true,
      message: 'Failed to process subscription. Please try again.',
      severity: 'error'
    });
  };

  const handleSubscriptionCancel = () => {
    console.log('Subscription process cancelled by user');
    setAlert({
      open: true,
      message: 'Subscription cancelled.',
      severity: 'info'
    });
    setShowPaymentDialog(false);
  };

  const handleCloseAlert = () => {
    setAlert(prev => ({ ...prev, open: false }));
  };

  const premiumStats = [
    { value: '10x', label: 'Faster Downloads' },
    { value: '1000+', label: 'Premium Mods' },
    { value: '24/7', label: 'Priority Support' },
    { value: '0s', label: 'Wait Time' },
  ];

  const handleNextTestimonial = () => {
    setCurrentTestimonialIndex((prev) => 
      prev === testimonials.length - 1 ? 0 : prev + 1
    );
  };

  const handlePrevTestimonial = () => {
    setCurrentTestimonialIndex((prev) => 
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  return (
    <>
      {/* Hero Section with Dynamic Background */}
      <Box
        sx={{
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          background: '#000',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(135deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.5) 100%)',
            zIndex: 1,
          },
        }}
      >
        {/* Animated Background */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url(/img/bg1.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            animation: 'zoomInOut 20s infinite alternate',
            '@keyframes zoomInOut': {
              '0%': {
                transform: 'scale(1)',
              },
              '100%': {
                transform: 'scale(1.1)',
              },
            },
          }}
        />

        {/* Glowing Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at 50% 50%, rgba(255,107,107,0.1) 0%, rgba(78,205,196,0.1) 100%)',
            zIndex: 2,
          }}
        />

        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 3, py: 15 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              {/* Main Content */}
              <Box>
                {/* Premium Badge */}
                <Box
                  sx={{
                    display: 'inline-block',
                    background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, #ffffff 99%)`,
                    borderRadius: '50px',
                    px: 3,
                    py: 1,
                    mb: 4,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'white',
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      letterSpacing: 1,
                    }}
                  >
                    Premium Membership
                  </Typography>
                </Box>

                {/* Main Title */}
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '2.5rem', md: '4rem', lg: '5rem' },
                    lineHeight: 1.2,
                    mb: 3,
                    background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, #ffffff 90%)`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '0 0 20px rgba(255,107,107,0.3)',
                  }}
                >
                  Elevate Your
                  <br />
                  Drifting Experience
                </Typography>

                {/* Subtitle */}
                <Typography
                  variant="h4"
                  sx={{
                    color: 'rgba(255,255,255,0.9)',
                    fontWeight: 300,
                    mb: 4,
                    maxWidth: '600px',
                  }}
                >
                  Join the elite community of drift enthusiasts
                </Typography>

                {/* CTA Buttons */}
                <Box sx={{ display: 'flex', gap: 2, mb: 6 }}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => handlePlanSelect('premium')}
                    sx={{
                      px: 4,
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, #ffffff 90%)`,
                      borderRadius: '50px',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    Get Premium Now
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => navigate('/mods')}
                    sx={{
                      py: 2,
                      px: 4,
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      color: 'white',
                      borderColor: 'rgba(255,255,255,0.5)',
                      borderRadius: '50px',
                      '&:hover': {
                        borderColor: '#4ECDC4',
                        backgroundColor: 'rgba(78,205,196,0.1)',
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    Explore Mods
                  </Button>
                </Box>

                {/* Premium Stats */}
                <Grid container spacing={3}>
                  {premiumStats.map((stat, index) => (
                    <Grid item xs={6} sm={3} key={index}>
                      <Box
                        sx={{
                          background: 'rgba(255,255,255,0.1)',
                          backdropFilter: 'blur(10px)',
                          borderRadius: 2,
                          p: 2,
                          textAlign: 'center',
                          transition: 'transform 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                          },
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            color: 'white',
                            fontWeight: 700,
                            mb: 1,
                            background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, #ffffff 90%)`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                          }}
                        >
                          {stat.value}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: 'rgba(255,255,255,0.8)',
                            fontWeight: 500,
                          }}
                        >
                          {stat.label}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>

            {/* Right Side Image/Animation */}
            <Grid item xs={12} md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Box
                sx={{
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '400px',
                    height: '400px',
                    background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, #ffffff 90%)`,
                    borderRadius: '50%',
                    filter: 'blur(60px)',
                  },
                }}
              >
                <img
                  src="/img/logo.png"
                  alt="Premium Drift Car"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '500px',
                    transform: 'rotate(-5deg)',
                    animation: 'float 6s ease-in-out infinite',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <style>
        {`
          @keyframes float {
            0% {
              transform: translateY(0) rotate(-5deg);
            }
            50% {
              transform: translateY(-20px) rotate(-5deg);
            }
            100% {
              transform: translateY(0) rotate(-5deg);
            }
          }
        `}
      </style>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}

        {/* Premium Benefits Section with Icons */}
        <Box sx={{ mb: 12 }}>
        <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 4
            }}
          >
          Premium Advantages
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  height: '100%',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }}
              >
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                  <SpeedIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
                </Box>
                <Typography variant="h5" gutterBottom align="center">
                  Lightning-Fast Downloads
                </Typography>
                <Typography color="text.secondary" align="center">
                  Experience downloads up to 10x faster than free tier. No speed limits, no throttling.
                  Get your favorite mods instantly and spend more time drifting.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  height: '100%',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }}
              >
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                  <StarIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
                </Box>
                <Typography variant="h5" gutterBottom align="center">
                  Exclusive Content
                </Typography>
                <Typography color="text.secondary" align="center">
                  Get access to premium-only mods, early releases, and special editions.
                  Be the first to try new content and experience unique drifting challenges.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  height: '100%',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }}
              >
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                  <SupportIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
                </Box>
                <Typography variant="h5" gutterBottom align="center">
                  VIP Treatment
                </Typography>
                <Typography color="text.secondary" align="center">
                  Enjoy priority support, exclusive Discord channels, and direct access to mod creators.
                  Your questions and issues are our top priority.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* Plan Comparison */}
        <Box sx={{ mb: 12 }}>
        <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 4
            }}
          >
           Choose Your Plan
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <SubscriptionPlan
                {...plans.free}
                current={currentUser?.subscription?.type === 'free'}
                onSelect={handlePlanSelect}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SubscriptionPlan
                {...plans.premium}
                current={currentUser?.subscription?.type === 'premium'}
                onSelect={handlePlanSelect}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Detailed Features Table */}
        <Box sx={{ mb: 12 }}>
        <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 4
            }}
          >
          Feature Comparison
          </Typography>
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Feature</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Free</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Premium</Typography>
              </Grid>
            </Grid>
            {[
              {
                feature: 'Download Speed',
                free: 'Slow',
                premium: 'Unlimited (100x faster)',
                description: 'Experience blazing-fast downloads with our premium servers'
              },
              {
                feature: 'Waiting Period',
                free: '60 Seconds timer before downloads start, 30 Seconds if you are loged in.',
                premium: 'No waiting time',
                description: 'Download as many mods as you want, whenever you want'
              },
              {
                feature: 'Mod Access',
                free: 'Basic mods only',
                premium: 'All mods + Exclusive content',
                description: 'Get access to our entire library including premium-only content'
              },
              {
                feature: 'Support Priority',
                free: 'Community support',
                premium: '24/7 Priority support',
                description: 'Get help when you need it with dedicated support channels'
              },
              {
                feature: 'Early Access',
                free: 'No',
                premium: 'Yes (7 days early)',
                description: 'Be the first to try new mods and updates'
              },
              {
                feature: 'Advertising',
                free: 'Ads shown',
                premium: 'Ad-free experience',
                description: 'Enjoy a clean, distraction-free browsing experience'
              },
              {
                feature: 'Download History',
                free: 'Last 7 days',
                premium: 'Unlimited + Favorites',
                description: 'Keep track of all your downloads and favorite mods'
              },
              {
                feature: 'Discord Access',
                free: 'Basic channels',
                premium: 'Premium channels + Direct mod creator access',
                description: 'Connect with the community and mod creators directly'
              }
            ].map((item, index) => (
              <Box key={index} sx={{ mt: 3, pb: 2, borderBottom: index !== 7 ? 1 : 0, borderColor: 'divider' }}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>{item.feature}</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>{item.description}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color="text.secondary">{item.free}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color="primary" sx={{ fontWeight: 'medium' }}>{item.premium}</Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Paper>
        </Box>

        {/* Testimonials Section */}
        <Box sx={{ mt: 8, mb: 4 }}>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 4
            }}
          >
            What Our Members Say
          </Typography>

          <Box
            sx={{
              position: 'relative',
              maxWidth: 800,
              mx: 'auto',
              px: 4
            }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 4,
                borderRadius: 4,
                background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              <FormatQuoteIcon
                sx={{
                  position: 'absolute',
                  top: 20,
                  left: 20,
                  fontSize: '3rem',
                  opacity: 0.1,
                  color: theme.palette.primary.main
                }}
              />
              
              <Box sx={{ textAlign: 'center' }}>
                <Avatar
                  src={testimonials[currentTestimonialIndex].avatar}
                  sx={{
                    width: 100,
                    height: 100,
                    mx: 'auto',
                    mb: 2,
                    border: `3px solid ${theme.palette.primary.main}`
                  }}
                />
                
                <Typography variant="h5" gutterBottom>
                  {testimonials[currentTestimonialIndex].name}
                </Typography>
                
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.primary.main, mb: 2 }}
                >
                  {testimonials[currentTestimonialIndex].role}
                </Typography>
                
                <Rating
                  value={testimonials[currentTestimonialIndex].rating}
                  readOnly
                  sx={{ mb: 2 }}
                />
                
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: 'italic',
                    mb: 3,
                    maxWidth: 600,
                    mx: 'auto',
                    lineHeight: 1.8
                  }}
                >
                  "{testimonials[currentTestimonialIndex].text}"
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  mt: 2
                }}
              >
                <Button
                  onClick={handlePrevTestimonial}
                  sx={{
                    minWidth: 'auto',
                    p: 1,
                    borderRadius: '50%',
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    '&:hover': {
                      background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
                    }
                  }}
                >
                  <KeyboardArrowLeft />
                </Button>
                
                <Button
                  onClick={handleNextTestimonial}
                  sx={{
                    minWidth: 'auto',
                    p: 1,
                    borderRadius: '50%',
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    '&:hover': {
                      background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
                    }
                  }}
                >
                  <KeyboardArrowRight />
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box>

        {/* FAQ Section */}
        <Box sx={{ mb: 8 }}>
        <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 4
            }}
          >
          Frequently Asked Questions
          </Typography>
          <Grid container spacing={3}>
            {[
              {
                question: "How does the premium subscription work?",
                answer: "Once you subscribe, you'll instantly get access to all premium features including faster downloads, exclusive content, and priority support. Your subscription will automatically renew monthly, and you can cancel anytime."
              },
              {
                question: "Can I upgrade from free to premium later?",
                answer: "Yes! You can upgrade to premium at any time. Your premium benefits will be activated immediately after successful payment processing."
              },
              {
                question: "What payment methods do you accept?",
                answer: "We accept all major credit cards and PayPal for premium subscriptions. All payments are processed securely through PayPal's payment system."
              }
            ].map((faq, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom color="primary">
                    {faq.question}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {faq.answer}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Call to Action */}
        <Box 
          sx={{ 
            textAlign: 'center',
            py: 8,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            borderRadius: 4,
            color: 'white',
            mb: 4
          }}
        >
          <Typography variant="h4" gutterBottom>
            Ready to Enhance Your Drifting Experience?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
            Join thousands of satisfied premium members today
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => handlePlanSelect('premium')}
            sx={{
              py: 2,
              px: 6,
              fontSize: '1.2rem',
              backgroundColor: 'white',
              color: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.9)',
              },
            }}
          >
            Upgrade to Premium Now
          </Button>
        </Box>

        {/* PayPal Subscription Dialog */}
        <Dialog
          open={showPaymentDialog}
          onClose={() => setShowPaymentDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            <Typography variant="h5" component="div" align="center">
              Subscribe to Premium
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ textAlign: 'center', mb: 3 }}>
              <Typography variant="h4" component="div" color="primary" gutterBottom>
                ${plans.premium.price}/{plans.premium.period}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                You're about to upgrade to Premium and unlock all exclusive features
              </Typography>
            </Box>
            <PayPalScriptProvider options={{
              "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || "AQBX6XlikWkBRvGs4_3Hw9EdTxpAPvScBR3qf_VJNfSMTWA_9lm9_IFgJjaFph9DKkncIoGqCTNbRp91",
              intent: "subscription",
              vault: true,
            }}>
              <PayPalSubscribeButton
                onSuccess={handleSubscriptionSuccess}
                onError={handleSubscriptionError}
                onCancel={handleSubscriptionCancel}
              />
            </PayPalScriptProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPaymentDialog(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* Alert Snackbar */}
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleCloseAlert}
            severity={alert.severity}
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
