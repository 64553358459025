import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Avatar, Stack } from '@mui/material';
import { ThumbUp, Reply } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import { commentsService } from '../../services/communityService';
import { formatDistanceToNow } from 'date-fns';

function Comment({ comment, onReply, onLike }) {
  const { currentUser } = useAuth();
  const isLiked = comment.likedBy?.includes(currentUser?.uid);

  return (
    <Box sx={{ mb: 2, pl: comment.parentId ? 4 : 0 }}>
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Avatar src={comment.userAvatar} alt={comment.userName} />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2" fontWeight="bold">
            {comment.userName}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {formatDistanceToNow(comment.createdAt?.toDate(), { addSuffix: true })}
          </Typography>
          <Typography variant="body1" paragraph>
            {comment.content}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              size="small"
              startIcon={<ThumbUp />}
              onClick={() => onLike(comment.id)}
              color={isLiked ? "primary" : "inherit"}
            >
              {comment.likes} Likes
            </Button>
            <Button
              size="small"
              startIcon={<Reply />}
              onClick={() => onReply(comment.id)}
            >
              Reply
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default function Comments({ modId }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [replyTo, setReplyTo] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    loadComments();
  }, [modId]);

  const loadComments = async () => {
    const fetchedComments = await commentsService.getModComments(modId);
    setComments(fetchedComments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      await commentsService.addComment(modId, currentUser.uid, newComment, replyTo);
      setNewComment('');
      setReplyTo(null);
      loadComments();
    } catch (error) {
      console.error('Error posting comment:', error);
    }
  };

  const handleLike = async (commentId) => {
    try {
      const comment = comments.find(c => c.id === commentId);
      if (comment.likedBy?.includes(currentUser.uid)) {
        await commentsService.unlikeComment(commentId, currentUser.uid);
      } else {
        await commentsService.likeComment(commentId, currentUser.uid);
      }
      loadComments();
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  const handleReply = (commentId) => {
    setReplyTo(commentId);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Comments
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder={replyTo ? "Write a reply..." : "Write a comment..."}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Stack direction="row" spacing={2}>
          <Button type="submit" variant="contained">
            Post {replyTo ? 'Reply' : 'Comment'}
          </Button>
          {replyTo && (
            <Button onClick={() => setReplyTo(null)}>
              Cancel Reply
            </Button>
          )}
        </Stack>
      </Box>

      <Stack spacing={2}>
        {comments.map(comment => (
          <React.Fragment key={comment.id}>
            <Comment
              comment={comment}
              onReply={handleReply}
              onLike={handleLike}
            />
            {comments
              .filter(reply => reply.parentId === comment.id)
              .map(reply => (
                <Comment
                  key={reply.id}
                  comment={reply}
                  onReply={handleReply}
                  onLike={handleLike}
                />
              ))}
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
}
