import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondary,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Alert
} from '@mui/material';
import { Delete as DeleteIcon, CloudUpload, Link as LinkIcon } from '@mui/icons-material';

export default function ModUploader({ onFileSelect, selectedFile, onFileRemove }) {
  const [uploadType, setUploadType] = useState('file');
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState('');

  const handleUploadTypeChange = (event, newType) => {
    if (newType !== null) {
      setUploadType(newType);
      onFileRemove();
      setUrl('');
      setUrlError('');
    }
  };

  const handleUrlSubmit = () => {
    // Basic URL validation
    try {
      new URL(url);
      setUrlError('');
      onFileSelect({ 
        isUrl: true, 
        url: url,
        name: url.split('/').pop() || 'External mod',
        preview: url
      });
    } catch (e) {
      setUrlError('Please enter a valid URL');
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      onFileSelect({
        isUrl: false,
        file: acceptedFiles[0],
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size
      });
    }
  }, [onFileSelect]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/zip': ['.zip'],
      'application/x-rar-compressed': ['.rar'],
      'application/x-7z-compressed': ['.7z']
    }
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
        <ToggleButtonGroup
          value={uploadType}
          exclusive
          onChange={handleUploadTypeChange}
          aria-label="upload method"
        >
          <ToggleButton value="file" aria-label="file upload">
            <CloudUpload sx={{ mr: 1 }} />
            File Upload
          </ToggleButton>
          <ToggleButton value="url" aria-label="url input">
            <LinkIcon sx={{ mr: 1 }} />
            URL Input
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {uploadType === 'file' ? (
        <Paper
          {...getRootProps()}
          sx={{
            p: 3,
            border: '2px dashed',
            borderColor: isDragActive ? 'primary.main' : 'grey.500',
            bgcolor: isDragActive ? 'action.hover' : 'background.paper',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
              borderColor: 'primary.main',
              bgcolor: 'action.hover'
            }
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 150
            }}
          >
            {isDragActive ? (
              <Typography variant="h6" color="primary">
                Drop the mod file here...
              </Typography>
            ) : (
              <Typography variant="h6" color="textSecondary">
                Drag and drop your mod file here, or click to select
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Supported formats: .zip, .rar, .7z
            </Typography>
          </Box>
        </Paper>
      ) : (
        <Box sx={{ width: '100%' }}>
          <TextField
            fullWidth
            label="Mod URL"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              if (urlError) setUrlError('');
            }}
            onBlur={handleUrlSubmit}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleUrlSubmit();
              }
            }}
            error={!!urlError}
            helperText={urlError || 'Enter the URL of your mod file'}
            sx={{ mb: 2 }}
          />
        </Box>
      )}

      {selectedFile && (
        <List>
          <ListItem
            secondaryAction={
              <IconButton edge="end" onClick={() => {
                onFileRemove();
                if (uploadType === 'url') setUrl('');
              }}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={selectedFile.name}
              secondary={selectedFile.isUrl ? 'External URL' : `${(selectedFile.size / (1024 * 1024)).toFixed(2)} MB`}
            />
          </ListItem>
        </List>
      )}
    </Box>
  );
}
