import React from 'react';
import {
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Check as CheckIcon,
  Block as BlockIcon,
} from '@mui/icons-material';

const SubscriptionPlan = ({
  type,
  price,
  period,
  features,
  current,
  onSelect,
  loading,
}) => {
  const isFreePlan = type === 'free';

  return (
    <Paper
      elevation={isFreePlan ? 1 : 8}
      sx={{ 
        p: 3, 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: isFreePlan ? 'none' : 'translateY(-5px)'
        }
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom align="center">
        {isFreePlan ? 'Free Plan' : 'Premium Plan'}
      </Typography>

      <Typography variant="h4" component="div" align="center" sx={{ mb: 2 }}>
        {isFreePlan ? 'Free' : `$${price}/${period}`}
      </Typography>

      <List sx={{ flexGrow: 1 }}>
        {features.map((feature, index) => (
          <ListItem key={index} sx={{ py: 1 }}>
            <ListItemIcon>
              {feature.enabled ? (
                <CheckIcon color="success" />
              ) : (
                <BlockIcon color="error" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={feature.text}
              sx={{
                '& .MuiListItemText-primary': {
                  color: feature.enabled ? 'text.primary' : 'text.secondary'
                }
              }}
            />
          </ListItem>
        ))}
      </List>

      {!isFreePlan && !current && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSelect(type)}
          disabled={loading}
          sx={{
            py: 1.5,
            mt: 2,
            fontWeight: 600,
            fontSize: '1.1rem',
            '&:hover': {
              transform: 'scale(1.02)'
            }
          }}
        >
          Get Premium Now
        </Button>
      )}

      {current && (
        <Button
          variant="outlined"
          disabled
          sx={{ mt: 2 }}
        >
          Current Plan
        </Button>
      )}
    </Paper>
  );
};

export default SubscriptionPlan;
