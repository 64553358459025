import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Pagination,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import {
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
  getCountFromServer,
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import ModCard from '../../components/mods/ModCard';

export default function CarMods() {
  const [mods, setMods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [lastDoc, setLastDoc] = useState(null);
  const [sortBy, setSortBy] = useState('newest');
  const modsPerPage = 12;

  const fetchTotalPages = async () => {
    try {
      const baseQuery = query(
        collection(db, 'mods'),
        where('category', '==', 'cars'),
        where('status', '==', 'approved')
      );
      const snapshot = await getCountFromServer(baseQuery);
      const total = snapshot.data().count;
      setTotalPages(Math.max(1, Math.ceil(total / modsPerPage)));
    } catch (error) {
      console.error('Error fetching total count:', error);
    }
  };

  const fetchMods = async () => {
    setLoading(true);
    try {
      let sortOptions;
      switch (sortBy) {
        case 'oldest':
          sortOptions = ['uploadedAt', 'asc'];
          break;
        case 'downloads':
          sortOptions = ['downloads', 'desc'];
          break;
        case 'newest':
        default:
          sortOptions = ['uploadedAt', 'desc'];
          break;
      }

      let baseQuery = query(
        collection(db, 'mods'),
        where('category', '==', 'cars'),
        where('status', '==', 'approved'),
        orderBy(sortOptions[0], sortOptions[1]),
        limit(modsPerPage)
      );

      if (page > 1 && lastDoc) {
        baseQuery = query(
          collection(db, 'mods'),
          where('category', '==', 'cars'),
          where('status', '==', 'approved'),
          orderBy(sortOptions[0], sortOptions[1]),
          startAfter(lastDoc),
          limit(modsPerPage)
        );
      }

      const snapshot = await getDocs(baseQuery);
      if (snapshot.empty) {
        setMods([]);
        return;
      }

      const modsList = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          thumbnailUrl: data.screenshots && data.screenshots.length > 0 
            ? data.screenshots[0] 
            : '/placeholder-mod.jpg',
          downloads: data.downloads || 0,
          uploadDate: data.uploadedAt ? new Date(data.uploadedAt.toDate()).toLocaleDateString() : 'Unknown'
        };
      });

      setMods(modsList);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

      if (page === 1) {
        fetchTotalPages();
      }
    } catch (error) {
      console.error('Error fetching mods:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMods();
  }, [page, sortBy]);

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setPage(1);
    setLastDoc(null);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Car Mods
        </Typography>
        
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="sort-select-label">Sort By</InputLabel>
          <Select
            labelId="sort-select-label"
            value={sortBy}
            label="Sort By"
            onChange={handleSortChange}
          >
            <MenuItem value="newest">Newest First</MenuItem>
            <MenuItem value="oldest">Oldest First</MenuItem>
            <MenuItem value="downloads">Most Downloads</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container spacing={3}>
                {mods.map((mod) => (
                  <Grid item xs={12} sm={6} md={4} key={mod.id}>
                    <ModCard mod={mod} />
                  </Grid>
                ))}
              </Grid>

              {totalPages > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Pagination 
                    count={totalPages} 
                    page={page} 
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
