import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Paper,
  Avatar,
  Grid,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import {
  PhotoCamera as PhotoCameraIcon,
  NavigateNext as NextIcon,
  NavigateBefore as BackIcon,
} from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase/config';

const steps = ['Basic Info', 'Preferences', 'Experience Level', 'Finish'];

const driftingStyles = [
  'Street', 'Competition', 'Tandem', 'Grip/Drift', 'Freestyle',
  'Mountain Pass', 'Circuit', 'Casual'
];

const experienceLevels = [
  'Beginner',
  'Intermediate',
  'Advanced',
  'Professional',
  'Instructor'
];

export default function ProfileWizard() {
  const navigate = useNavigate();
  const { currentUser, updateUserProfile } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [profileData, setProfileData] = useState({
    displayName: currentUser?.displayName || '',
    bio: '',
    avatar: currentUser?.photoURL || '',
    preferredStyles: [],
    favoriteCarBrands: [],
    experienceLevel: '',
    yearsOfExperience: '',
    preferredControls: '',
    customControls: '',
  });
  const [avatarFile, setAvatarFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStyleToggle = (style) => {
    setProfileData(prev => ({
      ...prev,
      preferredStyles: prev.preferredStyles.includes(style)
        ? prev.preferredStyles.filter(s => s !== style)
        : [...prev.preferredStyles, style]
    }));
  };

  const handleAvatarChange = (event) => {
    if (event.target.files[0]) {
      setAvatarFile(event.target.files[0]);
      setProfileData(prev => ({
        ...prev,
        avatar: URL.createObjectURL(event.target.files[0])
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const uploadAvatar = async () => {
    if (!avatarFile) return null;
    
    const avatarRef = ref(storage, `avatars/${currentUser.uid}/${avatarFile.name}`);
    await uploadBytes(avatarRef, avatarFile);
    return getDownloadURL(avatarRef);
  };

  const handleFinish = async () => {
    setLoading(true);
    try {
      // Upload avatar if changed
      let avatarUrl = profileData.avatar;
      if (avatarFile) {
        avatarUrl = await uploadAvatar();
      }

      // Update user profile in Firestore
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        displayName: profileData.displayName,
        bio: profileData.bio,
        photoURL: avatarUrl,
        preferredStyles: profileData.preferredStyles,
        favoriteCarBrands: profileData.favoriteCarBrands,
        experienceLevel: profileData.experienceLevel,
        yearsOfExperience: profileData.yearsOfExperience,
        preferredControls: profileData.preferredControls,
        customControls: profileData.customControls,
        'onboarding.profileCompleted': true,
        'onboarding.profileCompletedAt': new Date().toISOString(),
        'onboarding.lastStep': 'recommendations'
      });

      // Navigate to mod recommendations
      navigate('/onboarding/recommendations');
    } catch (error) {
      console.error('Error saving profile:', error);
      // Handle error appropriately
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box position="relative">
                <Avatar
                  src={profileData.avatar}
                  sx={{ width: 120, height: 120 }}
                />
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="avatar-upload"
                  type="file"
                  onChange={handleAvatarChange}
                />
                <label htmlFor="avatar-upload">
                  <IconButton
                    component="span"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      backgroundColor: 'primary.main',
                      '&:hover': { backgroundColor: 'primary.dark' },
                    }}
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Display Name"
                name="displayName"
                value={profileData.displayName}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Bio"
                name="bio"
                value={profileData.bio}
                onChange={handleInputChange}
                multiline
                rows={4}
                placeholder="Tell us about yourself and your drifting journey..."
              />
            </Grid>
          </Grid>
        );

      case 1:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Preferred Drifting Styles
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {driftingStyles.map((style) => (
                  <Chip
                    key={style}
                    label={style}
                    onClick={() => handleStyleToggle(style)}
                    color={profileData.preferredStyles.includes(style) ? "primary" : "default"}
                    variant={profileData.preferredStyles.includes(style) ? "filled" : "outlined"}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Favorite Car Brands"
                name="favoriteCarBrands"
                value={profileData.favoriteCarBrands}
                onChange={handleInputChange}
                placeholder="e.g., Nissan, Toyota, BMW (comma-separated)"
              />
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Experience Level</InputLabel>
                <Select
                  name="experienceLevel"
                  value={profileData.experienceLevel}
                  onChange={handleInputChange}
                  label="Experience Level"
                >
                  {experienceLevels.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Years of Experience"
                name="yearsOfExperience"
                type="number"
                value={profileData.yearsOfExperience}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Preferred Controls</InputLabel>
                <Select
                  name="preferredControls"
                  value={profileData.preferredControls}
                  onChange={handleInputChange}
                  label="Preferred Controls"
                >
                  <MenuItem value="keyboard">Keyboard</MenuItem>
                  <MenuItem value="gamepad">Gamepad</MenuItem>
                  <MenuItem value="wheel">Steering Wheel</MenuItem>
                  <MenuItem value="custom">Custom Setup</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {profileData.preferredControls === 'custom' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Custom Controls Setup"
                  name="customControls"
                  value={profileData.customControls}
                  onChange={handleInputChange}
                  multiline
                  rows={3}
                  placeholder="Describe your custom controls setup..."
                />
              </Grid>
            )}
          </Grid>
        );

      case 3:
        return (
          <Box textAlign="center">
            <Typography variant="h6" gutterBottom>
              You're all set! 🎉
            </Typography>
            <Typography color="text.secondary">
              We'll use your preferences to recommend the best mods for you.
              You can always update your profile later.
            </Typography>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom textAlign="center">
          Complete Your Profile
        </Typography>
        
        <Stepper activeStep={activeStep} sx={{ my: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            startIcon={<BackIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
            endIcon={activeStep === steps.length - 1 ? null : <NextIcon />}
            disabled={loading}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
