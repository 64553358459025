import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  MenuItem,
  Grid,
  CircularProgress,
  Chip,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  Autocomplete
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ModUploader from '../components/mods/ModUploader';
import ImageUploader from '../components/upload/ImageUploader';
import ReCaptcha from '../components/common/ReCaptcha';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { storage, db } from '../firebase/config';
import MDEditor from '@uiw/react-md-editor';

const categories = [
  'cars',
  'driftcarpacks',
  'tracks',
  'other'
];

export default function ModUpload() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  
  // Form state
  const [modFile, setModFile] = useState(null);
  const [screenshots, setScreenshots] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    tags: [],
    carBrand: '',
    carModel: '',
    yearOfCar: new Date().getFullYear(),
    type: 'car_pack',
    physics: {
      engine: {
        power: 0,
        torque: 0,
        redline: 7500
      },
      suspension: {
        type: 'drift',
        adjustable: true
      },
      transmission: {
        type: 'manual',
        gears: 6
      }
    },
    requirements: {
      minGameVersion: '1.16.5',
      dlcs: [],
      dependencies: []
    },
    currentDlc: '',
    currentDependency: ''
  });

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function TabPanel({ children, value, index }) {
    const ref = useRef(null);

    return (
      <div
        ref={ref}
        role="tabpanel"
        hidden={value !== index}
        id={`mod-upload-tabpanel-${index}`}
        aria-labelledby={`mod-upload-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      // Handle nested objects (physics properties)
      const [parent, child, subChild] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: subChild ? {
            ...prev[parent][child],
            [subChild]: value
          } : value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData(prev => ({
      ...prev,
      description: value || ''
    }));
  };

  const handleAddTag = (e) => {
    e.preventDefault();
    if (formData.currentTag && !formData.tags.includes(formData.currentTag)) {
      setFormData(prev => ({
        ...prev,
        tags: [...prev.tags, prev.currentTag],
        currentTag: ''
      }));
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  const handleAddDlc = (e) => {
    e.preventDefault();
    if (formData.currentDlc && !formData.requirements.dlcs.includes(formData.currentDlc)) {
      setFormData(prev => ({
        ...prev,
        requirements: {
          ...prev.requirements,
          dlcs: [...prev.requirements.dlcs, prev.currentDlc]
        },
        currentDlc: ''
      }));
    }
  };

  const handleRemoveDlc = (dlcToRemove) => {
    setFormData(prev => ({
      ...prev,
      requirements: {
        ...prev.requirements,
        dlcs: prev.requirements.dlcs.filter(dlc => dlc !== dlcToRemove)
      }
    }));
  };

  const handleAddDependency = (e) => {
    e.preventDefault();
    if (formData.currentDependency && !formData.requirements.dependencies.includes(formData.currentDependency)) {
      setFormData(prev => ({
        ...prev,
        requirements: {
          ...prev.requirements,
          dependencies: [...prev.requirements.dependencies, prev.currentDependency]
        },
        currentDependency: ''
      }));
    }
  };

  const handleRemoveDependency = (depToRemove) => {
    setFormData(prev => ({
      ...prev,
      requirements: {
        ...prev.requirements,
        dependencies: prev.requirements.dependencies.filter(dep => dep !== depToRemove)
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!modFile) {
      setError('Please select a mod file or provide a URL');
      return;
    }

    if (!captchaVerified) {
      setError('Please complete the reCAPTCHA verification');
      return;
    }

    setLoading(true);
    setError('');

    try {
      let modDownloadURL;

      // Handle file upload or use provided URL
      if (!modFile.isUrl) {
        try {
          // Upload mod file to storage
          const modStorageRef = ref(storage, `mods/${currentUser.uid}/${Date.now()}_${modFile.file.name}`);
          await uploadBytes(modStorageRef, modFile.file);
          modDownloadURL = await getDownloadURL(modStorageRef);
        } catch (error) {
          console.error('Error uploading mod file:', error);
          if (error.code === 'storage/unauthorized') {
            throw new Error('You do not have permission to upload mod files. Please contact the administrator.');
          } else if (error.code === 'storage/invalid-argument') {
            throw new Error('Invalid file format. Please check your file and try again.');
          } else if (error.code === 'storage/quota-exceeded') {
            throw new Error('Storage quota exceeded. Please contact the administrator.');
          }
          throw error;
        }
      } else {
        modDownloadURL = modFile.url;
      }

      // Upload screenshots
      let screenshotUrls = [];
      if (screenshots && screenshots.length > 0) {
        try {
          screenshotUrls = await Promise.all(
            screenshots.map(async (screenshot) => {
              try {
                // Add timestamp and random string to ensure unique filenames
                const timestamp = Date.now();
                const randomStr = Math.random().toString(36).substring(2, 8);
                const fileName = `${timestamp}_${randomStr}_${screenshot.name}`;
                
                const screenshotRef = ref(
                  storage,
                  `screenshots/${currentUser.uid}/${fileName}`
                );
                
                // Upload with metadata to ensure proper content type
                const metadata = {
                  contentType: screenshot.type,
                };
                await uploadBytes(screenshotRef, screenshot, metadata);
                return getDownloadURL(screenshotRef);
              } catch (error) {
                console.error('Error uploading screenshot:', error);
                // Don't throw here, just return null and filter out failed uploads
                return null;
              }
            })
          );
          
          // Filter out any failed uploads
          screenshotUrls = screenshotUrls.filter(url => url !== null);
          
          if (screenshotUrls.length === 0 && screenshots.length > 0) {
            throw new Error('Failed to upload screenshots. Please try again.');
          }
        } catch (error) {
          console.error('Error uploading screenshots:', error);
          throw new Error('Failed to upload screenshots. Please check your image files and try again.');
        }
      }

      // Create mod document in Firestore
      // Generate a clean ID that matches our validation regex /^[a-zA-Z0-9_]+$/
      const timestamp = Date.now().toString(36);
      const randomStr = Math.random().toString(36).substring(2, 8);
      const modId = `mod_${timestamp}_${randomStr}`.replace(/[^a-zA-Z0-9_]/g, '_');
      
      const modData = {
        id: modId,
        title: formData.title,
        description: formData.description,
        category: formData.category,
        tags: formData.tags,
        fileUrl: modDownloadURL,
        fileName: modFile.name,
        fileSize: modFile.isUrl ? null : modFile.size,
        isExternalUrl: modFile.isUrl,
        screenshots: screenshotUrls,
        authorId: currentUser.uid,
        authorName: currentUser.displayName || currentUser.email,
        uploadedAt: serverTimestamp(),
        downloads: 0,
        status: 'pending',
        carBrand: formData.carBrand,
        carModel: formData.carModel,
        yearOfCar: formData.yearOfCar,
        type: formData.type,
        physics: formData.physics,
        requirements: formData.requirements,
        searchTerms: [
          formData.title.toLowerCase(),
          formData.category.toLowerCase(),
          ...formData.tags.map(tag => tag.toLowerCase())
        ]
      };

      await setDoc(doc(db, 'mods', modId), modData);
      setSuccess(true);
      
      // Redirect after successful upload
      setTimeout(() => {
        navigate('/mods');
      }, 2000);
    } catch (err) {
      console.error('Error uploading mod:', err);
      setError(err.message || 'Failed to upload mod. Please try again.');
      setLoading(false);
      window.scrollTo(0, 0);
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
    setError('');
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Upload New Mod
        </Typography>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>Mod uploaded successfully! Redirecting...</Alert>}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ModUploader
                onFileSelect={setModFile}
                selectedFile={modFile}
                onFileRemove={() => setModFile(null)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Category</InputLabel>
                <Select
                  value={formData.category}
                  label="Category"
                  name="category"
                  onChange={handleInputChange}
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.tags}
                onChange={(event, newValue) => {
                  setFormData(prev => ({
                    ...prev,
                    tags: newValue
                  }));
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      color="primary"
                      variant="outlined"
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Add tags"
                    helperText="Press Enter to add tags"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Description (Markdown)
                </Typography>
                <MDEditor
                  value={formData.description}
                  onChange={handleDescriptionChange}
                  height={300}
                  preview="edit"
                />
              </Box>
            </Grid>

            {/* Tabbed Sections */}
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
                <Tabs value={currentTab} onChange={handleTabChange} aria-label="mod details tabs">
                  <Tab label="Car Details" />
                  <Tab label="Physics Configuration" />
                  <Tab label="Requirements" />
                </Tabs>
              </Box>

              {/* Car Details Tab */}
              <TabPanel value={currentTab} index={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Car Brand"
                      name="carBrand"
                      value={formData.carBrand}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Car Model"
                      name="carModel"
                      value={formData.carModel}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Year"
                      name="yearOfCar"
                      value={formData.yearOfCar}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              {/* Physics Configuration Tab */}
              <TabPanel value={currentTab} index={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Engine Power (HP)"
                      name="physics.engine.power"
                      value={formData.physics.engine.power}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Engine Torque (Nm)"
                      name="physics.engine.torque"
                      value={formData.physics.engine.torque}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Redline (RPM)"
                      name="physics.engine.redline"
                      value={formData.physics.engine.redline}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      select
                      label="Suspension Type"
                      name="physics.suspension.type"
                      value={formData.physics.suspension.type}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="drift">Drift</MenuItem>
                      <MenuItem value="race">Race</MenuItem>
                      <MenuItem value="street">Street</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      select
                      label="Transmission Type"
                      name="physics.transmission.type"
                      value={formData.physics.transmission.type}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="manual">Manual</MenuItem>
                      <MenuItem value="automatic">Automatic</MenuItem>
                      <MenuItem value="sequential">Sequential</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </TabPanel>

              {/* Requirements Tab */}
              <TabPanel value={currentTab} index={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Minimum Game Version"
                      name="requirements.minGameVersion"
                      value={formData.requirements.minGameVersion}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Add Required DLC"
                        name="currentDlc"
                        value={formData.currentDlc}
                        onChange={handleInputChange}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            handleAddDlc(e);
                          }
                        }}
                        helperText="Press Enter to add DLC"
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {formData.requirements.dlcs.map((dlc) => (
                        <Chip
                          key={dlc}
                          label={dlc}
                          onDelete={() => handleRemoveDlc(dlc)}
                        />
                      ))}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Add Dependency"
                        name="currentDependency"
                        value={formData.currentDependency}
                        onChange={handleInputChange}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            handleAddDependency(e);
                          }
                        }}
                        helperText="Press Enter to add dependency"
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {formData.requirements.dependencies.map((dep) => (
                        <Chip
                          key={dep}
                          label={dep}
                          onDelete={() => handleRemoveDependency(dep)}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>

           
            {/* Screenshots Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Screenshots
              </Typography>
              <ImageUploader
                onImagesSelect={(newImages) => setScreenshots(prev => [...prev, ...newImages])}
                selectedImages={screenshots}
                onImageRemove={(imageToRemove) => 
                  setScreenshots(prev => prev.filter(img => img !== imageToRemove))
                }
                maxImages={10}
              />
            </Grid>
               {/* reCAPTCHA */}
            <Grid item xs={12}>
              <Box sx={{ mb: 3 }}>
                <ReCaptcha onChange={handleCaptchaChange} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                sx={{ mt: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : 'Upload Mod'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
