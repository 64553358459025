import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  serverTimestamp,
  increment,
  arrayUnion,
  arrayRemove,
  runTransaction
} from 'firebase/firestore';
import { db } from '../firebase/config';

// Comments Service
export const commentsService = {
  async addComment(modId, userId, content, parentId = null) {
    try {
      // First, verify the mod exists
      const modRef = doc(db, 'mods', modId);
      const modDoc = await getDoc(modRef);
      if (!modDoc.exists()) {
        throw new Error('Mod not found');
      }

      // Create the comment
      const comment = {
        modId,
        userId,
        content,
        parentId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        likes: 0,
        likedBy: [],
        isEdited: false
      };

      // Add the comment
      const docRef = await addDoc(collection(db, 'comments'), comment);

      // Update mod's comment count
      await updateDoc(modRef, {
        commentCount: increment(1)
      });

      return { id: docRef.id, ...comment };
    } catch (error) {
      console.error('Error in addComment:', error);
      throw error;
    }
  },

  async getModComments(modId) {
    const q = query(
      collection(db, 'comments'),
      where('modId', '==', modId),
      orderBy('createdAt', 'desc')
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async likeComment(commentId, userId) {
    const commentRef = doc(db, 'comments', commentId);
    await updateDoc(commentRef, {
      likes: increment(1),
      likedBy: arrayUnion(userId)
    });
  },

  async unlikeComment(commentId, userId) {
    const commentRef = doc(db, 'comments', commentId);
    await updateDoc(commentRef, {
      likes: increment(-1),
      likedBy: arrayRemove(userId)
    });
  }
};

// Reviews Service
export const reviewsService = {
  async addReview(modId, userId, rating, content) {
    try {
      const review = {
        modId,
        userId,
        rating,
        content,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        helpfulCount: 0,
        helpfulBy: []
      };

      await runTransaction(db, async (transaction) => {
        const docRef = await transaction.add(collection(db, 'reviews'), review);
        
        // Update mod's rating
        const modRef = doc(db, 'mods', modId);
        const modDoc = await transaction.get(modRef);
        const modData = modDoc.data();
        
        const newRatingCount = (modData.ratingCount || 0) + 1;
        const newRatingTotal = (modData.ratingTotal || 0) + rating;
        
        await transaction.update(modRef, {
          rating: newRatingTotal / newRatingCount,
          ratingCount: newRatingCount,
          ratingTotal: newRatingTotal
        });

        return { id: docRef.id, ...review };
      });
    } catch (error) {
      console.error('Error in addReview:', error);
      throw error;
    }
  },

  async getModReviews(modId) {
    const q = query(
      collection(db, 'reviews'),
      where('modId', '==', modId),
      orderBy('createdAt', 'desc')
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async markReviewHelpful(reviewId, userId) {
    const reviewRef = doc(db, 'reviews', reviewId);
    await updateDoc(reviewRef, {
      helpfulCount: increment(1),
      helpfulBy: arrayUnion(userId)
    });
  }
};

// Collections Service
export const collectionsService = {
  async createCollection(userId, name, description, isPublic = true) {
    const collection = {
      userId,
      name,
      description,
      isPublic,
      mods: [],
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      followersCount: 0,
      followers: []
    };

    const docRef = await addDoc(collection(db, 'collections'), collection);
    return { id: docRef.id, ...collection };
  },

  async addModToCollection(collectionId, modId) {
    const collectionRef = doc(db, 'collections', collectionId);
    await updateDoc(collectionRef, {
      mods: arrayUnion(modId),
      updatedAt: serverTimestamp()
    });
  },

  async removeModFromCollection(collectionId, modId) {
    const collectionRef = doc(db, 'collections', collectionId);
    await updateDoc(collectionRef, {
      mods: arrayRemove(modId),
      updatedAt: serverTimestamp()
    });
  },

  async getUserCollections(userId) {
    const q = query(
      collection(db, 'collections'),
      where('userId', '==', userId)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async getPublicCollections() {
    const q = query(
      collection(db, 'collections'),
      where('isPublic', '==', true),
      orderBy('followersCount', 'desc')
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }
};

// Achievements Service
export const achievementsService = {
  async awardAchievement(userId, achievementId) {
    const userRef = doc(db, 'users', userId);
    const achievement = {
      id: achievementId,
      awardedAt: serverTimestamp()
    };

    await updateDoc(userRef, {
      achievements: arrayUnion(achievement)
    });

    return achievement;
  },

  async getUserAchievements(userId) {
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.data()?.achievements || [];
  },

  async checkModUploadAchievements(userId) {
    const q = query(
      collection(db, 'mods'),
      where('userId', '==', userId)
    );
    const snapshot = await getDocs(q);
    const uploadCount = snapshot.size;

    const achievements = [];
    if (uploadCount >= 1) achievements.push('FIRST_MOD');
    if (uploadCount >= 5) achievements.push('MOD_CREATOR');
    if (uploadCount >= 20) achievements.push('MOD_MASTER');

    for (const achievementId of achievements) {
      await this.awardAchievement(userId, achievementId);
    }
  },

  async checkDownloadAchievements(userId) {
    const userDoc = await getDoc(doc(db, 'users', userId));
    const downloadCount = userDoc.data()?.downloadCount || 0;

    const achievements = [];
    if (downloadCount >= 10) achievements.push('MOD_ENTHUSIAST');
    if (downloadCount >= 50) achievements.push('MOD_COLLECTOR');
    if (downloadCount >= 200) achievements.push('MOD_HOARDER');

    for (const achievementId of achievements) {
      await this.awardAchievement(userId, achievementId);
    }
  }
};
