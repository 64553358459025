import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Avatar,
  Tooltip,
  useTheme,
  TextField,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Upload as UploadIcon,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  Analytics as AnalyticsIcon,
  Logout as LogoutIcon,
  Person as PersonIcon,
  AdminPanelSettings as AdminIcon,
  ArrowDropDown as ArrowDropDownIcon,
  People as UsersIcon,
  Report as ReportsIcon,
  ReportProblem as ReportProblemIcon,
  Home as HomeIcon,
  DirectionsCar as CarIcon,
  Star as StarIcon,
  Search as SearchIcon,
  Support as SupportIcon,
  Book as BookIcon,
} from '@mui/icons-material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useAdmin } from '../../contexts/AdminContext';

export default function Navbar() {
  const { currentUser, logout } = useAuth();
  const { isAdmin, isModerator } = useAdmin();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const [adminMenuAnchor, setAdminMenuAnchor] = useState(null);
  const [modMenuAnchor, setModMenuAnchor] = useState(null);
  const [modsMenuAnchor, setModsMenuAnchor] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenu = (event) => {
    setMobileAnchorEl(event.currentTarget);
  };

  const handleAdminMenu = (event) => {
    setAdminMenuAnchor(event.currentTarget);
  };

  const handleModMenu = (event) => {
    setModMenuAnchor(event.currentTarget);
  };

  const handleModsMenu = (event) => {
    setModsMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMobileAnchorEl(null);
    setAdminMenuAnchor(null);
    setModMenuAnchor(null);
    setModsMenuAnchor(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      handleClose();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };

  const mainMenuItems = [
    { label: 'Home', icon: <HomeIcon />, path: '/' },
    { 
      label: 'Mods', 
      icon: <CarIcon />, 
      path: '/mods',
      onClick: handleModsMenu,
      hasDropdown: true 
    },
    { label: 'Guides', icon: <BookIcon />, path: '/guides' },
    { label: 'Premium', icon: <StarIcon />, path: '/premium' },
    { label: 'Support', icon: <SupportIcon />, path: '/contact' },
  ];

  const adminMenuItems = [
    { label: 'Dashboard', icon: <DashboardIcon fontSize="small" />, path: '/admin' },
    { label: 'Analytics', icon: <AnalyticsIcon fontSize="small" />, path: '/admin/analytics' },
    { label: 'Users', icon: <UsersIcon fontSize="small" />, path: '/admin/users' },
    { label: 'Mods', icon: <CarIcon fontSize="small" />, path: '/admin/mods' },
    { label: 'Settings', icon: <SettingsIcon fontSize="small" />, path: '/admin/settings' },
  ];

  const modMenuItems = [
    { label: 'Mod Queue', icon: <CarIcon fontSize="small" />, path: '/mod/queue' },
    { label: 'Reports', icon: <ReportsIcon fontSize="small" />, path: '/mod/reports' },
    { label: 'Mods Management', icon: <SettingsIcon fontSize="small" />, path: '/admin/mods' },
  ];

  const modCategories = [
    { label: 'All Mods', icon: <CarIcon fontSize="small" />, path: '/mods' },
    { label: 'Cars', icon: <CarIcon fontSize="small" />, path: '/mods/cars' },
    { label: 'Car Packs', icon: <CarIcon fontSize="small" />, path: '/mods/car-packs' },
    { label: 'Tracks', icon: <CarIcon fontSize="small" />, path: '/mods/tracks' },
  ];

  return (
    <AppBar 
      position="static" 
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Mobile Menu Button */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { sm: 'none' } }}
          onClick={handleMobileMenu}
        >
          <MenuIcon />
        </IconButton>

        {/* Logo */}
        
        <RouterLink to="/">
          <img src="/img/logo.png" alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
        </RouterLink>

        {/* Search Bar */}
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <TextField
            size="small"
            placeholder="Search mods..."
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                color: 'white',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'rgba(255, 255, 255, 0.7)',
                opacity: 1,
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton 
                  size="small" 
                  sx={{ color: 'white' }}
                  onClick={() => navigate(`/mods?search=${searchQuery}`)}
                >
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                navigate(`/mods?search=${searchQuery}`);
              }
            }}
          />
        </Box>

        {/* Main Navigation - Desktop */}
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', gap: 1 }}>
          {mainMenuItems.map((item) => (
            <Button
              key={item.path}
              color="inherit"
              component={item.hasDropdown ? 'button' : RouterLink}
              to={!item.hasDropdown ? item.path : undefined}
              onClick={item.onClick}
              startIcon={item.icon}
              endIcon={item.hasDropdown && <ArrowDropDownIcon />}
              sx={{
                minWidth: 100,
                borderRadius: '8px',
                ...(isCurrentPath(item.path) && {
                  backgroundColor: theme.palette.action.selected,
                  '&:hover': {
                    backgroundColor: theme.palette.action.selected,
                  },
                }),
              }}
            >
              {item.label}
            </Button>
          ))}

          {/* Mods Menu */}
          <Menu
            anchorEl={modsMenuAnchor}
            open={Boolean(modsMenuAnchor)}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            {modCategories.map((item) => (
              <MenuItem
                key={item.path}
                component={RouterLink}
                to={item.path}
                sx={{
                  minWidth: '200px',
                  py: 1,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            ))}
          </Menu>

          {/* Upload Button */}
          {currentUser && (
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to="/upload"
              startIcon={<UploadIcon />}
              sx={{
                borderRadius: '8px',
                ml: 1,
              }}
            >
              Upload
            </Button>
          )}
        </Box>

        {/* Right Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {/* Moderator Menu */}
          {isModerator && (
            <>
              <Button
                color="inherit"
                onClick={handleModMenu}
                endIcon={<ArrowDropDownIcon />}
                startIcon={<SettingsIcon />}
                sx={{
                  borderRadius: '8px',
                  ...(location.pathname.startsWith('/mod') && {
                    backgroundColor: theme.palette.action.selected,
                  }),
                }}
              >
                Mod
              </Button>
              <Menu
                anchorEl={modMenuAnchor}
                open={Boolean(modMenuAnchor)}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
              >
                {modMenuItems.map((item) => (
                  <MenuItem
                    key={item.path}
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      minWidth: '200px',
                      py: 1,
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}

          {/* Admin Menu */}
          {isAdmin && (
            <>
              <Button
                color="inherit"
                onClick={handleAdminMenu}
                endIcon={<ArrowDropDownIcon />}
                startIcon={<AdminIcon />}
                sx={{
                  borderRadius: '8px',
                  ...(location.pathname.startsWith('/admin') && {
                    backgroundColor: theme.palette.action.selected,
                  }),
                }}
              >
                Admin
              </Button>
              <Menu
                anchorEl={adminMenuAnchor}
                open={Boolean(adminMenuAnchor)}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
              >
                {adminMenuItems.map((item) => (
                  <MenuItem
                    key={item.path}
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      minWidth: '200px',
                      py: 1,
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}

          {/* User Menu */}
          {currentUser ? (
            <>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleMenu}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                >
                  <Avatar 
                    src={currentUser.photoURL}
                    sx={{ 
                      width: 32, 
                      height: 32,
                      bgcolor: theme.palette.primary.main,
                      border: '2px solid',
                      borderColor: 'transparent',
                      '&:hover': {
                        borderColor: theme.palette.primary.main,
                      }
                    }}
                  >
                    {currentUser.email?.[0].toUpperCase() || 'U'}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem component={RouterLink} to="/profile">
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to="/auth"
              sx={{ borderRadius: '8px' }}
            >
              Sign In
            </Button>
          )}
        </Box>

        {/* Mobile Menu */}
        <Menu
          anchorEl={mobileAnchorEl}
          open={Boolean(mobileAnchorEl)}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              mt: 1.5,
              width: '100%',
              maxWidth: 320,
            },
          }}
        >
          <MenuItem
            component={RouterLink}
            to="/"
            sx={{
              py: 1,
              ...(isCurrentPath('/') && {
                backgroundColor: theme.palette.action.selected,
              }),
            }}
          >
            <ListItemIcon><HomeIcon /></ListItemIcon>
            Home
          </MenuItem>
          
          {modCategories.map((item) => (
            <MenuItem
              key={item.path}
              component={RouterLink}
              to={item.path}
              sx={{
                py: 1,
                ...(isCurrentPath(item.path) && {
                  backgroundColor: theme.palette.action.selected,
                }),
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.label}
            </MenuItem>
          ))}

          <MenuItem
            component={RouterLink}
            to="/guides"
            sx={{
              py: 1,
              ...(isCurrentPath('/guides') && {
                backgroundColor: theme.palette.action.selected,
              }),
            }}
          >
            <ListItemIcon><BookIcon /></ListItemIcon>
            Guides
          </MenuItem>

          <MenuItem
            component={RouterLink}
            to="/premium"
            sx={{
              py: 1,
              ...(isCurrentPath('/premium') && {
                backgroundColor: theme.palette.action.selected,
              }),
            }}
          >
            <ListItemIcon><StarIcon /></ListItemIcon>
            Premium
          </MenuItem>

          <MenuItem
            component={RouterLink}
            to="/contact"
            sx={{
              py: 1,
              ...(isCurrentPath('/contact') && {
                backgroundColor: theme.palette.action.selected,
              }),
            }}
          >
            <ListItemIcon><SupportIcon /></ListItemIcon>
            Support
          </MenuItem>

          {currentUser && (
            <MenuItem component={RouterLink} to="/upload">
              <ListItemIcon><UploadIcon /></ListItemIcon>
              Upload
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
