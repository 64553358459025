export const checkAdBlocker = async () => {
  try {
    // Method 1: Check for common ad element
    const testAd = document.createElement('div');
    testAd.innerHTML = '&nbsp;';
    testAd.className = 'adsbox ad-placement ad-unit advertising';
    document.body.appendChild(testAd);
    
    // Method 2: Check for Google AdSense
    const googleAdTest = document.createElement('script');
    googleAdTest.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    googleAdTest.async = true;
    document.head.appendChild(googleAdTest);

    // Method 3: Check for common ad network URLs
    const adUrls = [
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
      'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
    ];

    const urlChecks = adUrls.map(url => 
      fetch(url, { mode: 'no-cors' }).catch(() => null)
    );

    // Wait for all checks to complete
    await Promise.all([
      new Promise(resolve => setTimeout(resolve, 100)),
      ...urlChecks
    ]);

    // Check results
    const isElementBlocked = testAd.offsetHeight === 0;
    const isGoogleAdsBlocked = !window.adsbygoogle;

    // Cleanup
    document.body.removeChild(testAd);
    document.head.removeChild(googleAdTest);

    return isElementBlocked || isGoogleAdsBlocked;
  } catch (e) {
    console.error('Ad blocker detection error:', e);
    return true; // Assume blocked if there's an error
  }
};