import React, { useState, useEffect } from 'react';
import {
  Box,
  Rating,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Stack,
  Chip,
  IconButton,
  Alert,
  Link
} from '@mui/material';
import { ThumbUp } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { formatDistanceToNow } from 'date-fns';
import { reviewService } from '../../services/reviewService';

function ReviewCard({ review, onMarkHelpful }) {
  const { currentUser } = useAuth();
  const isHelpful = review.helpfulBy?.includes(currentUser?.uid);

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="subtitle1" fontWeight="bold">
            {review.userName}
          </Typography>
          <Rating value={review.rating} readOnly size="small" />
        </Stack>

        <Typography variant="body2" color="text.secondary" gutterBottom>
          {review.createdAt ? formatDistanceToNow(review.createdAt.toDate(), { addSuffix: true }) : 'Recently'}
        </Typography>

        <Typography variant="body1" paragraph>
          {review.content}
        </Typography>

        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            size="small"
            onClick={() => onMarkHelpful(review.id)}
            color={isHelpful ? "primary" : "default"}
          >
            <ThumbUp />
          </IconButton>
          <Typography variant="body2" color="text.secondary">
            {review.helpfulCount || 0} found this helpful
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default function Reviews({ modId }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newReview, setNewReview] = useState({ rating: 0, content: '' });
  const { currentUser } = useAuth();
  const [indexError, setIndexError] = useState(false);

  useEffect(() => {
    const fetchReviews = async () => {
      if (!modId) return;

      try {
        const reviewsRef = collection(db, 'reviews');
        let q;
        
        try {
          // Try to fetch with ordering
          q = query(
            reviewsRef,
            where('modId', '==', modId),
            orderBy('createdAt', 'desc')
          );
          const querySnapshot = await getDocs(q);
          const reviewsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReviews(reviewsData);
          setIndexError(false);
        } catch (err) {
          if (err.message.includes('requires an index')) {
            // If index error, fetch without ordering
            setIndexError(true);
            q = query(
              reviewsRef,
              where('modId', '==', modId)
            );
            const querySnapshot = await getDocs(q);
            const reviewsData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            // Sort reviews client-side
            reviewsData.sort((a, b) => b.createdAt?.toDate() - a.createdAt?.toDate());
            setReviews(reviewsData);
          } else {
            throw err;
          }
        }
      } catch (err) {
        console.error('Error fetching reviews:', err);
        setError('Failed to load reviews');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [modId]);

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      setError('Please sign in to submit a review');
      return;
    }

    if (newReview.rating === 0) {
      setError('Please select a rating');
      return;
    }

    try {
      // Submit review
      const reviewId = await reviewService.addReview(
        currentUser.uid, 
        currentUser.displayName || 'Anonymous User', 
        modId, 
        newReview
      );

      // Add the new review to the list
      const newReviewEntry = {
        id: reviewId,
        userId: currentUser.uid,
        userName: currentUser.displayName || 'Anonymous User',
        ...newReview,
        createdAt: { toDate: () => new Date() },
        helpfulCount: 0,
        helpfulBy: []
      };

      // Update reviews list and reset form
      setReviews(prev => [newReviewEntry, ...prev]);
      setNewReview({ rating: 0, content: '' });
      setError(null);
    } catch (err) {
      console.error('Error submitting review:', err);
      setError('Failed to submit review. Please try again.');
    }
  };

  const handleMarkHelpful = async (reviewId) => {
    if (!currentUser) {
      setError('Please sign in to mark a review as helpful');
      return;
    }

    try {
      await reviewService.markHelpful(currentUser.uid, reviewId);

      // Update the reviews list to reflect the helpful status
      setReviews(prev => prev.map(review => 
        review.id === reviewId 
          ? {
              ...review, 
              helpfulCount: review.helpfulBy?.includes(currentUser.uid) 
                ? (review.helpfulCount || 1) - 1 
                : (review.helpfulCount || 0) + 1,
              helpfulBy: review.helpfulBy?.includes(currentUser.uid)
                ? review.helpfulBy.filter(id => id !== currentUser.uid)
                : [...(review.helpfulBy || []), currentUser.uid]
            }
          : review
      ));
    } catch (err) {
      console.error('Error marking review as helpful:', err);
      setError('Failed to mark review as helpful');
    }
  };

  if (loading) {
    return <Typography>Loading reviews...</Typography>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box>
      {indexError && (
        <Alert severity="info" sx={{ mb: 2 }}>
          Note: Reviews are currently sorted client-side. To improve performance,{' '}
          <Link 
            href="https://console.firebase.google.com/v1/r/project/acdp-website-bff13/firestore/indexes?create_composite=ClJwcm9qZWN0cy9hY2RwLXdlYnNpdGUtYmZmMTMvZGF0YWJhc2VzLyhkZWZhdWx0KS9jb2xsZWN0aW9uR3JvdXBzL3Jldmlld3MvaW5kZXhlcy9fEAEaCQoFbW9kSWQQARoNCgljcmVhdGVkQXQQAhoMCghfX25hbWVfXxAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </Link>
          {' '}to create the required index.
        </Alert>
      )}

      {currentUser && (
        <Box component="form" onSubmit={handleSubmitReview} sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Write a Review
          </Typography>
          <Rating
            value={newReview.rating}
            onChange={(_, value) => setNewReview(prev => ({ ...prev, rating: value }))}
            size="large"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            value={newReview.content}
            onChange={(e) => setNewReview(prev => ({ ...prev, content: e.target.value }))}
            placeholder="Share your thoughts about this mod..."
            sx={{ mb: 2 }}
          />
          <Button type="submit" variant="contained" color="primary">
            Submit Review
          </Button>
        </Box>
      )}

      {reviews.length > 0 ? (
        reviews.map(review => (
          <ReviewCard
            key={review.id}
            review={review}
            onMarkHelpful={handleMarkHelpful}
          />
        ))
      ) : (
        <Typography color="text.secondary">
          No reviews yet. Be the first to review this mod!
        </Typography>
      )}
    </Box>
  );
}
