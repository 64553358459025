import React from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  useTheme
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import BlockIcon from '@mui/icons-material/Block';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

export default function AdBlockerWarning() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const theme = useTheme();

  // Don't show on excluded pages
  const excludedPaths = ['/auth', '/premium', '/signup', '/login'];
  if (excludedPaths.some(path => location.pathname.startsWith(path)) || 
      currentUser?.subscription?.type === 'premium') {
    return null;
  }

  return (
    <Dialog 
      open={true}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          background: theme.palette.mode === 'dark' 
            ? 'linear-gradient(45deg, #1a1a1a, #2d2d2d)'
            : 'linear-gradient(45deg, #f5f5f5, #ffffff)'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', pt: 4 }}>
        <BlockIcon sx={{ fontSize: 48, color: theme.palette.error.main, mb: 2 }} />
        <Typography variant="h4" component="div" gutterBottom>
          Ad Blocker Detected
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ textAlign: 'center', py: 2 }}>
          <Typography variant="h6" gutterBottom>
            We noticed you're using an ad blocker
          </Typography>
          <Typography color="text.secondary" paragraph>
            Our website relies on advertising revenue to maintain and improve our services.
            Please consider either disabling your ad blocker or upgrading to a premium account
            for an ad-free experience.
          </Typography>
          
          <Box sx={{ 
            mt: 4, 
            p: 3, 
            bgcolor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
            borderRadius: 2
          }}>
            <MonetizationOnIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Go Premium
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 2 }}>
              Enjoy an ad-free experience plus exclusive features
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate('/premium')}
              sx={{ 
                px: 4,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: 4
                }
              }}
            >
              Upgrade to Premium
            </Button>
          </Box>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ p: 3, justifyContent: 'center' }}>
        <Button 
          variant="outlined"
          onClick={() => window.location.reload()}
          sx={{ mr: 2 }}
        >
          I've Disabled My Ad Blocker
        </Button>
      </DialogActions>
    </Dialog>
  );
}