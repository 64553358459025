import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Tabs,
  Tab,
} from '@mui/material';
import {
  Visibility as ViewIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { 
  collection, 
  query, 
  getDocs, 
  doc, 
  updateDoc, 
  deleteDoc,
  getDoc
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const MOD_STATUSES = [
  { value: 'pending', label: 'Pending Review' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'flagged', label: 'Flagged' },
];

const categories = [
  'Cars',
  'Car Packs',
  'Tracks',
  'Other'
];

// Custom TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function ModsManagement() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  
  const [mods, setMods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  const [selectedMod, setSelectedMod] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [modStatus, setModStatus] = useState('');
  const [reviewNotes, setReviewNotes] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  // Check admin status
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        navigate('/auth');
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const userData = userDoc.data();
        
        if (userData.role !== 'admin') {
          navigate('/');
          return;
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        navigate('/');
      }
    };

    checkAdminStatus();
  }, [currentUser, navigate]);

  // Fetch mods
  useEffect(() => {
    const fetchMods = async () => {
      try {
        setLoading(true);
        
        const modsQuery = query(collection(db, 'mods'));
        const snapshot = await getDocs(modsQuery);
        
        const modsList = await Promise.all(snapshot.docs.map(async (modDoc) => {
          const modData = modDoc.data();
          
          // Fetch author details
          let authorName = 'Unknown';
          try {
            const authorDoc = await getDoc(doc(db, 'users', modData.authorId));
            if (authorDoc.exists()) {
              authorName = authorDoc.data().username || 'Unknown';
            }
          } catch (authorError) {
            console.error('Error fetching author details:', authorError);
          }

          return {
            id: modDoc.id,
            ...modData,
            authorName
          };
        }));

        setMods(modsList);
        setError(modsList.length === 0 ? 'No mods found' : '');
      } catch (error) {
        console.error('Error fetching mods:', error);
        setError('Failed to load mods: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMods();
  }, []);

  // Handle comprehensive mod update
  const handleUpdateMod = async () => {
    if (!selectedMod) return;

    try {
      const modRef = doc(db, 'mods', selectedMod.id);
      
      // Prepare update object with all editable fields
      const updateData = {
        title: selectedMod.title,
        description: selectedMod.description,
        version: selectedMod.version,
        category: selectedMod.category,
        carBrand: selectedMod.carBrand,
        carModel: selectedMod.carModel,
        yearOfCar: selectedMod.yearOfCar,
        physics: selectedMod.physics,
        requirements: selectedMod.requirements,
        status: modStatus,
        reviewNotes: reviewNotes,
        tags: selectedMod.tags || [],
        compatibility: selectedMod.compatibility || [],
        downloadLink: selectedMod.downloadLink,
        previewImages: selectedMod.previewImages || [],
        reviewedBy: currentUser.uid,
        reviewedAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      // Remove undefined values
      Object.keys(updateData).forEach(key => 
        updateData[key] === undefined && delete updateData[key]
      );

      // Update Firestore document
      await updateDoc(modRef, updateData);

      // Update local state
      setMods(prevMods => 
        prevMods.map(mod => 
          mod.id === selectedMod.id 
            ? { ...mod, ...updateData }
            : mod
        )
      );

      // Close dialog and reset
      setEditDialogOpen(false);
      setSelectedMod(null);
      setModStatus('');
      setReviewNotes('');
      
      setError('');
    } catch (error) {
      console.error('Error updating mod:', error);
      setError('Failed to update mod details');
    }
  };

  // Handle mod status update
  const handleUpdateModStatus = async () => {
    if (!selectedMod) return;

    try {
      const modRef = doc(db, 'mods', selectedMod.id);
      await updateDoc(modRef, {
        status: modStatus,
        reviewNotes: reviewNotes,
        reviewedBy: currentUser.uid,
        reviewedAt: new Date().toISOString()
      });

      // Update local state
      setMods(prevMods => 
        prevMods.map(mod => 
          mod.id === selectedMod.id 
            ? { ...mod, status: modStatus, reviewNotes } 
            : mod
        )
      );

      // Close dialog and reset
      setEditDialogOpen(false);
      setSelectedMod(null);
      setModStatus('');
      setReviewNotes('');
      
      setError('');
    } catch (error) {
      console.error('Error updating mod status:', error);
      setError('Failed to update mod status');
    }
  };

  // Handle mod deletion
  const handleDeleteMod = async (modId) => {
    if (!window.confirm('Are you sure you want to delete this mod? This action cannot be undone.')) return;

    try {
      await deleteDoc(doc(db, 'mods', modId));
      
      // Remove from local state
      setMods(prevMods => prevMods.filter(mod => mod.id !== modId));
      
      setError('');
    } catch (error) {
      console.error('Error deleting mod:', error);
      setError('Failed to delete mod');
    }
  };

  // Open edit dialog
  const handleEditClick = (mod) => {
    setSelectedMod(mod);
    setModStatus(mod.status || 'pending');
    setReviewNotes(mod.reviewNotes || '');
    setEditDialogOpen(true);
  };

  // View mod details
  const handleViewMod = (modId) => {
    navigate(`/mods/${modId}`);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Admin Mods Management
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Downloads</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mods.map((mod) => (
                <TableRow key={mod.id}>
                  <TableCell>{mod.title}</TableCell>
                  <TableCell>{mod.authorName}</TableCell>
                  <TableCell>
                    <Chip 
                      label={mod.status} 
                      color={
                        mod.status === 'approved' ? 'success' :
                        mod.status === 'rejected' ? 'error' :
                        mod.status === 'pending' ? 'warning' :
                        'default'
                      } 
                      size="small" 
                    />
                  </TableCell>
                  <TableCell>{mod.downloadCount || 0}</TableCell>
                  <TableCell>
                    {new Date(mod.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleViewMod(mod.id)}>
                      <ViewIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditClick(mod)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteMod(mod.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Edit Mod Dialog */}
        <Dialog 
          open={editDialogOpen} 
          onClose={() => setEditDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Edit Mod Details</DialogTitle>
          <DialogContent>
            {selectedMod && (
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={currentTab} onChange={handleTabChange} aria-label="mod details tabs">
                    <Tab label="Basic Info" />
                    <Tab label="Car Details" />
                    <Tab label="Physics Configuration" />
                    <Tab label="Requirements" />
                    <Tab label="Download" />
                  </Tabs>
                </Box>
                <TabPanel value={currentTab} index={0}>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Mod Title"
                        variant="outlined"
                        value={selectedMod.title}
                        onChange={(e) => setSelectedMod(prev => ({ ...prev, title: e.target.value }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Select
                          value={selectedMod.category}
                          label="Category"
                          onChange={(e) => setSelectedMod(prev => ({ ...prev, category: e.target.value }))}
                        >
                          {categories.map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Description"
                        variant="outlined"
                        value={selectedMod.description}
                        onChange={(e) => setSelectedMod(prev => ({ ...prev, description: e.target.value }))}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={currentTab} index={1}>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Car Brand"
                        variant="outlined"
                        value={selectedMod.carBrand}
                        onChange={(e) => setSelectedMod(prev => ({ ...prev, carBrand: e.target.value }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Car Model"
                        variant="outlined"
                        value={selectedMod.carModel}
                        onChange={(e) => setSelectedMod(prev => ({ ...prev, carModel: e.target.value }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Year of Car"
                        variant="outlined"
                        type="number"
                        value={selectedMod.yearOfCar}
                        onChange={(e) => setSelectedMod(prev => ({ ...prev, yearOfCar: Number(e.target.value) }))}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={currentTab} index={2}>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Engine Power (HP)"
                        variant="outlined"
                        type="number"
                        value={selectedMod.physics?.engine?.power || ''}
                        onChange={(e) => setSelectedMod(prev => ({
                          ...prev,
                          physics: {
                            ...prev.physics,
                            engine: {
                              ...prev.physics.engine,
                              power: Number(e.target.value)
                            }
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Engine Torque (Nm)"
                        variant="outlined"
                        type="number"
                        value={selectedMod.physics?.engine?.torque || ''}
                        onChange={(e) => setSelectedMod(prev => ({
                          ...prev,
                          physics: {
                            ...prev.physics,
                            engine: {
                              ...prev.physics.engine,
                              torque: Number(e.target.value)
                            }
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Redline (RPM)"
                        variant="outlined"
                        type="number"
                        value={selectedMod.physics?.engine?.redline || ''}
                        onChange={(e) => setSelectedMod(prev => ({
                          ...prev,
                          physics: {
                            ...prev.physics,
                            engine: {
                              ...prev.physics.engine,
                              redline: Number(e.target.value)
                            }
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Suspension Type"
                        variant="outlined"
                        value={selectedMod.physics?.suspension?.type || ''}
                        onChange={(e) => setSelectedMod(prev => ({
                          ...prev,
                          physics: {
                            ...prev.physics,
                            suspension: {
                              ...prev.physics.suspension,
                              type: e.target.value
                            }
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Transmission Type"
                        variant="outlined"
                        value={selectedMod.physics?.transmission?.type || ''}
                        onChange={(e) => setSelectedMod(prev => ({
                          ...prev,
                          physics: {
                            ...prev.physics,
                            transmission: {
                              ...prev.physics.transmission,
                              type: e.target.value
                            }
                          }
                        }))}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={currentTab} index={3}>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Minimum Game Version"
                        variant="outlined"
                        value={selectedMod.requirements?.minGameVersion || '1.16.5'}
                        onChange={(e) => setSelectedMod(prev => ({ 
                          ...prev, 
                          requirements: {
                            ...prev.requirements,
                            minGameVersion: e.target.value
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="DLCs (comma-separated)"
                        variant="outlined"
                        value={(selectedMod.requirements?.dlcs || []).join(', ')}
                        onChange={(e) => setSelectedMod(prev => ({ 
                          ...prev, 
                          requirements: {
                            ...prev.requirements,
                            dlcs: e.target.value.split(',').map(dlc => dlc.trim()).filter(dlc => dlc)
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Dependencies (comma-separated)"
                        variant="outlined"
                        value={(selectedMod.requirements?.dependencies || []).join(', ')}
                        onChange={(e) => setSelectedMod(prev => ({ 
                          ...prev, 
                          requirements: {
                            ...prev.requirements,
                            dependencies: e.target.value.split(',').map(dep => dep.trim()).filter(dep => dep)
                          }
                        }))}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={currentTab} index={4}>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Download Link"
                        variant="outlined"
                        value={selectedMod.downloadLink || ''}
                        onChange={(e) => setSelectedMod(prev => ({ ...prev, downloadLink: e.target.value }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button variant="contained" component="label">
                        Upload Mod File
                        <input
                          type="file"
                          hidden
                          onChange={(e) => {
                            const file = e.target.files[0];
                            // Handle file upload logic here
                            setSelectedMod(prev => ({ ...prev, modFile: file }));
                          }}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleUpdateMod} 
              color="primary" 
              variant="contained"
            >
              Update Mod
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
}
