import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import {
  Visibility as ViewIcon,
  Edit as EditIcon,
  Block as BlockIcon,
  CheckCircle as ApproveIcon,
} from '@mui/icons-material';
import { 
  collection, 
  query, 
  getDocs, 
  doc, 
  updateDoc, 
  where, 
  orderBy,
  getDoc
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const MOD_STATUSES = [
  { value: 'pending', label: 'Pending Review' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'flagged', label: 'Flagged' },
];

export default function ModeratorModsManagement() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  
  const [mods, setMods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  const [selectedMod, setSelectedMod] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [modStatus, setModStatus] = useState('');
  const [reviewNotes, setReviewNotes] = useState('');

  // Check moderator status
  useEffect(() => {
    const checkModeratorStatus = async () => {
      if (!currentUser) {
        navigate('/auth');
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const userData = userDoc.data();
        
        if (userData.role !== 'moderator' && userData.role !== 'admin') {
          navigate('/');
          return;
        }
      } catch (error) {
        console.error('Error checking moderator status:', error);
        navigate('/');
      }
    };

    checkModeratorStatus();
  }, [currentUser, navigate]);

  // Fetch mods
  useEffect(() => {
    const fetchMods = async () => {
      try {
        setLoading(true);
        
        // Query all mods without any filtering
        const modsQuery = query(
          collection(db, 'mods')
        );

        console.log('Fetching mods query:', modsQuery);

        const snapshot = await getDocs(modsQuery);
        console.log('Mods snapshot size:', snapshot.size);
        
        const modsList = snapshot.docs.map(doc => {
          const modData = doc.data();
          console.log('Individual Mod:', {
            id: doc.id,
            title: modData.title,
            authorId: modData.authorId,
            status: modData.status
          });
          return {
            id: doc.id,
            ...modData
          };
        });

        console.log('Mods List:', modsList);

        setMods(modsList);
        setError(modsList.length === 0 ? 'No mods found' : '');
      } catch (error) {
        console.error('Error fetching mods:', error);
        setError('Failed to load mods: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMods();
  }, []);

  // Handle mod status update
  const handleUpdateModStatus = async () => {
    if (!selectedMod) return;

    try {
      const modRef = doc(db, 'mods', selectedMod.id);
      await updateDoc(modRef, {
        status: modStatus,
        reviewNotes: reviewNotes,
        reviewedBy: currentUser.uid,
        reviewedAt: new Date().toISOString()
      });

      // Update local state
      setMods(prevMods => 
        prevMods.map(mod => 
          mod.id === selectedMod.id 
            ? { ...mod, status: modStatus, reviewNotes } 
            : mod
        )
      );

      // Close dialog and reset
      setEditDialogOpen(false);
      setSelectedMod(null);
      setModStatus('');
      setReviewNotes('');
      
      setError('');
    } catch (error) {
      console.error('Error updating mod status:', error);
      setError('Failed to update mod status');
    }
  };

  // Open edit dialog
  const handleEditClick = (mod) => {
    setSelectedMod(mod);
    setModStatus(mod.status || 'pending');
    setReviewNotes(mod.reviewNotes || '');
    setEditDialogOpen(true);
  };

  // View mod details
  const handleViewMod = (modId) => {
    navigate(`/mods/${modId}`);
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Moderator Mods Management
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mod Title</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Current Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mods.map((mod) => (
              <TableRow key={mod.id}>
                <TableCell>{mod.title}</TableCell>
                <TableCell>{mod.authorName || 'Unknown'}</TableCell>
                <TableCell>
                  <Chip 
                    label={mod.status || 'Pending'} 
                    color={
                      mod.status === 'approved' ? 'success' :
                      mod.status === 'rejected' ? 'error' :
                      mod.status === 'flagged' ? 'warning' :
                      'default'
                    } 
                    size="small" 
                  />
                </TableCell>
                <TableCell>
                  {mod.createdAt 
                    ? new Date(mod.createdAt).toLocaleDateString() 
                    : 'Unknown'}
                </TableCell>
                <TableCell>
                  <IconButton 
                    onClick={() => handleViewMod(mod.id)}
                    title="View Mod Details"
                  >
                    <ViewIcon />
                  </IconButton>
                  <IconButton 
                    onClick={() => handleEditClick(mod)}
                    title="Edit Mod Status"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Mod Status Dialog */}
      <Dialog 
        open={editDialogOpen} 
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Update Mod Status</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Mod Status</InputLabel>
                <Select
                  value={modStatus}
                  label="Mod Status"
                  onChange={(e) => setModStatus(e.target.value)}
                >
                  {MOD_STATUSES.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Review Notes"
                value={reviewNotes}
                onChange={(e) => setReviewNotes(e.target.value)}
                placeholder="Optional: Add notes about the mod status"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setEditDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleUpdateModStatus}
            color="primary"
            variant="contained"
            startIcon={<ApproveIcon />}
          >
            Update Status
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
