import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import Navbar from './components/layout/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import ModDatabase from './pages/ModDatabase';
import ModDetails from './pages/ModDetails';
import Profile from './pages/Profile';
import ViewProfile from './pages/ViewProfile';
import Auth from './pages/Auth';
import OnboardingFlow from './pages/OnboardingFlow';
import Premium from './pages/Premium';
import ModUpload from './pages/ModUpload';
import AdminDashboard from './pages/admin/AdminDashboard';
import AnalyticsDashboard from './pages/admin/AnalyticsDashboard';
import AdminSettings from './pages/admin/AdminSettings';
import Users from './pages/admin/Users';
import Reports from './pages/admin/Reports';
import ReportsManagement from './pages/admin/ReportsManagement';
import ModsManagement from './pages/admin/ModsManagement';
import ModeratorModsManagement from './pages/admin/ModeratorModsManagement';
import CarMods from './pages/categories/CarMods';
import CarPacks from './pages/categories/CarPacks';
import TrackMods from './pages/categories/TrackMods';
import Contact from './pages/Contact';
import SupportTickets from './pages/admin/SupportTickets';
import Guides from './pages/Guides';
import GuideCreate from './pages/GuideCreate';
import GuideDetail from './pages/GuideDetail';
import GuideEdit from './pages/GuideEdit';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookieConsent from './components/common/CookieConsent';
import { AuthProvider } from './contexts/AuthContext';
import { AdminProvider } from './contexts/AdminContext';
import { OnboardingProvider } from './contexts/OnboardingContext';
import { HelmetProvider } from 'react-helmet-async';
import RequireAdmin from './components/auth/RequireAdmin';
import RequireAuth from './components/auth/RequireAuth';
import ErrorBoundary from './components/ErrorBoundary';

// Create a custom theme
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#44e6f7', // Pink accent color
    },
    secondary: {
      main: '#32a852', // Cyan accent color
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
  },
});

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <AdminProvider>
            <OnboardingProvider>
              <Router>
                <ErrorBoundary>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    minHeight: '100vh'
                  }}>
                    <Navbar />
                    <Box component="main" sx={{ flex: 1 }}>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/mods" element={<ModDatabase />} />
                        <Route path="/mods/cars" element={<CarMods />} />
                        <Route path="/mods/car-packs" element={<CarPacks />} />
                        <Route path="/mods/tracks" element={<TrackMods />} />
                        <Route path="/mod/:modId" element={<ModDetails />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/profile/:userId" element={<ViewProfile />} />
                        <Route path="/auth" element={<Auth />} />
                        <Route path="/onboarding/*" element={<OnboardingFlow />} />
                        <Route path="/premium" element={<Premium />} />
                        <Route path="/upload" element={<ModUpload />} />
                        <Route path="/contact" element={<RequireAuth><Contact /></RequireAuth>} />
                        <Route path="/guides" element={<Guides />} />
                        <Route path="/guides/create" element={<RequireAdmin><GuideCreate /></RequireAdmin>} />
                        <Route path="/guides/:guideId" element={<GuideDetail />} />
                        <Route path="/guides/edit/:guideId" element={<RequireAdmin><GuideEdit /></RequireAdmin>} />
                        <Route path="/terms" element={<TermsOfService />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        
                        {/* Admin Routes */}
                        <Route path="/admin" element={<RequireAdmin><AdminDashboard /></RequireAdmin>} />
                        <Route path="/admin/analytics" element={<RequireAdmin><AnalyticsDashboard /></RequireAdmin>} />
                        <Route path="/admin/users" element={<RequireAdmin><Users /></RequireAdmin>} />
                        <Route path="/admin/mods" element={<RequireAdmin><ModsManagement /></RequireAdmin>} />
                        <Route path="/admin/reports" element={<RequireAdmin><Reports /></RequireAdmin>} />
                        <Route path="/admin/reports-management" element={<RequireAdmin><ReportsManagement /></RequireAdmin>} />
                        <Route path="/admin/settings" element={<RequireAdmin><AdminSettings /></RequireAdmin>} />
                        <Route path="/admin/mods-management" element={<RequireAdmin><ModeratorModsManagement /></RequireAdmin>} />
                        <Route path="/admin/support" element={<RequireAdmin><SupportTickets /></RequireAdmin>} />
                      </Routes>
                    </Box>
                    <Footer />
                    <CookieConsent />
                  </Box>
                </ErrorBoundary>
              </Router>
            </OnboardingProvider>
          </AdminProvider>
        </AuthProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
