import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Grid, 
  Paper, 
  Typography, 
  Avatar, 
  Box, 
  CircularProgress, 
  Alert,
  Button
} from '@mui/material';
import { 
  Link as LinkIcon 
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { userModService } from '../services/userModService';
import UserStats from '../components/profile/UserStats';
import UserMods from '../components/profile/UserMods';
import { useAuth } from '../contexts/AuthContext';

export default function ViewProfile() {
  const { userId } = useParams();
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState(null);
  const [uploadedMods, setUploadedMods] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!userId) {
        setError('No user ID provided');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching profile for userId:', userId);  

        // Fetch user document
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setProfile({
            displayName: userData.displayName || 'Anonymous User',
            photoURL: userData.photoURL || currentUser?.photoURL || '/default-avatar.png',
            bio: userData.bio || 'No bio available',
            website: userData.website || '',
            discord: userData.discord || '',
            donationLink: userData.donationLink || '',
            joinDate: userData.createdAt 
              ? (userData.createdAt.toDate 
                ? new Date(userData.createdAt.toDate()).toLocaleDateString() 
                : new Date(userData.createdAt).toLocaleDateString())
              : 'Unknown'
          });
        } else {
          setError('User not found');
        }

        // Fetch uploaded mods
        const uploadedModsList = await userModService.getUserUploads(userId);
        console.log('Uploaded Mods:', uploadedModsList);  
        setUploadedMods(uploadedModsList);

        // Fetch upload stats
        const uploadStats = await userModService.getUploadStats(userId);
        
        // Calculate user stats
        const stats = {
          uploadCount: uploadStats.totalUploads,
          downloadCount: uploadStats.totalDownloads,
          averageRating: uploadStats.averageRating,
          topCategories: Object.entries(uploadStats.categories)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 3)
            .map(([category, count]) => ({ category, count })),
          recentUpload: uploadStats.recentUpload
        };
        setStats(stats);

      } catch (error) {
        console.error('Error fetching user profile:', error);
        setError('Failed to load user profile');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [userId, currentUser?.photoURL]);

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          minHeight="80vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={3}>
        {/* Profile Header */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Avatar
                  src={profile.photoURL}
                  alt={profile.displayName}
                  loading="lazy"
                  sx={{ 
                    width: 200, 
                    height: 200, 
                    border: '4px solid', 
                    borderColor: 'primary.main' 
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {profile.displayName}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" gutterBottom>
                    {profile.bio}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Joined: {profile.joinDate}
                  </Typography>
                  {profile.website && (
                    <Button
                      variant="contained"
                      color="primary"
                      href={profile.website}
                      target="_blank"
                      startIcon={<LinkIcon />}
                      sx={{ mt: 2, mr: 2 }}
                    >
                      Visit Website
                    </Button>
                  )}
                  {profile.discord && (
                    <Button
                      variant="contained"
                      color="primary"
                      href={profile.discord}
                      target="_blank"
                      startIcon={<LinkIcon />}
                      sx={{ mt: 2, mr: 2 }}
                    >
                      Join Discord
                    </Button>
                  )}
                  {profile.donationLink && (
                    <Button
                      variant="contained"
                      color="secondary"
                      href={profile.donationLink}
                      target="_blank"
                      sx={{ mt: 2 }}
                    >
                      Support Creator
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* User Stats */}
        <Grid item xs={12}>
          <UserStats stats={stats} />
        </Grid>

        {/* User Mods */}
        <Grid item xs={12}>
          <UserMods 
            uploadedMods={uploadedMods} 
            loading={loading}
            isViewProfile={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
