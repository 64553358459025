import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  MenuItem,
  Typography,
  Alert
} from '@mui/material';
import { reportService } from '../../services/reportService';
import { useAuth } from '../../contexts/AuthContext';
import { serverTimestamp } from 'firebase/firestore';

const REPORT_REASONS = [
  'Inappropriate Content',
  'Copyright Infringement',
  'Offensive Material',
  'Spam or Scam',
  'Other'
];

export default function ReportModal({ 
  open, 
  onClose, 
  itemId, 
  itemTitle,
  itemType = 'mod'
}) {
  const { currentUser } = useAuth();
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmitReport = async () => {
    if (!currentUser) {
      console.error('User must be authenticated to submit a report');
      return;
    }

    // Validate required fields
    if (!itemType || !itemId) {
      console.error('Missing required report information', { 
        itemType, 
        itemId, 
        context: 'Report Modal Submission' 
      });
      return;
    }

    if (!reason) {
      console.error('Report reason is required');
      return;
    }

    try {
      const reportData = {
        itemType,
        itemId,
        reportedBy: currentUser.uid,
        reason,
        description: description || '',
        status: 'pending',
        createdAt: serverTimestamp()
      };

      await reportService.createReport(reportData);
      setSuccess('Report submitted successfully');
      setTimeout(() => {
        onClose();
        setSuccess('');
      }, 2000);
    } catch (error) {
      console.error('Error submitting report:', error);
      setError(error.message || 'Failed to submit report');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setReason('');
    setDescription('');
    setError('');
    setSuccess('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Report {itemType}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          Item: {itemTitle}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}

        <TextField
          select
          fullWidth
          label="Reason for Reporting"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          sx={{ mb: 2 }}
        >
          {REPORT_REASONS.map((reportReason) => (
            <MenuItem key={reportReason} value={reportReason}>
              {reportReason}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          fullWidth
          multiline
          rows={4}
          label="Additional Description (Optional)"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button 
          onClick={handleSubmitReport} 
          color="primary" 
          variant="contained"
          disabled={loading}
        >
          Submit Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}
