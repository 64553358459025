import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress
} from '@mui/material';
import { 
  collection, 
  query, 
  getDocs, 
  doc, 
  updateDoc,
  deleteDoc
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';

export default function ReportsManagement() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedReport, setSelectedReport] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setLoading(true);
        const reportsQuery = query(collection(db, 'reports'));
        const snapshot = await getDocs(reportsQuery);
        
        const reportsList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setReports(reportsList);
        setError('');
      } catch (error) {
        console.error('Error fetching reports:', error);
        setError('Failed to load reports: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleResolveReport = async (reportId, status) => {
    try {
      const reportRef = doc(db, 'reports', reportId);
      await updateDoc(reportRef, {
        status: status,
        resolvedBy: currentUser.uid,
        resolvedAt: new Date()
      });

      // Update local state
      setReports(reports.map(report => 
        report.id === reportId 
          ? { ...report, status: status, resolvedBy: currentUser.uid, resolvedAt: new Date() }
          : report
      ));

      setSelectedReport(null);
    } catch (error) {
      console.error('Error resolving report:', error);
      setError('Failed to resolve report');
    }
  };

  const handleDeleteReport = async (reportId) => {
    try {
      await deleteDoc(doc(db, 'reports', reportId));
      
      // Update local state
      setReports(reports.filter(report => report.id !== reportId));
      setSelectedReport(null);
    } catch (error) {
      console.error('Error deleting report:', error);
      setError('Failed to delete report');
    }
  };

  const getStatusColor = (status) => {
    switch(status) {
      case 'pending': return 'warning';
      case 'resolved': return 'success';
      case 'rejected': return 'error';
      default: return 'default';
    }
  };

  const renderReportDetails = () => {
    if (!selectedReport) return null;

    return (
      <Dialog 
        open={!!selectedReport} 
        onClose={() => setSelectedReport(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Report Details</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            <strong>Reported Item:</strong> {selectedReport.itemType} - {selectedReport.itemId}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Reported By:</strong> {selectedReport.reportedBy}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Reason:</strong> {selectedReport.reason}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Description:</strong> {selectedReport.description}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Status:</strong> 
            <Chip 
              label={selectedReport.status} 
              color={getStatusColor(selectedReport.status)} 
              size="small" 
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          {selectedReport.status === 'pending' && (
            <>
              <Button 
                onClick={() => handleResolveReport(selectedReport.id, 'resolved')} 
                color="success"
              >
                Resolve
              </Button>
              <Button 
                onClick={() => handleResolveReport(selectedReport.id, 'rejected')} 
                color="error"
              >
                Reject
              </Button>
            </>
          )}
          <Button onClick={() => handleDeleteReport(selectedReport.id)} color="secondary">
            Delete Report
          </Button>
          <Button onClick={() => setSelectedReport(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Reports Management
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Type</TableCell>
              <TableCell>Reported By</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow key={report.id}>
                <TableCell>{report.itemType}</TableCell>
                <TableCell>{report.reportedBy}</TableCell>
                <TableCell>{report.reason}</TableCell>
                <TableCell>
                  <Chip 
                    label={report.status} 
                    color={getStatusColor(report.status)} 
                    size="small" 
                  />
                </TableCell>
                <TableCell>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={() => setSelectedReport(report)}
                  >
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {renderReportDetails()}
    </Container>
  );
}
