import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Chip,
  Rating,
  CircularProgress,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  orderBy,
  doc,
  getDoc,
  updateDoc
} from 'firebase/firestore';
import { db } from '../../firebase/config';

export default function ModRecommendations() {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecommendations();
  }, [currentUser]);

  const fetchRecommendations = async () => {
    try {
      const userPreferences = await getUserPreferences();
      const recommendedMods = await getRecommendedMods(userPreferences);
      setRecommendations(recommendedMods);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
    } finally {
      setLoading(false);
    }
  };

  const getUserPreferences = async () => {
    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
    return userDoc.data();
  };

  const getRecommendedMods = async (preferences) => {
    const recommendedMods = [];
    
    // Get mods matching user's preferred styles
    if (preferences.preferredStyles?.length > 0) {
      const styleQuery = query(
        collection(db, 'mods'),
        where('tags', 'array-contains-any', preferences.preferredStyles),
        orderBy('rating', 'desc'),
        limit(5)
      );
      const styleSnapshot = await getDocs(styleQuery);
      recommendedMods.push(...styleSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        matchReason: 'Matches your preferred drifting style'
      })));
    }

    // Get popular mods for user's experience level
    if (preferences.experienceLevel) {
      const levelQuery = query(
        collection(db, 'mods'),
        where('difficulty', '==', preferences.experienceLevel.toLowerCase()),
        orderBy('downloads', 'desc'),
        limit(5)
      );
      const levelSnapshot = await getDocs(levelQuery);
      recommendedMods.push(...levelSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        matchReason: `Popular with ${preferences.experienceLevel} drivers`
      })));
    }

    // Get trending mods
    const trendingQuery = query(
      collection(db, 'mods'),
      orderBy('downloads', 'desc'),
      limit(5)
    );
    const trendingSnapshot = await getDocs(trendingQuery);
    recommendedMods.push(...trendingSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      matchReason: 'Trending in the community'
    })));

    // Remove duplicates and limit to 12 recommendations
    const uniqueMods = Array.from(
      new Map(recommendedMods.map(mod => [mod.id, mod])).values()
    ).slice(0, 12);

    return uniqueMods;
  };

  const handleModClick = (modId) => {
    navigate(`/mods/${modId}`);
  };

  const handleFinish = async () => {
    try {
      // Update user's onboarding status
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        'onboarding.modRecommendationsCompleted': true,
        'onboarding.modRecommendationsCompletedAt': new Date().toISOString(),
        'onboarding.lastStep': 'completed',
        'onboarding.completed': true,
        'onboarding.completedAt': new Date().toISOString(),
        'recommendedMods': []
      });

      // Navigate to profile page
      navigate('/profile');
    } catch (error) {
      console.error('Error saving recommendations:', error);
      // Handle error appropriately
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Recommended Mods for You
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          textAlign="center"
          gutterBottom
        >
          Based on your preferences, we've picked some mods you might enjoy.
        </Typography>
      </Paper>

      <Grid container spacing={3}>
        {recommendations.map((mod) => (
          <Grid item xs={12} sm={6} md={4} key={mod.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
              onClick={() => handleModClick(mod.id)}
            >
              <CardMedia
                component="img"
                height="200"
                loading="lazy"
                image={mod.thumbnailUrl || mod.imageUrl || '/default-mod-image.jpg'}
                alt={mod.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" gutterBottom>
                  {mod.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {mod.description?.slice(0, 120)}...
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Chip 
                    label={mod.matchReason} 
                    color="primary" 
                    size="small" 
                    sx={{ mr: 1 }} 
                  />
                </Box>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Rating value={mod.rating || 0} readOnly size="small" />
                  <Typography variant="body2" color="text.secondary">
                    ({mod.downloads || 0} downloads)
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleFinish}
        >
          Continue to Dashboard
        </Button>
      </Box>
    </Box>
  );
}
