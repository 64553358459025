import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ModCard from '../mods/ModCard';
import UserReports from './UserReports';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`mods-tabpanel-${index}`}
      aria-labelledby={`mods-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

export default function UserMods({ uploadedMods, favoriteMods, reports, loading, isViewProfile = false }) {
  const [tabValue, setTabValue] = useState(0);
  const [showAllUploaded, setShowAllUploaded] = useState(false);
  const [showAllFavorites, setShowAllFavorites] = useState(false);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const displayedUploadedMods = showAllUploaded ? uploadedMods : uploadedMods?.slice(0, 6);
  const displayedFavoriteMods = showAllFavorites ? favoriteMods : favoriteMods?.slice(0, 6);

  return (
    <Paper sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5">
          {isViewProfile ? 'User Mods' : 'My Mods'}
        </Typography>
        {!isViewProfile && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/upload')}
          >
            Upload New Mod
          </Button>
        )}
      </Box>

      {!isViewProfile && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="mod tabs"
          >
            <Tab label={`Uploaded (${uploadedMods?.length || 0})`} />
            <Tab label={`Favorites (${favoriteMods?.length || 0})`} />
            <Tab label="Reports" />
          </Tabs>
        </Box>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={3}>
              {displayedUploadedMods?.length > 0 ? (
                <>
                  {displayedUploadedMods.map((mod) => (
                    <Grid item xs={12} sm={6} md={4} key={mod.id}>
                      <ModCard
                        mod={mod}
                        showStatus={!isViewProfile}
                        showEditButton={!isViewProfile}
                      />
                    </Grid>
                  ))}
                  {uploadedMods?.length > 6 && !showAllUploaded && (
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                          variant="outlined"
                          onClick={() => setShowAllUploaded(true)}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          Show More ({uploadedMods.length - 6} more)
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={12}>
                  <Box sx={{ textAlign: 'center', py: 4 }}>
                    <Typography color="text.secondary">
                      {isViewProfile 
                        ? 'No mods uploaded yet' 
                        : 'You haven\'t uploaded any mods yet'}
                    </Typography>
                    {!isViewProfile && (
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate('/upload')}
                        sx={{ mt: 2 }}
                      >
                        Upload Your First Mod
                      </Button>
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={3}>
              {displayedFavoriteMods?.length > 0 ? (
                <>
                  {displayedFavoriteMods.map((mod) => (
                    <Grid item xs={12} sm={6} md={4} key={mod.id}>
                      <ModCard mod={mod} />
                    </Grid>
                  ))}
                  {favoriteMods?.length > 6 && !showAllFavorites && (
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                          variant="outlined"
                          onClick={() => setShowAllFavorites(true)}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          Show More ({favoriteMods.length - 6} more)
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={12}>
                  <Box sx={{ textAlign: 'center', py: 4 }}>
                    <Typography color="text.secondary">
                      No favorite mods yet
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <UserReports reports={reports} />
          </TabPanel>
        </>
      )}
    </Paper>
  );
}
