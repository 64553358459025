import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  RedditIcon,
  WhatsappIcon,
  TelegramIcon
} from 'react-share';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

const ShareButtons = ({ mod }) => {
  if (!mod) return null;

  const shareUrl = `${window.location.origin}/mod/${mod.id}`;
  const title = `Check out ${mod.title} for Assetto Corsa!`;
  const hashtags = ['AssettoCorsaMods', 'ACDriftingPro', ...(mod.tags || [])];

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    // You could add a toast notification here
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      <FacebookShareButton url={shareUrl} quote={title} hashtag="#AssettoCorsaMods">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={shareUrl} title={title} hashtags={hashtags}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <RedditShareButton url={shareUrl} title={title}>
        <RedditIcon size={32} round />
      </RedditShareButton>

      <WhatsappShareButton url={shareUrl} title={title}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <TelegramShareButton url={shareUrl} title={title}>
        <TelegramIcon size={32} round />
      </TelegramShareButton>

      <Tooltip title="Copy Link">
        <IconButton onClick={handleCopyLink} size="small">
          <ContentCopy />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ShareButtons;
