import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Box,
  Alert,
  MenuItem,
  CircularProgress,
  Grid,
  useTheme,
  Card,
  CardContent,
} from '@mui/material';
import { 
  Email as EmailIcon,
  Support as SupportIcon,
  Send as SendIcon,
  Chat as ChatIcon,
  Forum as ForumIcon,
  ArrowForward as ArrowForwardIcon
} from '@mui/icons-material';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ReCaptcha from '../components/common/ReCaptcha';

const CONTACT_REASONS = [
  'Technical Support',
  'Account Issues',
  'Subscription Help',
  'Report a Bug',
  'Feature Request',
  'Other'
];

const SUPPORT_CHANNELS = [
  {
    title: 'Discord Community',
    description: 'Join our Discord server for real-time support and community discussions',
    icon: ChatIcon,
    link: 'https://discord.gg/your-discord',
    buttonText: 'Join Discord',
    color: '#5865F2' // Discord brand color
  },
  {
    title: 'Community Guides',
    description: 'Browse our guides and find answers to common questions',
    icon: ForumIcon,
    link: '/guides',
    buttonText: 'Visit Guides',
    color: '#00796b' // Teal color
  },
  {
    title: 'Email Support',
    description: 'Contact us directly via email for dedicated support',
    icon: EmailIcon,
    link: 'mailto:support@acdriftingpro.com',
    buttonText: 'Send Email',
    color: '#d32f2f' // Red color
  }
];

export default function Contact() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    reason: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login', { state: { from: '/contact' } });
    }
  }, [currentUser, navigate]);

  // If not authenticated, don't render the page content
  if (!currentUser) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaVerified) {
      setError('Please complete the reCAPTCHA verification');
      return;
    }
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      await addDoc(collection(db, 'support_tickets'), {
        userId: currentUser?.uid || 'anonymous',
        userEmail: currentUser?.email || 'anonymous',
        reason: formData.reason,
        subject: formData.subject,
        message: formData.message,
        status: 'open',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      setSuccess(true);
      setFormData({ reason: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error submitting support ticket:', error);
      setError('Failed to submit your message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
    setError('');
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Hero Section */}
      <Box
        sx={{
          background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
          borderRadius: 4,
          p: 6,
          mb: 8,
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}22, ${theme.palette.secondary.main}22)`,
            zIndex: 1
          }
        }}
      >
        <Grid container spacing={4} alignItems="center" sx={{ position: 'relative', zIndex: 2 }}>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Need Help?
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                color: theme.palette.text.secondary,
                mb: 4,
                maxWidth: '80%'
              }}
            >
              We're here to assist you with any questions or issues you might have.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                position: 'relative'
              }}
            >
              <SupportIcon 
                sx={{ 
                  fontSize: 200, 
                  color: theme.palette.primary.main, 
                  opacity: 0.8,
                  filter: 'drop-shadow(0 4px 20px rgba(0,0,0,0.2))'
                }} 
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Support Channels */}
      <Box sx={{ mb: 12 }}>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 700,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            mb: 6
          }}
        >
          Support Channels
        </Typography>
        <Grid container spacing={4}>
          {SUPPORT_CHANNELS.map((channel, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'all 0.3s ease-in-out',
                  background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
                  borderRadius: 3,
                  overflow: 'hidden',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 30px rgba(0,0,0,0.2)',
                  }
                }}
              >
                <CardContent sx={{ p: 4, flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 3,
                      background: `linear-gradient(45deg, ${channel.color}22, ${channel.color}11)`,
                      p: 2,
                      borderRadius: 2
                    }}
                  >
                    <channel.icon
                      sx={{
                        fontSize: 40,
                        color: channel.color,
                        mr: 2
                      }}
                    />
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        background: `linear-gradient(45deg, ${channel.color}, ${theme.palette.primary.main})`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                      }}
                    >
                      {channel.title}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 3,
                      color: theme.palette.text.secondary
                    }}
                  >
                    {channel.description}
                  </Typography>
                  <Button
                    variant="contained"
                    href={channel.link}
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      background: `linear-gradient(45deg, ${channel.color}, ${theme.palette.primary.main})`,
                      color: 'white',
                      px: 3,
                      py: 1,
                      '&:hover': {
                        background: `linear-gradient(45deg, ${channel.color}, ${theme.palette.primary.dark})`
                      }
                    }}
                  >
                    {channel.buttonText}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Contact Form */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 700,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            mb: 6
          }}
        >
          Contact Support
        </Typography>
        <Paper
          component="form"
          onSubmit={handleSubmit}
          sx={{
            p: 4,
            background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
            borderRadius: 3,
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          }}
        >
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 3 }}>
              Your message has been sent successfully! We'll get back to you soon.
            </Alert>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Reason for Contact"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
              >
                {CONTACT_REASONS.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ReCaptcha onChange={handleCaptchaChange} />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading || !captchaVerified}
                startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                sx={{
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  color: 'white',
                  px: 4,
                  py: 1.5,
                  '&:hover': {
                    background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
                  }
                }}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}
