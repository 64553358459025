import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Grid, 
  Paper, 
  Typography, 
  Avatar, 
  Box, 
  Button,
  CircularProgress,
  Alert,
  TextField
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { Link as LinkIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  doc, 
  getDoc,
  updateDoc
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import UserStats from '../components/profile/UserStats';
import UserMods from '../components/profile/UserMods';
import SubscriptionCard from '../components/profile/SubscriptionCard';
import { userModService } from '../services/userModService';
import { favoriteService } from '../services/favoriteService';
import { userReportService } from '../services/userReportService';

export default function Profile() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [editMode, setEditMode] = useState(false);

  const [profile, setProfile] = useState({
    displayName: '',
    bio: '',
    website: '',
    discord: '',
    donationLink: '',
    subscription: null
  });

  const [stats, setStats] = useState({});
  const [uploadedMods, setUploadedMods] = useState([]);
  const [favoriteMods, setFavoriteMods] = useState([]);
  const [reports, setReports] = useState([]);
  const [onboardingCompleted, setOnboardingCompleted] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth');
      return;
    }

    const fetchUserData = async () => {
      try {
        // Fetch user profile
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setProfile({
            displayName: userData.displayName || '',
            bio: userData.bio || '',
            website: userData.website || '',
            discord: userData.discord || '',
            donationLink: userData.donationLink || '',
            subscription: userData.subscription || null
          });
          
          // Check onboarding status
          setOnboardingCompleted(
            userData.onboarding?.completed === true || 
            (userData.onboarding?.welcomeTourCompleted && 
             userData.onboarding?.profileCompleted && 
             userData.onboarding?.modRecommendationsCompleted)
          );
        }

        // Fetch user's uploaded mods
        const uploadedModsList = await userModService.getUserUploads(currentUser.uid);
        setUploadedMods(uploadedModsList);

        // Fetch user's favorite mods
        const favoriteModsList = await favoriteService.getUserFavoriteMods(currentUser.uid);
        setFavoriteMods(favoriteModsList);

        // Fetch user's reports
        const reportsList = await userReportService.getUserReports(currentUser.uid);
        setReports(reportsList);

        // Fetch upload stats
        const uploadStats = await userModService.getUploadStats(currentUser.uid);
        
        // Calculate user stats
        const stats = {
          uploadCount: uploadStats.totalUploads,
          downloadCount: uploadStats.totalDownloads,
          averageRating: uploadStats.averageRating,
          favoriteCount: favoriteModsList.length,
          topCategories: Object.entries(uploadStats.categories)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 3)
            .map(([category, count]) => ({ category, count })),
          recentUpload: uploadStats.recentUpload
        };
        setStats(stats);

      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load profile data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser, navigate]);

  const handleProfileUpdate = async () => {
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        displayName: profile.displayName,
        bio: profile.bio,
        website: profile.website,
        discord: profile.discord,
        donationLink: profile.donationLink
      });
      setSuccess('Profile updated successfully');
      setEditMode(false);
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  const handleUpgradeSubscription = () => {
    navigate('/premium');
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Profile Info and Subscription */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box sx={{ textAlign: 'center', mb: 3 }}>
              <Avatar
                src={currentUser?.photoURL}
                alt={profile.displayName || currentUser?.email}
                sx={{ width: 120, height: 120, mx: 'auto', mb: 2 }}
              />
              {editMode ? (
                <TextField
                  fullWidth
                  label="Display Name"
                  value={profile.displayName}
                  onChange={(e) =>
                    setProfile({ ...profile, displayName: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="h5" gutterBottom>
                  {profile.displayName || currentUser?.email}
                </Typography>
              )}
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                gap: 1,
                mt: 2 
              }}>
                <Button
                  fullWidth
                  startIcon={<EditIcon />}
                  onClick={() => {
                    if (editMode) {
                      handleProfileUpdate();
                    } else {
                      setEditMode(true);
                    }
                  }}
                  variant={editMode ? "contained" : "outlined"}
                  sx={{ maxWidth: 250 }}
                >
                  {editMode ? 'Save Profile' : 'Edit Profile'}
                </Button>
                {!onboardingCompleted && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/onboarding/welcome')}
                    sx={{ maxWidth: 250 }}
                  >
                    Complete Onboarding
                  </Button>
                )}
              </Box>
            </Box>

            {editMode ? (
              <>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Bio"
                  value={profile.bio}
                  onChange={(e) =>
                    setProfile({ ...profile, bio: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Website"
                  value={profile.website}
                  onChange={(e) =>
                    setProfile({ ...profile, website: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Discord Server Link"
                  value={profile.discord}
                  onChange={(e) =>
                    setProfile({ ...profile, discord: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Donation Link"
                  value={profile.donationLink}
                  onChange={(e) =>
                    setProfile({ ...profile, donationLink: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
              </>
            ) : (
              <>
                {profile.bio && (
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                    {profile.bio}
                  </Typography>
                )}
                {profile.website && (
                  <Button
                    variant="contained"
                    color="primary"
                    href={profile.website}
                    target="_blank"
                    startIcon={<LinkIcon />}
                    sx={{ mt: 2, mr: 2 }}
                  >
                    Visit Website
                  </Button>
                )}
                {profile.discord && (
                  <Button
                    variant="contained"
                    color="primary"
                    href={profile.discord}
                    target="_blank"
                    startIcon={<LinkIcon />}
                    sx={{ mt: 2, mr: 2 }}
                  >
                    Join Discord
                  </Button>
                )}
                {profile.donationLink && (
                  <Button
                    variant="contained"
                    color="secondary"
                    href={profile.donationLink}
                    target="_blank"
                    sx={{ mt: 2 }}
                  >
                    Support Creator
                  </Button>
                )}
              </>
            )}
          </Paper>

          {/* Subscription Card */}
          <SubscriptionCard
            currentUser={currentUser}
            onUpgrade={handleUpgradeSubscription}
          />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} md={8}>
          <Box sx={{ mb: 3 }}>
            <UserStats stats={stats} />
          </Box>

          <Box sx={{ mt: 3 }}>
            <UserMods
              uploadedMods={uploadedMods}
              favoriteMods={favoriteMods}
              reports={reports}
              loading={loading}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
