import React from 'react';
import { Button, Typography, Box, Container } from '@mui/material';
import { auth } from '../firebase/config';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    console.error('Error caught by boundary:', error, errorInfo);
  }

  handleRetry = () => {
    // Force a hard reload of the page
    window.location.reload(true);
  };

  handleSignOut = async () => {
    try {
      await auth.signOut();
      // Force a hard reload after sign out
      window.location.reload(true);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  render() {
    if (this.state.hasError) {
      const isFirebaseError = this.state.error?.message?.includes('Firebase');
      const isNetworkError = this.state.error?.message?.includes('network') || 
                            this.state.error?.message?.includes('fetch');

      return (
        <Container>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            minHeight: '100vh',
            textAlign: 'center',
            gap: 2
          }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Something went wrong
            </Typography>
            
            <Typography variant="body1" gutterBottom>
              {isFirebaseError ? (
                "We're having trouble connecting to our services."
              ) : isNetworkError ? (
                "There seems to be a network connectivity issue."
              ) : (
                "An unexpected error occurred."
              )}
            </Typography>

            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <Button 
                variant="contained" 
                color="primary"
                onClick={this.handleRetry}
              >
                Retry
              </Button>
              
              {isFirebaseError && (
                <Button 
                  variant="outlined" 
                  color="secondary"
                  onClick={this.handleSignOut}
                >
                  Sign Out
                </Button>
              )}
            </Box>

            {process.env.NODE_ENV === 'development' && (
              <Box sx={{ mt: 4, textAlign: 'left' }}>
                <Typography variant="body2" color="error">
                  {this.state.error?.toString()}
                </Typography>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.errorInfo?.componentStack}
                </pre>
              </Box>
            )}
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
