import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { doc, getDoc, collection, query, where, orderBy, startAfter, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Rating,
  Chip,
  CircularProgress,
  Divider,
  Avatar,
  Stack,
  IconButton,
  Breadcrumbs,
  Link,
  Tooltip,
  Tab,
  Tabs,
  MobileStepper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  Download,
  CalendarToday,
  Person,
  Share,
  Favorite,
  FavoriteBorder,
  Category,
  Update,
  Info,
  ArrowBack,
  Report,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  CloudDownloadOutlined,
  Storage,
  NewReleases,
  Games
} from '@mui/icons-material';
import ModDownload from '../components/mods/ModDownload';
import ModInstallGuide from '../components/mods/ModInstallGuide';
import ShareButtons from '../components/social/ShareButtons';
import Comments from '../components/community/Comments';
import Reviews from '../components/community/Reviews';
import RelatedMods from '../components/mods/RelatedMods';
import { useAuth } from '../contexts/AuthContext';
import { formatDistanceToNow } from 'date-fns';
import { favoriteService } from '../services/favoriteService';
import ReportModal from '../components/modals/ReportModal';
import { checkAdBlocker } from '../services/adBlockDetectionService';
import AdBlockerWarning from '../components/ads/AdBlockerWarning';

function TabPanel({ children, value, index }) {
  return (
    <Box role="tabpanel" hidden={value !== index} sx={{ py: 3 }}>
      {value === index && children}
    </Box>
  );
}

const ModDetails = () => {
  const { modId } = useParams();
  const [mod, setMod] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [isFavorited, setIsFavorited] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [nextMod, setNextMod] = useState(null);
  const { currentUser } = useAuth();
  const [isAdBlocked, setIsAdBlocked] = useState(false);

  useEffect(() => {
    const detectAdBlocker = async () => {
      const blocked = await checkAdBlocker();
      setIsAdBlocked(blocked);
    };
    
    detectAdBlocker();
  }, []);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    const fetchMod = async () => {
      if (!modId) {
        setError('No mod ID provided');
        setLoading(false);
        return;
      }

      try {
        const modRef = doc(db, 'mods', modId);
        const modSnap = await getDoc(modRef);

        if (modSnap.exists()) {
          const data = modSnap.data();
          setMod({
            id: modSnap.id,
            title: data.title || 'Untitled Mod',
            description: data.description || 'No description available',
            rating: data.rating || 0,
            downloadCount: data.downloads || 0,
            ratingCount: data.ratingCount || 0,
            tags: data.tags || [],
            screenshots: data.screenshots || [],
            authorName: data.authorName || 'Unknown',
            authorId: data.authorId || null,
            authorPhotoURL: data.authorPhotoURL || null,
            requirements: data.requirements || null,
            uploadDate: data.uploadedAt ? new Date(data.uploadedAt.toDate()).toLocaleDateString() : 'Unknown',
            lastUpdated: data.lastUpdated ? formatDistanceToNow(data.lastUpdated.toDate(), { addSuffix: true }) : 'Unknown',
            downloadUrl: data.downloadUrl || null,
            version: data.version || '1.0.0',
            category: data.category || 'Uncategorized',
            fileSize: data.fileSize || 0,
            dependencies: data.dependencies || [],
            ...data
          });

          // Fetch complete author information
          if (data.authorId) {
            const authorRef = doc(db, 'users', data.authorId);
            const authorDoc = await getDoc(authorRef);
            if (authorDoc.exists()) {
              const authorData = authorDoc.data();
              setMod(prevMod => ({
                ...prevMod,
                authorName: authorData.displayName || prevMod.authorName,
                authorPhotoURL: authorData.photoURL || currentUser?.photoURL || '/default-avatar.png',
                authorDonationLink: authorData.donationLink || null,
                authorBio: authorData.bio || null
              }));
            }
          }
        } else {
          setError('Mod not found');
        }
      } catch (err) {
        console.error('Error fetching mod:', err);
        setError('Failed to load mod details');
      }
      setLoading(false);
    };

    fetchMod();
  }, [modId]);

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      if (currentUser && modId) {
        try {
          const favoriteStatus = await favoriteService.checkIsFavorited(currentUser.uid, modId);
          setIsFavorited(favoriteStatus);
        } catch (error) {
          console.error('Failed to check favorite status:', error);
        }
      }
    };

    checkFavoriteStatus();
  }, [currentUser, modId]);

  useEffect(() => {
    const fetchNextMod = async () => {
      if (!mod?.category) return;
      
      try {
        const modsRef = collection(db, 'mods');
        const q = query(
          modsRef,
          where('category', '==', mod.category),
          where('status', '==', 'approved'),
          orderBy('uploadedAt', 'desc'),
          startAfter(mod.uploadedAt),
          limit(1)
        );
        
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          setNextMod({
            id: querySnapshot.docs[0].id,
            title: querySnapshot.docs[0].data().title
          });
        } else {
          // If no next mod, get the first mod in the category
          const firstModQuery = query(
            modsRef,
            where('category', '==', mod.category),
            where('status', '==', 'approved'),
            orderBy('uploadedAt', 'desc'),
            limit(1)
          );
          
          const firstModSnapshot = await getDocs(firstModQuery);
          if (!firstModSnapshot.empty && firstModSnapshot.docs[0].id !== modId) {
            setNextMod({
              id: firstModSnapshot.docs[0].id,
              title: firstModSnapshot.docs[0].data().title
            });
          } else {
            setNextMod(null);
          }
        }
      } catch (error) {
        console.error('Error fetching next mod:', error);
        setNextMod(null);
      }
    };

    fetchNextMod();
  }, [mod?.category, modId]);

  const handleFavoriteClick = async () => {
    if (!currentUser) {
      // Redirect to login or show login dialog
      return;
    }

    try {
      const newFavoriteStatus = await favoriteService.toggleFavorite(currentUser.uid, modId);
      setIsFavorited(newFavoriteStatus);
    } catch (error) {
      console.error('Failed to toggle favorite:', error);
      // Optionally show an error toast or snackbar
    }
  };

  const handleReportClick = () => {
    if (!mod || !mod.id) {
      console.error('Cannot report: mod or mod.id is undefined', { mod });
      return;
    }
    setReportModalOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <Typography color="error" variant="h6">{error}</Typography>
      </Box>
    );
  }

  if (!mod) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <Typography variant="h6">Mod not found</Typography>
      </Box>
    );
  }

  const ModRequirements = ({ mod }) => {
    if (!mod.requirements) return null;

    return (
      <Box>
        {mod.requirements.dlcs && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom color="primary">
              Required DLCs
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {Array.isArray(mod.requirements.dlcs) ? mod.requirements.dlcs.join(', ') : 'None'}
            </Typography>
          </Box>
        )}
        {mod.requirements.dependencies && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom color="primary">
              Dependencies
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {Array.isArray(mod.requirements.dependencies) ? mod.requirements.dependencies.join(', ') : 'None'}
            </Typography>
          </Box>
        )}
        {mod.requirements.minGameVersion && (
          <Box>
            <Typography variant="h6" gutterBottom color="primary">
              Minimum Game Version
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {mod.requirements.minGameVersion}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {/* Hero Header with Screenshots */}
      {mod?.screenshots && mod.screenshots.length > 0 && (
        <Box 
          sx={{ 
            position: 'relative',
            width: '100%',
            height: { xs: '300px', sm: '400px', md: '500px' },
            mb: 4,
            overflow: 'hidden',
            borderRadius: { xs: 0, sm: '16px' },
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
          }}
        >
          <Box
            component="img"
            src={mod.screenshots[activeStep]}
            alt={`Screenshot ${activeStep + 1}`}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              filter: 'brightness(0.8)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.02)'
              }
            }}
          />
          
          {/* Navigation Arrows */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 2,
            }}
          >
            <IconButton 
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.15)',
                backdropFilter: 'blur(8px)',
                '&:hover': { 
                  bgcolor: 'rgba(255, 255, 255, 0.3)',
                  transform: 'scale(1.1)'
                },
                color: 'white',
                transition: 'all 0.2s ease'
              }}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton 
              onClick={handleNext}
              disabled={activeStep === mod.screenshots.length - 1}
              sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.15)',
                backdropFilter: 'blur(8px)',
                '&:hover': { 
                  bgcolor: 'rgba(255, 255, 255, 0.3)',
                  transform: 'scale(1.1)'
                },
                color: 'white',
                transition: 'all 0.2s ease'
              }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>

          {/* Title Overlay */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              p: 4,
              background: 'linear-gradient(to top, rgba(0,0,0,0.9), transparent)',
              backdropFilter: 'blur(8px)',
              color: 'white',
            }}
          >
            <Container maxWidth="xl">
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: 3 
              }}>
                <Typography variant="h4" component="h1" gutterBottom>
                  {mod.title}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  gap: 1,
                  alignItems: 'center' 
                }}>
                  <Tooltip title="Add to Favorites">
                    <IconButton 
                      onClick={handleFavoriteClick}
                      sx={{
                        transition: 'transform 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        }
                      }}
                    >
                      {isFavorited ? (
                        <Favorite color="error" />
                      ) : (
                        <FavoriteBorder />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Report Mod">
                    <IconButton 
                      onClick={handleReportClick}
                      sx={{
                        transition: 'transform 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                          color: 'error.main'
                        }
                      }}
                    >
                      <Report />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              
            </Container>
          </Box>

          {/* Screenshot Counter */}
          <Box
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              bgcolor: 'rgba(0, 0, 0, 0.6)',
              backdropFilter: 'blur(8px)',
              color: 'white',
              px: 2,
              py: 1,
              borderRadius: 2,
              fontSize: '0.875rem',
              fontWeight: 'medium'
            }}
          >
            {activeStep + 1} / {mod.screenshots.length}
          </Box>
        </Box>
      )}

      <Container maxWidth="xl" sx={{ py: 4 }}>
        {/* Breadcrumbs */}
        <Box sx={{ mb: 4 }}>
          <Breadcrumbs 
            aria-label="breadcrumb"
            sx={{ 
              mb: 2,
              '& .MuiBreadcrumbs-separator': {
                color: 'text.secondary'
              }
            }}
          >
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  color: 'primary.main',
                  textDecoration: 'underline'
                }
              }}
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to="/mods"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  color: 'primary.main',
                  textDecoration: 'underline'
                }
              }}
            >
              Mods
            </Link>
            <Link
              component={RouterLink}
              to={`/mods?category=${mod.category}`}
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  color: 'primary.main',
                  textDecoration: 'underline'
                }
              }}
            >
              {mod.category}
            </Link>
            <Typography color="text.primary">
              {mod.title}
            </Typography>
          </Breadcrumbs>
        </Box>

        <Grid container spacing={4}>
          {/* Left Column - Main Content */}
          <Grid item xs={12} md={8}>
            {/* Stats and Info Section */}
            <Paper 
              elevation={0}
              sx={{ 
                p: 4, 
                mb: 4,
                borderRadius: 4,
                background: (theme) => theme.palette.mode === 'dark' 
                  ? 'linear-gradient(145deg, rgba(40,40,40,0.9), rgba(20,20,20,0.9))'
                  : 'linear-gradient(145deg, rgba(255,255,255,0.9), rgba(240,240,240,0.9))',
                backdropFilter: 'blur(10px)',
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom color="primary">
                  Description
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                  {mod.description}
                </Typography>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom color="primary">
                  Tags
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {mod.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      sx={{ 
                        borderRadius: '8px',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Paper>

            {/* Tabs Section */}
            <Paper 
              elevation={0}
              sx={{ 
                borderRadius: 4,
                overflow: 'hidden',
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <Tabs 
                value={tabValue} 
                onChange={(e, newValue) => setTabValue(newValue)}
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  bgcolor: 'background.paper',
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 500,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      bgcolor: 'action.hover'
                    }
                  }
                }}
              >
                <Tab label="Information" />
                <Tab label="Details" />
                <Tab label="Installation Guide" />
                <Tab label="Comments" />
                <Tab label="Reviews" />
              </Tabs>

              <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
                <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={3} sx={{ mb: 4 }}>
                    <Grid item xs={12} md={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: '100%',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 2,
                          background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <Rating 
                            value={Number(mod.rating)} 
                            readOnly 
                            precision={0.5} 
                            size="large"
                            sx={{ 
                              mb: 1,
                              '& .MuiRating-iconFilled': {
                                color: 'primary.main'
                              }
                            }} 
                          />
                          <Typography 
                            variant="h5" 
                            sx={{ 
                              fontWeight: 'bold',
                              background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                            }}
                          >
                            {mod.rating.toFixed(1)}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            {mod.ratingCount} ratings
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: '100%',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 2,
                          background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <CloudDownloadOutlined 
                            sx={{ 
                              fontSize: 40, 
                              mb: 1,
                              color: 'primary.main'
                            }} 
                          />
                          <Typography 
                            variant="h5" 
                            sx={{ 
                              fontWeight: 'bold',
                              background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                            }}
                          >
                            {mod.downloadCount ? mod.downloadCount.toLocaleString() : '0'}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            Downloads
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: '100%',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 2,
                          background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <Storage 
                            sx={{ 
                              fontSize: 40, 
                              mb: 1,
                              color: 'primary.main'
                            }} 
                          />
                          <Typography 
                            variant="h5" 
                            sx={{ 
                              fontWeight: 'bold',
                              background: 'linear-gradient(45deg, #00BFA5 30%, #64FFDA 90%)',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                            }}
                          >
                            {(mod.fileSize / 1024 / 1024).toFixed(2)} MB
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            File Size
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: '100%',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 2,
                          background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <CalendarToday 
                            sx={{ 
                              fontSize: 40, 
                              mb: 1,
                              color: 'primary.main'
                            }} 
                          />
                          <Typography 
                            variant="h5" 
                            sx={{ 
                              fontWeight: 'bold',
                              background: 'linear-gradient(45deg, #9C27B0 30%, #E040FB 90%)',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                            }}
                          >
                            {mod.uploadDate}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            Upload Date
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: '100%',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 2,
                          background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        <Box>
                          <Typography variant="h6" gutterBottom color="primary">
                            Category
                          </Typography>
                          <Typography variant="body1" sx={{ opacity: 0.9 }}>
                            {mod.category}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: '100%',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 2,
                          background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        <Box>
                          <Typography variant="h6" gutterBottom color="primary">
                            Upload Date
                          </Typography>
                          <Typography variant="body1" sx={{ opacity: 0.9 }}>
                            {new Date(mod.uploadDate).toLocaleDateString()}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: '100%',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 2,
                          background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        <Box>
                          <Typography variant="h6" gutterBottom color="primary">
                            Last Updated
                          </Typography>
                          <Typography variant="body1" sx={{ opacity: 0.9 }}>
                            {new Date(mod.lastUpdated).toLocaleDateString()}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    {mod.requirements && (
                      <Grid item xs={12}>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 3,
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 2,
                            background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)',
                          }}
                        >
                          <Typography variant="h6" gutterBottom color="primary">
                            Requirements
                          </Typography>
                          <Grid container spacing={3}>
                            {mod.requirements.dlcs && (
                              <Grid item xs={12} sm={6}>
                                <Box>
                                  <Typography variant="subtitle1" color="primary" gutterBottom>
                                    Required DLCs
                                  </Typography>
                                  <List>
                                    {Array.isArray(mod.requirements.dlcs) ? (
                                      mod.requirements.dlcs.map((dlc, index) => (
                                        <ListItem key={index}>
                                          <ListItemIcon>
                                            <Games color="primary" />
                                          </ListItemIcon>
                                          <ListItemText primary={dlc} />
                                        </ListItem>
                                      ))
                                    ) : (
                                      <ListItem>
                                        <ListItemText primary="None" />
                                      </ListItem>
                                    )}
                                  </List>
                                </Box>
                              </Grid>
                            )}

                            {mod.requirements.dependencies && (
                              <Grid item xs={12} sm={6}>
                                <Box>
                                  <Typography variant="subtitle1" color="primary" gutterBottom>
                                    Dependencies
                                  </Typography>
                                  <List>
                                    {Array.isArray(mod.requirements.dependencies) ? (
                                      mod.requirements.dependencies.map((dep, index) => (
                                        <ListItem 
                                          key={index}
                                          component={RouterLink}
                                          to={`/mod/${dep.id}`}
                                          sx={{
                                            borderRadius: 1,
                                            '&:hover': {
                                              bgcolor: 'action.hover'
                                            }
                                          }}
                                        >
                                          <ListItemIcon>
                                            <Games color="primary" />
                                          </ListItemIcon>
                                          <ListItemText 
                                            primary={dep.name}
                                            secondary={`Version ${dep.version}`}
                                          />
                                        </ListItem>
                                      ))
                                    ) : (
                                      <ListItem>
                                        <ListItemText primary="None" />
                                      </ListItem>
                                    )}
                                  </List>
                                </Box>
                              </Grid>
                            )}

                            {mod.requirements.minGameVersion && (
                              <Grid item xs={12}>
                                <Box>
                                  <Typography variant="subtitle1" color="primary" gutterBottom>
                                    Minimum Game Version
                                  </Typography>
                                  <Typography variant="body1" sx={{ opacity: 0.9 }}>
                                    {mod.requirements.minGameVersion}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                  <ModInstallGuide mod={mod} />
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                  <Comments modId={mod.id} />
                </TabPanel>

                <TabPanel value={tabValue} index={4}>
                  <Reviews modId={mod.id} />
                </TabPanel>
              </Box>
            </Paper>
          </Grid>
          {isAdBlocked && !currentUser?.subscription?.type === 'premium' && (
  <AdBlockerWarning />
)}
          {/* Right Column - Sidebar */}
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              {/* Download Section */}
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  borderRadius: 4,
                  border: '1px solid',
                  borderColor: 'divider'
                }}
              >
                <ModDownload mod={mod} />
              </Paper>

              {/* Creator Section */}
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  borderRadius: 4,
                  border: '1px solid',
                  borderColor: 'divider'
                }}
              >
                <Box>
                  <Typography variant="h6" gutterBottom color="primary">
                    Creator
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    p: 3,
                    bgcolor: 'background.paper',
                    borderRadius: 2
                  }}>
                    <Avatar
                      src={mod.authorPhotoURL}
                      alt={mod.authorName}
                      sx={{ 
                        width: 120, 
                        height: 120,
                        border: '4px solid',
                        borderColor: 'primary.main',
                        mb: 2,
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                      }}
                    />
                    <Typography variant="h5" gutterBottom fontWeight="bold">
                      {mod.authorName}
                    </Typography>
                    {mod.authorId && (
                      <Button
                        component={RouterLink}
                        to={`/profile/${mod.authorId}`}
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ 
                          mb: 2,
                          '&:hover': {
                            bgcolor: 'primary.main',
                            color: 'white'
                          }
                        }}
                      >
                        View Profile
                      </Button>
                    )}
                    {mod.authorDonationLink && (
                      <Button
                        variant="contained"
                        color="secondary"
                        href={mod.authorDonationLink}
                        target="_blank"
                        startIcon={<FavoriteBorder />}
                        sx={{ 
                          fontWeight: 'bold',
                          px: 3,
                          '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                          },
                          transition: 'all 0.2s ease-in-out'
                        }}
                      >
                        Support Creator
                      </Button>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Stack>
          </Grid>
        </Grid>

        {/* Related Mods Section - Full Width */}
        <Box sx={{ mt: 6 }}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 4,
              borderRadius: 4,
              border: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Typography variant="h5" gutterBottom color="primary" sx={{ mb: 3 }}>
              Related Mods
            </Typography>
            <RelatedMods modId={mod.id} tags={mod.tags} category={mod.category} />
          </Paper>
        </Box>
      </Container>

      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3 
        }}>
          <Button
            component={RouterLink}
            to="/mods"
            startIcon={<ArrowBack />}
            sx={{ 
              color: 'text.secondary',
              '&:hover': {
                color: 'primary.main',
                bgcolor: 'action.hover'
              }
            }}
          >
            Back to Mods
          </Button>

          
        </Box>
      </Container>

      {/* Report Modal */}
      {reportModalOpen && (
        <ReportModal 
          open={reportModalOpen} 
          onClose={() => setReportModalOpen(false)}
          itemId={mod?.id}
          itemTitle={mod?.title}
        />
      )}
    </Box>
  );
};

export default ModDetails;
