import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Box,
  Chip,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  collection,
  query,
  orderBy,
  getDocs,
  doc,
  updateDoc,
  where
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import {
  Visibility as ViewIcon,
  Done as DoneIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';

const TICKET_STATUS = {
  open: { label: 'Open', color: 'error' },
  'in-progress': { label: 'In Progress', color: 'warning' },
  closed: { label: 'Closed', color: 'success' }
};

export default function SupportTickets() {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [response, setResponse] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [filter, setFilter] = useState('all');

  const fetchTickets = async () => {
    setLoading(true);
    try {
      let ticketsQuery = query(
        collection(db, 'support_tickets'),
        orderBy('createdAt', 'desc')
      );

      if (filter !== 'all') {
        ticketsQuery = query(
          collection(db, 'support_tickets'),
          where('status', '==', filter),
          orderBy('createdAt', 'desc')
        );
      }

      const snapshot = await getDocs(ticketsQuery);
      const ticketData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));
      setTickets(ticketData);
    } catch (err) {
      console.error('Error fetching tickets:', err);
      setError('Failed to load support tickets');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, [filter]);

  const handleViewTicket = (ticket) => {
    setSelectedTicket(ticket);
    setOpenDialog(true);
  };

  const handleUpdateStatus = async (newStatus) => {
    if (!selectedTicket) return;
    
    setUpdating(true);
    try {
      const ticketRef = doc(db, 'support_tickets', selectedTicket.id);
      await updateDoc(ticketRef, {
        status: newStatus,
        adminResponse: response,
        updatedAt: new Date()
      });
      
      setOpenDialog(false);
      fetchTickets();
    } catch (err) {
      console.error('Error updating ticket:', err);
      setError('Failed to update ticket');
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5" component="h1">
            Support Tickets
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              select
              size="small"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              sx={{ minWidth: 150 }}
            >
              <MenuItem value="all">All Tickets</MenuItem>
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="in-progress">In Progress</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
            </TextField>
            
            <Button
              startIcon={<RefreshIcon />}
              onClick={fetchTickets}
              variant="outlined"
            >
              Refresh
            </Button>
          </Box>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.map((ticket) => (
                <TableRow key={ticket.id}>
                  <TableCell>
                    {ticket.createdAt?.toLocaleDateString()}
                  </TableCell>
                  <TableCell>{ticket.userEmail}</TableCell>
                  <TableCell>{ticket.reason}</TableCell>
                  <TableCell>{ticket.subject}</TableCell>
                  <TableCell>
                    <Chip
                      label={TICKET_STATUS[ticket.status].label}
                      color={TICKET_STATUS[ticket.status].color}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleViewTicket(ticket)}
                      size="small"
                    >
                      <ViewIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {tickets.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No tickets found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedTicket && (
          <>
            <DialogTitle>
              Support Ticket Details
            </DialogTitle>
            <DialogContent>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  From: {selectedTicket.userEmail}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  Date: {selectedTicket.createdAt?.toLocaleString()}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  Status: {TICKET_STATUS[selectedTicket.status].label}
                </Typography>
              </Box>

              <Typography variant="h6" gutterBottom>
                {selectedTicket.subject}
              </Typography>

              <Typography variant="body1" paragraph>
                {selectedTicket.message}
              </Typography>

              <TextField
                fullWidth
                label="Admin Response"
                multiline
                rows={4}
                value={response}
                onChange={(e) => setResponse(e.target.value)}
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>
                Close
              </Button>
              {selectedTicket.status !== 'closed' && (
                <>
                  {selectedTicket.status === 'open' && (
                    <Button
                      onClick={() => handleUpdateStatus('in-progress')}
                      color="warning"
                      disabled={updating}
                    >
                      Mark In Progress
                    </Button>
                  )}
                  <Button
                    onClick={() => handleUpdateStatus('closed')}
                    color="success"
                    startIcon={<DoneIcon />}
                    disabled={updating}
                  >
                    Close Ticket
                  </Button>
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
  );
}
