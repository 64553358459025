import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Chip,
  Button,
  Rating,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { alpha } from '@mui/material/styles';

export default function ModCard({ mod }) {
  const navigate = useNavigate();

  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 2,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: (theme) => `0 12px 24px ${alpha(theme.palette.primary.main, 0.2)}`,
          '& .mod-image': {
            transform: 'scale(1.1)',
          },
          '& .mod-overlay': {
            opacity: 1,
          },
          '& .mod-actions': {
            transform: 'translateY(0)',
          },
        },
      }}
    >
      {/* Premium Badge */}
      {mod.isPremium && (
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            zIndex: 2,
            background: 'linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)',
            color: 'white',
            py: 0.5,
            px: 2,
            borderRadius: '50px',
            fontWeight: 'bold',
            fontSize: '0.75rem',
            boxShadow: '0 2px 8px rgba(0,0,0,0.25)',
          }}
        >
          PREMIUM
        </Box>
      )}

      {/* Image Container */}
      <Box sx={{ position: 'relative', paddingTop: '56.25%', overflow: 'hidden' }}>
        <CardMedia
          component="img"
          loading="lazy"
          image={
            mod.screenshots && mod.screenshots.length > 0 
              ? mod.screenshots[0] 
              : (mod.thumbnailUrl || '/placeholder-mod.jpg')
          }
          alt={mod.title}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease-in-out',
          }}
          className="mod-image"
        />
        
        {/* Overlay */}
        <Box
          className="mod-overlay"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%)',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
          }}
        />

        {/* Quick Actions */}
        <Box
          className="mod-actions"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: 2,
            display: 'flex',
            justifyContent: 'center',
            transform: 'translateY(100%)',
            transition: 'transform 0.3s ease-in-out',
            gap: 1,
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate(`/mod/${encodeURIComponent(mod.id)}`)}
            sx={{
              backgroundColor: 'white',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: 'white',
                transform: 'scale(1.05)',
              },
            }}
          >
            View Details
          </Button>
          <Tooltip title="Add to Favorites">
            <IconButton
              size="small"
              sx={{
                backgroundColor: 'rgba(255,255,255,0.1)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.2)',
                },
              }}
            >
              <FavoriteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <CardContent sx={{ flexGrow: 1, p: 2 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom noWrap>
            {mod.title}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Rating value={mod.rating || 0} readOnly size="small" sx={{ mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              ({mod.rating ? mod.rating.toFixed(1) : '0.0'})
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <DownloadIcon fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              {mod.downloads ? mod.downloads.toLocaleString() : '0'} Downloads
            </Typography>
          </Box>
          {mod.category && (
            <Chip
              label={mod.category}
              size="small"
              sx={{
                background: (theme) => alpha(theme.palette.primary.main, 0.1),
                color: 'primary.main',
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
