import { doc, updateDoc, setDoc, getDoc, collection, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { verifyPayPalSubscription, getSubscriptionDetails, cancelSubscription } from './paypalService';

// Verify and process new subscription
export async function verifyAndProcessSubscription(userId, subscriptionData) {
    try {
        // Verify subscription with PayPal
        const paypalSubscription = await verifyPayPalSubscription(subscriptionData.subscriptionID);
        
        if (paypalSubscription.status !== 'ACTIVE') {
            throw new Error('PayPal subscription is not active');
        }

        // Get detailed subscription info
        const subscriptionDetails = await getSubscriptionDetails(subscriptionData.subscriptionID);
        
        // Calculate expiration date
        const startDate = new Date(subscriptionDetails.start_time);
        const expirationDate = new Date(startDate);
        expirationDate.setMonth(expirationDate.getMonth() + 1);

        // Prepare subscription data
        const subscriptionInfo = {
            id: subscriptionData.subscriptionID,
            status: 'ACTIVE',
            type: 'premium',
            startDate: startDate.toISOString(),
            expiresAt: expirationDate.toISOString(),
            paypalSubscriptionId: subscriptionData.subscriptionID,
            planId: subscriptionDetails.plan_id,
            environment: process.env.NODE_ENV === 'development' ? 'sandbox' : 'live',
            updatedAt: new Date().toISOString()
        };

        // Update user document
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
            subscription: subscriptionInfo,
            roles: {
                premium: true
            }
        });

        // Store subscription details in subscriptions collection
        await setDoc(doc(db, 'subscriptions', subscriptionData.subscriptionID), {
            userId,
            ...subscriptionInfo,
            createdAt: new Date().toISOString(),
            lastVerifiedAt: new Date().toISOString()
        });

        return subscriptionInfo;
    } catch (error) {
        console.error('Error processing subscription:', error);
        // If there's an error, clean up any partial database updates
        try {
            const userRef = doc(db, 'users', userId);
            await updateDoc(userRef, {
                subscription: null,
                roles: {
                    premium: false
                }
            });
            // Try to delete subscription document if it was created
            if (subscriptionData?.subscriptionID) {
                await deleteDoc(doc(db, 'subscriptions', subscriptionData.subscriptionID));
            }
        } catch (cleanupError) {
            console.error('Error cleaning up failed subscription:', cleanupError);
        }
        throw new Error('Failed to process subscription');
    }
}

// Handle subscription cancellation or expiration
export async function handleSubscriptionEnd(userId, subscriptionId) {
    try {
        // Cancel subscription with PayPal
        await cancelSubscription(subscriptionId);
        
        // Update user document
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
            subscription: null,
            roles: {
                premium: false
            }
        });

        // Update subscription document
        const subscriptionRef = doc(db, 'subscriptions', subscriptionId);
        await updateDoc(subscriptionRef, {
            status: 'CANCELLED',
            cancelledAt: new Date().toISOString()
        });

        return true;
    } catch (error) {
        console.error('Error handling subscription end:', error);
        throw new Error('Failed to process subscription cancellation');
    }
}

// Verify subscription status
export async function verifySubscriptionStatus(userId) {
    try {
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        
        if (!userDoc.exists()) {
            return false;
        }

        const userData = userDoc.data();
        const subscription = userData.subscription;

        if (!subscription || !subscription.paypalSubscriptionId) {
            return false;
        }

        // Check if subscription is expired
        const expiresAt = new Date(subscription.expiresAt);
        if (expiresAt < new Date()) {
            // Subscription has expired, clean up
            await handleSubscriptionEnd(userId, subscription.paypalSubscriptionId);
            return false;
        }

        // Verify with PayPal
        const paypalStatus = await verifyPayPalSubscription(subscription.paypalSubscriptionId);
        if (paypalStatus.status !== 'ACTIVE') {
            // Subscription is no longer active in PayPal, clean up
            await handleSubscriptionEnd(userId, subscription.paypalSubscriptionId);
            return false;
        }

        return true;
    } catch (error) {
        console.error('Error verifying subscription status:', error);
        return false;
    }
}
