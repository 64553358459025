import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBMUdiN98nMrErteKo6rx2kFhA7dKgrn-s",
  authDomain: "acdriftingpro.com",
  projectId: "acdp-website-bff13",
  storageBucket: "acdp-website-bff13.firebasestorage.app",
  messagingSenderId: "264486699166",
  appId: "1:264486699166:web:484bd776ee091ccdbcbb8f",
  measurementId: "G-RPBM8S28ZZ"
};

let app, auth, db, storage, analytics;

try {
  // Initialize Firebase
  app = initializeApp(firebaseConfig);

  // Initialize services with error handling
  auth = getAuth(app);
  db = getFirestore(app);
  storage = getStorage(app);
  
  // Only initialize analytics if supported
  isSupported().then(yes => yes && (analytics = getAnalytics(app)))
    .catch(err => console.warn('Analytics not supported:', err));

  // Add connection state logging
  auth.onAuthStateChanged((user) => {
    if (user) {
      console.log('User is signed in');
    } else {
      console.log('No user is signed in');
    }
  });

} catch (error) {
  console.error('Error initializing Firebase:', error);
  // Provide fallback or show error UI
  throw new Error('Failed to initialize Firebase. Please check your internet connection and try again.');
}

export { auth, db, storage, analytics };
export default app;