import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Alert,
  Tooltip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress
} from '@mui/material';
import {
  GetApp,
  Warning,
  CheckCircle,
  Games
} from '@mui/icons-material';
import { modManagementService } from '../../services/modManagementService';
import { useAuth } from '../../contexts/AuthContext';
import { trackDownload } from '../../services/downloadService';
import DownloadTimerModal from './DownloadTimerModal';
import { useNavigate } from 'react-router-dom';

function DependencyCheck({ dependencies, onInstall }) {
  return (
    <List>
      {dependencies.map(dep => (
        <ListItem key={dep.id}>
          <ListItemIcon>
            {dep.installed ? (
              <CheckCircle color="success" />
            ) : (
              <Warning color="warning" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={dep.name}
            secondary={`Version ${dep.version}`}
          />
          {!dep.installed && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => onInstall(dep)}
            >
              Install
            </Button>
          )}
        </ListItem>
      ))}
    </List>
  );
}
  

export default function ModDownload({ mod }) {
  const [dependencies, setDependencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const [countdown, setCountdown] = useState(0);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showDownloadTimer, setShowDownloadTimer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadDependencies();
  }, [mod.id]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0 && showDownloadTimer) {
      // Start the download when countdown reaches 0
      window.location.href = mod.fileUrl;
      setShowDownloadTimer(false);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown, showDownloadTimer, mod.fileUrl]);

  const loadDependencies = async () => {
    try {
      const deps = await modManagementService.getModDependencies(mod.id);
      setDependencies(deps);
    } catch (err) {
      setError('Failed to load dependencies');
    }
  };

  const handleDownload = async () => {
    // Determine wait time based on user status
    let waitTime = 60; // Default for non-logged in users
    
    // Check if user is logged in and has subscription
    const isPremium = currentUser && (
      (typeof currentUser.subscription === 'string' && currentUser.subscription === 'premium') ||
      (typeof currentUser.subscription === 'object' && currentUser.subscription?.type === 'premium')
    );

    // Premium users get immediate download
    if (isPremium) {
      // Directly trigger download for premium users
      const link = document.createElement('a');
      link.href = mod.fileUrl;
      link.download = mod.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }
    
    // Logged-in non-premium users wait 30 seconds
    if (currentUser) {
      waitTime = 30;
    }

    setLoading(true);
    setError(null);

    try {
      setCountdown(waitTime); // Set countdown based on user status
      setShowDownloadTimer(true);
      return; // Just start the countdown
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleContentManagerInstall = () => {
    if (!currentUser) {
      setShowLoginDialog(true);
      return;
    }

    // More robust premium check
    const isPremium = currentUser && (
      (typeof currentUser.subscription === 'string' && currentUser.subscription === 'premium') ||
      (typeof currentUser.subscription === 'object' && currentUser.subscription?.type === 'premium')
    );

    if (!isPremium) {
      // Redirect non-premium users to premium page
      navigate('/premium');
      return;
    }

    // Premium users can use Content Manager install
    const cmUrl = modManagementService.generateContentManagerUrl(mod.fileUrl, mod.name);
    window.location.href = cmUrl;
  };

  const missingDependencies = dependencies.filter(dep => !dep.installed);

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          onClick={handleDownload}
          disabled={loading || countdown > 0 || missingDependencies.length > 0}
          fullWidth
          sx={{
            py: 2,
            bgcolor: theme => theme.palette.primary.main,
            color: 'white',
            borderRadius: 2,
            fontSize: '1rem',
            fontWeight: 500,
            '&:hover': {
              bgcolor: theme => theme.palette.primary.dark,
              transform: 'translateY(-2px)',
              transition: 'all 0.2s ease-in-out'
            },
            mb: 2
          }}
        >
          {loading ? 'Processing...' : countdown > 0 ? `Wait ${countdown}s` : 'Download Mod'}
        </Button>

        <Tooltip title="Install directly with Content Manager" placement="top" arrow>
          <Button
            variant="outlined"
            onClick={handleContentManagerInstall}
            disabled={loading || missingDependencies.length > 0}
            fullWidth
            sx={{
              py: 2,
              borderColor: theme => theme.palette.primary.main,
              color: theme => theme.palette.primary.main,
              borderWidth: 2,
              borderRadius: 2,
              fontSize: '1rem',
              fontWeight: 500,
              '&:hover': {
                borderColor: 'white',
                bgcolor: 'rgba(255,255,255,0.08)',
                transform: 'translateY(-2px)',
                transition: 'all 0.2s ease-in-out'
              },
              '&:active': {
                transform: 'translateY(1px)'
              },
              '&.Mui-disabled': {
                borderColor: 'rgba(255,255,255,0.12)',
                color: 'rgba(255,255,255,0.3)'
              }
            }}
          >
            Install With Content Manager
          </Button>
        </Tooltip>
      </Box>

      {missingDependencies.length > 0 && (
        <Alert 
          severity="warning" 
          sx={{ 
            mb: 3,
            bgcolor: 'rgba(237, 108, 2, 0.15)',
            '& .MuiAlert-icon': {
              color: 'warning.light'
            }
          }}
        >
          This mod requires additional dependencies
        </Alert>
      )}

      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          color: 'inherit',
          opacity: 0.9
        }}
      >
        <Typography variant="subtitle2" sx={{ color: 'inherit' }}>
          File Size: {(mod.fileSize / 1024 / 1024).toFixed(2)} MB
        </Typography>

        <Typography variant="subtitle2" sx={{ color: 'inherit' }}>
          Downloads: {mod.downloadCount}
        </Typography>
      </Box>

      {dependencies.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography 
            variant="subtitle1" 
            gutterBottom 
            sx={{ 
              color: 'inherit',
              fontWeight: 500,
              opacity: 0.95,
              mb: 2
            }}
          >
            Dependencies
          </Typography>
          <DependencyCheck
            dependencies={dependencies}
            onInstall={(dep) => window.location.href = `/mod/${dep.id}`}
          />
        </Box>
      )}

      {countdown > 0 && (
        <DownloadTimerModal
          open={true}
          countdown={countdown}
          totalWaitTime={currentUser ? 30 : 60}
          onClose={() => {
            setShowDownloadTimer(false);
            setCountdown(0);
            window.location.href = mod.fileUrl;
          }}
          onCancel={() => {
            setShowDownloadTimer(false);
            setCountdown(0);
          }}
          mod={mod}
        />
      )}
    </Box>
  );
}
