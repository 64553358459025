import React from 'react';
import {
  Paper,
  Typography,
  Button,
  Box,
  Chip,
  Divider
} from '@mui/material';
import { Star as StarIcon, Launch as LaunchIcon } from '@mui/icons-material';

const PAYPAL_SUBSCRIPTION_URL = 'https://www.paypal.com/myaccount/autopay';

export default function SubscriptionCard({ currentUser, onUpgrade }) {
  // Check if user has an active premium subscription
  const isPremium = currentUser?.subscription?.type === 'premium';
  
  // Get days remaining with safe fallback
  const daysRemaining = (() => {
    try {
      const expiresAt = currentUser?.subscription?.expiresAt;
      
      // If no expiration date, return 0
      if (!expiresAt) {
        return 0;
      }

      // Calculate days remaining
      const today = new Date();
      const diffTime = expiresAt - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 0 ? diffDays : 0;
    } catch (error) {
      console.error('Error calculating days remaining:', error);
      return 0;
    }
  })();

  console.log('Current user in SubscriptionCard:', currentUser);
  console.log('Subscription data:', currentUser?.subscription);
  console.log('Days remaining:', daysRemaining);

  return (
    <Paper 
      sx={{ 
        p: 3,
        minHeight: 300,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%'
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 1,
        flexWrap: 'wrap',
        gap: 1
      }}>
        <Typography variant="h6" component="div" sx={{ minWidth: 160 }}>
          Subscription Status
        </Typography>
        {isPremium && (
          <Chip
            icon={<StarIcon />}
            label="Premium"
            color="primary"
            variant="outlined"
          />
        )}
      </Box>

      {isPremium ? (
        <>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" gutterBottom>
              Premium Features Active
            </Typography>
            {currentUser?.subscription?.expiresAt && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  {daysRemaining} days remaining
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Expires: {currentUser.subscription.expiresAt.toLocaleDateString()}
                </Typography>
              </Box>
            )}
            <Button
              variant="outlined"
              color="primary"
              href={PAYPAL_SUBSCRIPTION_URL}
              target="_blank"
              sx={{ mt: 2 }}
            >
              Manage Subscription
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="body1" gutterBottom>
            Free Account
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Upgrade to Premium to unlock all features
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={onUpgrade}
            sx={{ mt: 'auto' }}
          >
            Upgrade to Premium
          </Button>
        </>
      )}
    </Paper>
  );
}
