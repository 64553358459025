import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  useTheme,
} from '@mui/material';
import {
  CloudUpload,
  GetApp,
  Star,
  Favorite,
  Category,
} from '@mui/icons-material';

export default function UserStats({ stats }) {
  const theme = useTheme();

  const statItems = [
    {
      icon: <CloudUpload sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      value: stats.uploadCount || 0,
      label: 'Uploads',
    },
    {
      icon: <GetApp sx={{ fontSize: 40, color: theme.palette.secondary.main }} />,
      value: stats.downloadCount || 0,
      label: 'Downloads',
    },
    {
      icon: <Star sx={{ fontSize: 40, color: theme.palette.warning.main }} />,
      value: stats.averageRating?.toFixed(1) || '0.0',
      label: 'Avg Rating',
    },
    {
      icon: <Favorite sx={{ fontSize: 40, color: theme.palette.error.main }} />,
      value: stats.favoriteCount || 0,
      label: 'Favorites',
    },
  ];

  // Add top categories if available
  if (stats.topCategories && stats.topCategories.length > 0) {
    statItems.push({
      icon: <Category sx={{ fontSize: 40, color: theme.palette.info.main }} />,
      value: stats.topCategories[0].category || 'N/A',
      label: 'Top Category',
    });
  }

  return (
    <Grid container spacing={2}>
      {statItems.map((item, index) => (
        <Grid item xs={6} sm={3} key={index}>
          <Paper
            sx={{
              p: 2,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
              },
            }}
          >
            <Box sx={{ mb: 1 }}>
              {item.icon}
            </Box>
            <Typography variant="h4" component="div" gutterBottom>
              {item.value}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.label}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
