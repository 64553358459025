import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box } from '@mui/material';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // Test key

export default function ReCaptcha({ onChange }) {
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center',
      my: 2 
    }}>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={onChange}
      />
    </Box>
  );
}
