import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

export default function ImageUploader({ onImagesSelect, selectedImages, onImageRemove, maxImages = 10 }) {
  const onDrop = useCallback((acceptedFiles) => {
    const remainingSlots = maxImages - (selectedImages?.length || 0);
    if (remainingSlots > 0) {
      // Convert files to array with preview URLs
      const newImages = acceptedFiles.slice(0, remainingSlots).map(file => 
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      onImagesSelect(newImages);
    }
  }, [maxImages, selectedImages, onImagesSelect]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.webp']
    },
    multiple: true,
    maxFiles: maxImages
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        {...getRootProps()}
        sx={{
          p: 3,
          border: '2px dashed',
          borderColor: isDragActive ? 'primary.main' : 'grey.500',
          bgcolor: isDragActive ? 'action.hover' : 'background.paper',
          cursor: 'pointer',
          transition: 'all 0.2s ease',
          '&:hover': {
            borderColor: 'primary.main',
            bgcolor: 'action.hover'
          }
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 100
          }}
        >
          {isDragActive ? (
            <Typography variant="h6" color="primary">
              Drop the images here...
            </Typography>
          ) : (
            <Typography variant="h6" color="textSecondary">
              Drag and drop screenshots here, or click to select
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Supported formats: JPG, PNG, GIF, WebP (Max {maxImages} images)
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {selectedImages?.length || 0} of {maxImages} images selected
          </Typography>
        </Box>
      </Paper>

      {selectedImages?.length > 0 && (
        <ImageList sx={{ mt: 2 }} cols={5} rowHeight={160}>
          {selectedImages.map((image, index) => (
            <ImageListItem key={image.preview}>
              <img
                src={image.preview}
                alt={`Preview ${index + 1}`}
                loading="lazy"
                style={{ height: '160px', objectFit: 'cover' }}
              />
              <ImageListItemBar
                position="top"
                actionIcon={
                  <IconButton
                    sx={{ color: 'white' }}
                    onClick={() => onImageRemove(image)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
                actionPosition="right"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Box>
  );
}
