import { 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc, 
  doc,
  serverTimestamp,
  runTransaction
} from 'firebase/firestore';
import { db } from '../firebase/config';

export const userModService = {
  async getUserUploads(userId) {
    if (!userId) {
      throw new Error('User ID is required');
    }

    try {
      // Query mods uploaded by the user
      const modsQuery = query(
        collection(db, 'mods'),
        where('authorId', '==', userId)
      );

      const querySnapshot = await getDocs(modsQuery);
      
      // Return an empty array if no mods found, instead of throwing an error
      if (querySnapshot.empty) {
        return [];
      }

      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching user uploads:', error);
      return []; // Return empty array on error
    }
  },

  async updateUserModStats(userId, modId, updateData) {
    if (!userId || !modId) {
      throw new Error('User ID and Mod ID are required');
    }

    try {
      const modRef = doc(db, 'mods', modId);
      const userRef = doc(db, 'users', userId);

      await runTransaction(db, async (transaction) => {
        // Update mod document
        transaction.update(modRef, {
          ...updateData,
          lastUpdated: serverTimestamp()
        });

        // Optionally update user's mod-related stats
        const userDoc = await transaction.get(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const updatedStats = {
            totalUploads: (userData.totalUploads || 0) + (updateData.newUpload ? 1 : 0),
            totalDownloads: (userData.totalDownloads || 0) + (updateData.downloads || 0)
          };

          transaction.update(userRef, updatedStats);
        }
      });

      return true;
    } catch (error) {
      console.error('Error updating mod stats:', error);
      throw error;
    }
  },

  async getUploadStats(userId) {
    if (!userId) {
      throw new Error('User ID is required');
    }

    try {
      const uploads = await this.getUserUploads(userId);

      const stats = {
        totalUploads: uploads.length,
        totalDownloads: uploads.reduce((sum, mod) => sum + (mod.downloads || 0), 0),
        averageRating: uploads.reduce((sum, mod) => sum + (mod.rating || 0), 0) / (uploads.length || 1),
        categories: {},
        recentUpload: uploads.length > 0 
          ? uploads.reduce((latest, mod) => 
              (!latest || (mod.uploadedAt > latest.uploadedAt) ? mod : latest)
            ) 
          : null
      };

      // Count mods by category
      uploads.forEach(mod => {
        if (mod.category) {
          stats.categories[mod.category] = 
            (stats.categories[mod.category] || 0) + 1;
        }
      });

      return stats;
    } catch (error) {
      console.error('Error getting upload stats:', error);
      throw error;
    }
  }
};
