import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  auth,
  db
} from '../firebase/config';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function signup(email, password, username) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Create user profile in Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        username,
        email,
        createdAt: Timestamp.now(),
        subscription: {
          type: 'free',
          expiresAt: null,
          createdAt: Timestamp.now()
        },
        uploadedMods: [],
        onboarding: {
          welcomeTourCompleted: false,
          profileCompleted: false,
          modRecommendationsCompleted: false,
          lastStep: 'welcome'
        }
      });
      return userCredential;
    } catch (error) {
      throw error;
    }
  }

  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // Check if user profile exists, if not create one
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', result.user.uid), {
          username: result.user.displayName,
          email: result.user.email,
          createdAt: Timestamp.now(),
          subscription: {
            type: 'free',
            expiresAt: null,
            createdAt: Timestamp.now()
          },
          uploadedMods: [],
          onboarding: {
            welcomeTourCompleted: false,
            profileCompleted: false,
            modRecommendationsCompleted: false,
            lastStep: 'welcome'
          }
        });
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Force token refresh to get latest custom claims
          await user.getIdToken(true);
          const idTokenResult = await user.getIdTokenResult();
          
          // Get additional user data from Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          
          // Handle subscription data with fallbacks
          const subscription = userData?.subscription || { 
            type: 'free', 
            expiresAt: null, 
            createdAt: null 
          };

          // Convert Firestore Timestamps to JavaScript Dates
          const processedSubscription = {
            ...subscription,
            expiresAt: subscription.expiresAt?.toDate?.() || null,
            createdAt: subscription.createdAt?.toDate?.() || null
          };
          
          setCurrentUser({
            ...user,
            ...userData,
            subscription: processedSubscription,
            admin: idTokenResult.claims.admin === true
          });
        } catch (error) {
          console.error('Error loading user data:', error);
          // Set basic user data if Firestore fetch fails
          setCurrentUser({
            ...user,
            subscription: {
              type: 'free',
              expiresAt: null,
              createdAt: null
            },
            admin: false
          });
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    loginWithGoogle,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
