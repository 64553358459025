export const PAYPAL_CONFIG = {
  CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  PREMIUM_PLAN_ID: process.env.REACT_APP_PAYPAL_PREMIUM_PLAN_ID,
  SCRIPT_OPTIONS: {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    intent: "subscription",
    vault: true
  },
  BUTTON_STYLES: {
    layout: 'vertical',
    color: 'blue',
    shape: 'rect',
    label: 'subscribe'
  }
};
