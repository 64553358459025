import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import { Add as AddIcon, Book as BookIcon } from '@mui/icons-material';

export default function Guides() {
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    fetchGuides();
  }, []);

  const fetchGuides = async () => {
    try {
      const guidesQuery = query(collection(db, 'guides'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(guidesQuery);
      const guidesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setGuides(guidesData);
    } catch (error) {
      console.error('Error fetching guides:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Hero Section */}
      <Box
        sx={{
          background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
          borderRadius: 4,
          p: 6,
          mb: 8,
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}22, ${theme.palette.secondary.main}22)`,
            zIndex: 1
          }
        }}
      >
        <Grid container spacing={4} alignItems="center" sx={{ position: 'relative', zIndex: 2 }}>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Guides & Tutorials
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                color: theme.palette.text.secondary,
                mb: 4,
                maxWidth: '80%'
              }}
            >
              Master the art of drifting with our comprehensive guides and tutorials
            </Typography>
            {currentUser?.role === 'admin' && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate('/guides/create')}
                sx={{
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  px: 4,
                  py: 1.5,
                  '&:hover': {
                    background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
                  }
                }}
              >
                Create New Guide
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                position: 'relative'
              }}
            >
              <BookIcon 
                sx={{ 
                  fontSize: 200, 
                  color: theme.palette.primary.main, 
                  opacity: 0.8,
                  filter: 'drop-shadow(0 4px 20px rgba(0,0,0,0.2))'
                }} 
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Guides Grid */}
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{
          fontWeight: 700,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          mb: 6
        }}
      >
        Latest Guides
      </Typography>

      <Grid container spacing={4}>
        {guides.map((guide) => (
          <Grid item xs={12} sm={6} md={4} key={guide.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease-in-out',
                background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
                borderRadius: 3,
                overflow: 'hidden',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 12px 30px rgba(0,0,0,0.2)',
                }
              }}
            >
              {guide.coverImage ? (
                <CardMedia
                  component="img"
                  height="200"
                  image={guide.coverImage}
                  alt={guide.title}
                  sx={{
                    objectFit: 'cover',
                    borderBottom: `2px solid ${theme.palette.primary.main}`
                  }}
                />
              ) : (
                <Box
                  sx={{
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}22, ${theme.palette.secondary.main}22)`,
                    borderBottom: `2px solid ${theme.palette.primary.main}`
                  }}
                >
                  <BookIcon sx={{ fontSize: 80, color: theme.palette.primary.main, opacity: 0.5 }} />
                </Box>
              )}
              <CardContent sx={{ flexGrow: 1, p: 3 }}>
                <Typography 
                  gutterBottom 
                  variant="h5" 
                  component="h2"
                  sx={{
                    fontWeight: 600,
                    mb: 2,
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  {guide.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: theme.palette.text.secondary,
                    mb: 2
                  }}
                >
                  {guide.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ p: 3, pt: 0 }}>
                <Button 
                  size="large" 
                  onClick={() => navigate(`/guides/${guide.id}`)}
                  sx={{
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    color: 'white',
                    px: 3,
                    '&:hover': {
                      background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
                    }
                  }}
                >
                  Read Guide
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
        {guides.length === 0 && (
          <Grid item xs={12}>
            <Paper 
              sx={{ 
                p: 6, 
                textAlign: 'center',
                background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
                borderRadius: 4,
              }}
            >
              <Typography variant="h5" sx={{ color: theme.palette.text.secondary, mb: 3 }}>
                No guides available yet
              </Typography>
              {currentUser?.role === 'admin' && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/guides/create')}
                  sx={{
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    px: 4,
                    py: 1.5,
                    '&:hover': {
                      background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
                    }
                  }}
                >
                  Create First Guide
                </Button>
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
