import React from 'react';
import { 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Chip,
  Box
} from '@mui/material';
import { format } from 'date-fns';

const getStatusColor = (status) => {
  switch(status) {
    case 'pending': return 'warning';
    case 'resolved': return 'success';
    case 'rejected': return 'error';
    default: return 'default';
  }
};

export default function UserReports({ reports }) {
  if (!reports || reports.length === 0) {
    return (
      <Paper sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          No reports submitted
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography variant="h6" sx={{ p: 2 }}>
        My Reports
      </Typography>
      <TableContainer>
        <Table stickyHeader aria-label="user reports table">
          <TableHead>
            <TableRow>
              <TableCell>Item Type</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow key={report.id} hover>
                <TableCell>{report.itemType}</TableCell>
                <TableCell>{report.reason}</TableCell>
                <TableCell>
                  <Chip 
                    label={report.status} 
                    color={getStatusColor(report.status)} 
                    size="small" 
                  />
                </TableCell>
                <TableCell>
                  {format(report.createdAt, 'MMM dd, yyyy')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
