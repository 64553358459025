import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useOnboarding } from '../contexts/OnboardingContext';
import WelcomeTour from '../components/onboarding/WelcomeTour';
import ProfileWizard from '../components/onboarding/ProfileWizard';
import ModRecommendations from '../components/onboarding/ModRecommendations';

function OnboardingWrapper({ children }) {
  const { currentUser } = useAuth();
  const { isComplete, loading } = useOnboarding();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (!currentUser) {
        navigate('/login');
      } else if (isComplete) {
        navigate('/');
      }
    }
  }, [currentUser, isComplete, loading, navigate]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return children;
}

export default function OnboardingFlow() {
  const { currentStep } = useOnboarding();

  return (
    <OnboardingWrapper>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/onboarding/${currentStep}`} replace />}
        />
        <Route
          path="/welcome"
          element={
            <>
              <WelcomeTour />
              <Navigate to="/onboarding/profile" replace />
            </>
          }
        />
        <Route path="/profile" element={<ProfileWizard />} />
        <Route path="/recommendations" element={<ModRecommendations />} />
        <Route path="*" element={<Navigate to="/onboarding" replace />} />
      </Routes>
    </OnboardingWrapper>
  );
}
