import React from 'react';
import { Container, Typography, Box, useTheme, Paper } from '@mui/material';

export default function PrivacyPolicy() {
  const theme = useTheme();

  const sectionStyle = {
    mb: 6,
    p: 4,
    borderRadius: 2,
    background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography
        variant="h2"
        sx={{
          fontWeight: 700,
          mb: 4,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}
      >
        Privacy Policy
      </Typography>

      <Typography paragraph sx={{ mb: 6, color: theme.palette.text.secondary }}>
        Last updated: December 9, 2024
      </Typography>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          1. Introduction
        </Typography>
        <Typography paragraph>
          AC Drifting Pro ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services.
        </Typography>
        <Typography paragraph>
          Please read this Privacy Policy carefully. By accessing or using our Services, you acknowledge that you have read and understood this Privacy Policy.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          2. Information We Collect
        </Typography>
        <Typography paragraph>
          2.1. Information You Provide:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Account Information: Name, email address, username, password
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Profile Information: Avatar, bio, social media links
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Payment Information: Credit card details, billing address
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Communication: Messages, feedback, support requests
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          2.2. Information Automatically Collected:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Device Information: IP address, browser type, operating system
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Usage Data: Pages visited, time spent, actions taken
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Cookies and Similar Technologies: Session data, preferences
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          3. How We Use Your Information
        </Typography>
        <Typography paragraph>
          We use your information for the following purposes:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Provide and maintain our Services
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Process payments and manage subscriptions
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Send administrative information and updates
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Respond to your comments and support requests
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Prevent fraud and abuse of our Services
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Improve and optimize our Services
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          4. Information Sharing and Disclosure
        </Typography>
        <Typography paragraph>
          4.1. We may share your information with:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Service Providers: Companies that help us operate our Services (e.g., payment processors, hosting providers)
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Legal Requirements: When required by law or to protect our rights
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Business Transfers: In connection with a merger, acquisition, or sale of assets
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          4.2. We will never sell your personal information to third parties for marketing purposes.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          5. Data Security
        </Typography>
        <Typography paragraph>
          5.1. Security Measures:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Encryption of sensitive data in transit and at rest
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Regular security assessments and monitoring
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Access controls and authentication requirements
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          5.2. While we implement reasonable security measures, no method of transmission over the internet is 100% secure. We cannot guarantee absolute security.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          6. Cookies and Tracking Technologies
        </Typography>
        <Typography paragraph>
          6.1. We use cookies and similar technologies to:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Remember your preferences and settings
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Understand how you use our Services
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Improve our Services and user experience
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          6.2. You can control cookie preferences through your browser settings. However, disabling cookies may limit some features of our Services.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          7. Your Rights and Choices
        </Typography>
        <Typography paragraph>
          You have the right to:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Access your personal information
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Correct inaccurate or incomplete information
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Request deletion of your information
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Object to certain processing activities
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Withdraw consent where applicable
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          8. Children's Privacy
        </Typography>
        <Typography paragraph>
          Our Services are not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          9. International Data Transfers
        </Typography>
        <Typography paragraph>
          Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place to protect your information during such transfers.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          10. Changes to Privacy Policy
        </Typography>
        <Typography paragraph>
          We may update this Privacy Policy from time to time. We will notify you of any material changes by:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Posting the updated policy on our website
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Sending an email to registered users
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          11. Contact Us
        </Typography>
        <Typography paragraph>
          If you have questions about this Privacy Policy or our privacy practices, please contact us at:
        </Typography>
        <Typography paragraph>
          Email: privacy@acdriftingpro.com
        </Typography>
        <Typography paragraph>
          We will respond to your inquiry as soon as possible.
        </Typography>
      </Paper>

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          {new Date().getFullYear()} AC Drifting Pro. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
}
