import React, { useState } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { Box, Alert, CircularProgress } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { verifyAndProcessSubscription } from '../../services/subscriptionService';
import { PAYPAL_CONFIG } from '../../config/paypal';

export default function PayPalSubscribeButton({ onSuccess, onError, onCancel }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubscription = async (subscriptionData) => {
    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    try {
      const subscriptionDetails = await verifyAndProcessSubscription(
        currentUser.uid,
        subscriptionData
      );
      return subscriptionDetails;
    } catch (error) {
      console.error('Subscription processing error:', error);
      throw error;
    }
  };

  const handleCancel = () => {
    console.log('Subscription process was cancelled.');
    onCancel?.();
  };

  if (!PAYPAL_CONFIG.PREMIUM_PLAN_ID) {
    console.error('Premium plan ID is not configured');
    return (
      <Alert severity="error">
        Subscription service is temporarily unavailable. Please try again later.
      </Alert>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto' }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <PayPalButtons
          style={PAYPAL_CONFIG.BUTTON_STYLES}
          createSubscription={(data, actions) => {
            console.log('Creating premium subscription with plan ID:', PAYPAL_CONFIG.PREMIUM_PLAN_ID);
            return actions.subscription.create({
              plan_id: PAYPAL_CONFIG.PREMIUM_PLAN_ID,
              vault: true
            });
          }}
          onApprove={(data, actions) => {
            console.log('Subscription approved:', data);
            setLoading(true);
            handleSubscription(data)
              .then((subscriptionDetails) => {
                console.log('Subscription processed successfully:', subscriptionDetails);
                onSuccess?.(subscriptionDetails);
              })
              .catch((err) => {
                console.error('Subscription error:', err);
                setError('Subscription verification failed. Please contact support.');
                onError?.(err);
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          onCancel={handleCancel}
          onError={(err) => {
            console.error('PayPal Button Error:', err);
            setError('PayPal encountered an error. Please try again.');
            onError?.(err);
          }}
        />
      )}
    </Box>
  );
}
