import React from 'react';
import { Container, Typography, Box, useTheme, Paper } from '@mui/material';

export default function TermsOfService() {
  const theme = useTheme();

  const sectionStyle = {
    mb: 6,
    p: 4,
    borderRadius: 2,
    background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography
        variant="h2"
        sx={{
          fontWeight: 700,
          mb: 4,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}
      >
        Terms of Service
      </Typography>

      <Typography paragraph sx={{ mb: 6, color: theme.palette.text.secondary }}>
        Last updated: December 9, 2024
      </Typography>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          1. Agreement to Terms
        </Typography>
        <Typography paragraph>
          These Terms of Service ("Terms") constitute a legally binding agreement between you and AC Drifting Pro ("we," "us," or "our") governing your access to and use of our website, services, and content (collectively, the "Services").
        </Typography>
        <Typography paragraph>
          By accessing or using our Services, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access the Services.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          2. Services Description
        </Typography>
        <Typography paragraph>
          AC Drifting Pro provides Assetto Corsa drifting mods, content, and related services through our platform. Our Services include:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Access to downloadable drifting mods and content for Assetto Corsa
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Premium membership subscriptions with enhanced features and benefits
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Community features and user-generated content
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Technical support and documentation
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          3. User Accounts and Eligibility
        </Typography>
        <Typography paragraph>
          3.1. Age Requirements: You must be at least 13 years old to use our Services. If you are under 18, you must have parental or guardian permission.
        </Typography>
        <Typography paragraph>
          3.2. Account Security: You are responsible for:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Maintaining the confidentiality of your account credentials
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              All activities that occur under your account
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Notifying us immediately of any unauthorized access
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          3.3. Account Information: You must provide accurate, current, and complete information during registration and keep it updated.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          4. Payments and Subscriptions
        </Typography>
        <Typography paragraph>
          4.1. Premium Subscriptions: We offer premium subscriptions with enhanced features and benefits. By purchasing a subscription, you agree to the following:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Subscription fees are billed in advance on a recurring basis
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Subscriptions automatically renew unless cancelled before the renewal date
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              You authorize us to charge your payment method for renewal fees
            </Typography>
          </li>
        </ul>
        <Typography paragraph sx={{ fontWeight: 'bold' }}>
          4.2. No Refunds Policy: All purchases and subscription payments are final and non-refundable. We do not provide refunds for:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Partial or unused subscription periods
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Technical issues beyond our control
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Dissatisfaction with the Services
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Accidental purchases
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          5. User Content and Conduct
        </Typography>
        <Typography paragraph>
          5.1. User-Generated Content: By submitting content to our Services, you:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Grant us a worldwide, non-exclusive, royalty-free license to use, modify, and distribute your content
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Represent that you own or have necessary rights to the content
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Accept responsibility for any third-party claims regarding your content
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          5.2. Prohibited Conduct: You agree not to:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Violate any laws or regulations
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Infringe on intellectual property rights
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Distribute malware or harmful code
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Engage in unauthorized scraping or data collection
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          6. Intellectual Property Rights
        </Typography>
        <Typography paragraph>
          6.1. Our Content: All content, features, and functionality, including but not limited to text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, are owned by AC Drifting Pro or its licensors.
        </Typography>
        <Typography paragraph>
          6.2. License to Use: We grant you a limited, non-exclusive, non-transferable, and revocable license to use our Services for personal, non-commercial purposes.
        </Typography>
        <Typography paragraph>
          6.3. Restrictions: You may not:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Modify, decompile, or reverse engineer any part of our Services
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Remove any copyright or proprietary notices
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Transfer your account or subscription to another person
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          7. Limitation of Liability
        </Typography>
        <Typography paragraph>
          7.1. Disclaimer: Our Services are provided "as is" without any warranties, express or implied.
        </Typography>
        <Typography paragraph>
          7.2. Limitation: To the maximum extent permitted by law, we shall not be liable for:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Any indirect, incidental, special, consequential, or punitive damages
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Loss of profits, data, use, goodwill, or other intangible losses
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Damages resulting from unauthorized access to your account
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          8. Termination
        </Typography>
        <Typography paragraph>
          8.1. We reserve the right to:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Suspend or terminate your access to our Services at any time for any reason
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Remove any content for any reason
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Modify or discontinue any part of our Services
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          8.2. Effect of Termination: Upon termination:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              All licenses and rights to use our Services will terminate
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              You must cease all use of our Services
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              No refunds will be provided
            </Typography>
          </li>
        </ul>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          9. Changes to Terms
        </Typography>
        <Typography paragraph>
          We reserve the right to modify these Terms at any time. We will notify you of any material changes by:
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              Posting the updated Terms on our website
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              Sending an email to the address associated with your account
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          Your continued use of our Services after changes become effective constitutes acceptance of the updated Terms.
        </Typography>
      </Paper>

      <Paper sx={sectionStyle}>
        <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main }}>
          10. Contact Information
        </Typography>
        <Typography paragraph>
          For questions about these Terms, please contact us through our support channels or email at support@acdriftingpro.com.
        </Typography>
      </Paper>

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          {new Date().getFullYear()} AC Drifting Pro. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
}
