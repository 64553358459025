import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  useTheme,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Chip,
} from '@mui/material';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import ModCard from '../components/mods/ModCard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import StarIcon from '@mui/icons-material/Star';
import SpeedIcon from '@mui/icons-material/Speed';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UpdateIcon from '@mui/icons-material/Update';
import { checkAdBlocker } from '../services/adBlockDetectionService';
import AdBlockerWarning from '../components/ads/AdBlockerWarning';

export default function Home() {
  const theme = useTheme();
  const [topDownloadedMods, setTopDownloadedMods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdBlocked, setIsAdBlocked] = useState(false);

  useEffect(() => {
    const fetchTopMods = async () => {
      try {
        const topModsQuery = query(
          collection(db, 'mods'),
          where('status', '==', 'approved'),
          orderBy('downloads', 'desc'),
          limit(3)
        );
        
        const snapshot = await getDocs(topModsQuery);
        const mods = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          thumbnailUrl: doc.data().screenshots && doc.data().screenshots.length > 0 
            ? doc.data().screenshots[0] 
            : '/placeholder-mod.jpg'
        }));
        setTopDownloadedMods(mods);
      } catch (error) {
        console.error('Error fetching top mods:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopMods();
  }, []);

  useEffect(() => {
    const detectAdBlocker = async () => {
      const blocked = await checkAdBlocker();
      setIsAdBlocked(blocked);
    };
    
    detectAdBlocker();
  }, []);

  const categories = [
    {
      title: 'Car Mods',
      icon: <DirectionsCarIcon sx={{ fontSize: 40 }} />,
      description: 'Browse individual car mods for drifting',
      path: '/mods/cars',
      color: theme.palette.primary.main
    },
    {
      title: 'Drift Car Packs',
      icon: <TimeToLeaveIcon sx={{ fontSize: 40 }} />,
      description: 'Get multiple drift cars in one pack',
      path: '/mods/car-packs',
      color: theme.palette.secondary.main
    },
    {
      title: 'Track Mods',
      icon: <SportsMotorsportsIcon sx={{ fontSize: 40 }} />,
      description: 'Explore custom drift tracks and circuits',
      path: '/mods/tracks',
      color: '#ff6b6b'
    }
  ];

  const premiumFeatures = [
    {
      title: 'Early Access',
      icon: <StarIcon sx={{ fontSize: 40 }} />,
      description: 'Get early access to new mods and updates',
      color: '#ffd700'
    },
    {
      title: 'Unlimited Downloads',
      icon: <CloudDownloadIcon sx={{ fontSize: 40 }} />,
      description: 'No download limits or waiting times',
      color: '#4caf50'
    },
    {
      title: 'Priority Updates',
      icon: <UpdateIcon sx={{ fontSize: 40 }} />,
      description: 'Receive mod updates before everyone else',
      color: '#2196f3'
    },
    {
      title: 'Performance Stats',
      icon: <SpeedIcon sx={{ fontSize: 40 }} />,
      description: 'Access detailed car performance statistics',
      color: '#9c27b0'
    }
  ];

  return (
    <Box sx={{ 
      minHeight: '100vh',
      background: theme.palette.mode === 'dark' 
        ? 'linear-gradient(180deg, #1a1a1a 0%, #2d2d2d 100%)'
        : 'linear-gradient(180deg, #f5f5f5 0%, #ffffff 100%)'
    }}>
      {isAdBlocked && <AdBlockerWarning />}
      {/* Hero Section with Dynamic Background */}
      <Box
        sx={{
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          background: '#000',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(135deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.5) 100%)',
            zIndex: 1,
          },
        }}
      >
        {/* Animated Background */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url(/img/bg1.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            animation: 'zoomInOut 20s infinite alternate',
            '@keyframes zoomInOut': {
              '0%': {
                transform: 'scale(1)',
              },
              '100%': {
                transform: 'scale(1.1)',
              },
            },
          }}
        />

        {/* Glowing Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at 50% 50%, rgba(255,107,107,0.1) 0%, rgba(78,205,196,0.1) 100%)',
            zIndex: 2,
          }}
        />

        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 3, py: 15 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 700,
                  mb: 2,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                AC Drifting Pro
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                sx={{ mb: 4, maxWidth: 600 }}
              >
                The Ultimate Assetto Corsa Drifting Mod Experience
              </Typography>
              <Button
                component={RouterLink}
                to="/mods"
                variant="contained"
                size="large"
                sx={{
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  color: 'white',
                  px: 4,
                  py: 2,
                  fontSize: '1.2rem',
                  '&:hover': {
                    background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
                  }
                }}
              >
                Explore Mods
              </Button>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: -20,
                    left: -20,
                    right: -20,
                    bottom: -20,
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}22, ${theme.palette.secondary.main}22)`,
                    borderRadius: '50%',
                    animation: 'pulse 2s infinite',
                  },
                  '@keyframes pulse': {
                    '0%': {
                      transform: 'scale(0.95)',
                      opacity: 0.5,
                    },
                    '70%': {
                      transform: 'scale(1)',
                      opacity: 0.75,
                    },
                    '100%': {
                      transform: 'scale(0.95)',
                      opacity: 0.5,
                    },
                  },
                }}
              >
                <img
                  src="/img/logo.png"
                  alt="AC Drifting Pro"
                  style={{
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    zIndex: 1,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        {/* Categories Section */}
        <Box sx={{ mb: 12 }}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: 700,
              mb: 6,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Browse by Category
          </Typography>
          <Grid container spacing={4}>
            {categories.map((category) => (
              <Grid item xs={12} md={4} key={category.title}>
                <Paper
                  component={RouterLink}
                  to={category.path}
                  sx={{
                    p: 4,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    textDecoration: 'none',
                    color: 'text.primary',
                    background: theme.palette.mode === 'dark'
                      ? 'linear-gradient(145deg, rgba(30,30,30,0.8), rgba(60,60,60,0.4))'
                      : 'linear-gradient(145deg, rgba(255,255,255,0.9), rgba(255,255,255,0.7))',
                    backdropFilter: 'blur(10px)',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: `0 12px 20px -10px ${category.color}40`
                    }
                  }}
                >
                  <Box sx={{ color: category.color, mb: 2 }}>
                    {category.icon}
                  </Box>
                  <Typography variant="h5" gutterBottom>
                    {category.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {category.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Popular Mods Section */}
        {!loading && topDownloadedMods.length > 0 && (
          <Box sx={{ mb: 12 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 4 
            }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                Popular Mods
              </Typography>
              <Button
                component={RouterLink}
                to="/mods"
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                sx={{
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                }}
              >
                View All Mods
              </Button>
            </Box>
            <Grid container spacing={4}>
              {topDownloadedMods.map((mod) => (
                <Grid item xs={12} md={4} key={mod.id}>
                  <ModCard mod={mod} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Premium Features Section */}
        <Box sx={{ mb: 8 }}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: 700,
              mb: 6,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Premium Features
          </Typography>
          <Grid container spacing={4}>
            {premiumFeatures.map((feature) => (
              <Grid item xs={12} sm={6} md={3} key={feature.title}>
                <Paper
                  sx={{
                    p: 4,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: theme.palette.mode === 'dark'
                      ? 'linear-gradient(145deg, rgba(30,30,30,0.8), rgba(60,60,60,0.4))'
                      : 'linear-gradient(145deg, rgba(255,255,255,0.9), rgba(255,255,255,0.7))',
                    backdropFilter: 'blur(10px)',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: `0 12px 20px -10px ${feature.color}40`
                    }
                  }}
                >
                  <Box sx={{ color: feature.color, mb: 2 }}>
                    {feature.icon}
                  </Box>
                  <Typography variant="h5" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {feature.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Button
              component={RouterLink}
              to="/premium" 
              variant="contained"
              sx={{
                bgcolor: (theme) => theme.palette.primary.main,
                color: 'white',
                borderRadius: '50px',
                px: 4,
                py: 2,
                '&:hover': {
                  bgcolor: (theme) => theme.palette.primary.dark,
                },
              }}
            >
              Get Premium Now
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
