import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  LinearProgress,
  Paper,
  Grid
} from '@mui/material';
import { Timer, Speed, Download, Block } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function DownloadTimerModal({ 
  open, 
  countdown, 
  onClose, 
  onCancel,
  mod, 
  totalWaitTime = 30 // Default to 30, but can be overridden
}) {
  const navigate = useNavigate();

  return (
    <Dialog 
      open={open} 
      maxWidth="sm" 
      fullWidth
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          // Allow closing the dialog even during countdown
          onCancel();
        }
      }}
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: 'background.paper',
          p: 2
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', pb: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Timer sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
          <Box component="span">
            Your Download is Starting Soon
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <LinearProgress 
            variant="determinate" 
            value={((totalWaitTime - countdown) / totalWaitTime) * 100}
            sx={{ 
              height: 10, 
              borderRadius: 5,
              mb: 1
            }} 
          />
          <Typography align="center" color="textSecondary">
            Download will start in {countdown} seconds...
          </Typography>
        </Box>

        <Paper sx={{ p: 3, bgcolor: 'primary.dark', mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Want Instant Downloads?
          </Typography>
          <Typography variant="body2" paragraph>
            Upgrade to Premium and enjoy these benefits:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Speed />
                <Typography>Instant downloads, no waiting</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Download />
                <Typography>Unlimited downloads</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Block />
                <Typography>Ad-free experience</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>

      <DialogActions sx={{ flexDirection: 'column', gap: 1, p: 2 }}>
        <Button 
          variant="contained" 
          fullWidth 
          onClick={() => navigate('/premium')}
          color="primary"
        >
          Upgrade to Premium
        </Button>
        <Button 
          variant="text" 
          fullWidth
          onClick={onCancel}
        >
          Cancel Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}