import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  orderBy,
  limit,
} from 'firebase/firestore';
import { db } from '../firebase/config';

export async function getGlobalStats(timeRange = 30) { // timeRange in days
  try {
    const startDate = Timestamp.fromMillis(Date.now() - (timeRange * 24 * 60 * 60 * 1000));
    const downloadsQuery = query(
      collection(db, 'downloads'),
      where('timestamp', '>=', startDate),
      orderBy('timestamp', 'desc')
    );

    const downloadDocs = await getDocs(downloadsQuery);
    const downloads = downloadDocs.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      timestamp: doc.data().timestamp.toDate()
    }));

    // Calculate daily downloads
    const dailyDownloads = {};
    const subscriptionStats = { premium: 0, free: 0 };
    
    downloads.forEach(download => {
      const date = download.timestamp.toISOString().split('T')[0];
      dailyDownloads[date] = (dailyDownloads[date] || 0) + 1;
      subscriptionStats[download.subscription]++;
    });

    // Get top downloaded mods
    const modStats = {};
    for (const download of downloads) {
      modStats[download.modId] = (modStats[download.modId] || 0) + 1;
    }

    const topModIds = Object.entries(modStats)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10)
      .map(([modId]) => modId);

    // Fetch mod details
    const modsQuery = query(
      collection(db, 'mods'),
      where('__name__', 'in', topModIds)
    );
    const modDocs = await getDocs(modsQuery);
    const modDetails = {};
    modDocs.docs.forEach(doc => {
      modDetails[doc.id] = {
        title: doc.data().title,
        thumbnailUrl: doc.data().thumbnailUrl,
        downloads: modStats[doc.id]
      };
    });

    return {
      totalDownloads: downloads.length,
      dailyDownloads,
      subscriptionStats,
      topMods: Object.entries(modDetails)
        .map(([id, data]) => ({ id, ...data }))
        .sort((a, b) => b.downloads - a.downloads)
    };
  } catch (error) {
    console.error('Error fetching global stats:', error);
    throw error;
  }
}

export async function getModStats(modId, timeRange = 30) {
  try {
    const startDate = Timestamp.fromMillis(Date.now() - (timeRange * 24 * 60 * 60 * 1000));
    const downloadsQuery = query(
      collection(db, 'downloads'),
      where('modId', '==', modId),
      where('timestamp', '>=', startDate),
      orderBy('timestamp', 'desc')
    );

    const downloadDocs = await getDocs(downloadsQuery);
    const downloads = downloadDocs.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      timestamp: doc.data().timestamp.toDate()
    }));

    // Calculate daily downloads
    const dailyDownloads = {};
    const subscriptionStats = { premium: 0, free: 0 };
    const browserStats = {};
    
    downloads.forEach(download => {
      const date = download.timestamp.toISOString().split('T')[0];
      dailyDownloads[date] = (dailyDownloads[date] || 0) + 1;
      subscriptionStats[download.subscription]++;

      // Extract browser info
      const userAgent = download.userAgent.toLowerCase();
      let browser = 'other';
      if (userAgent.includes('chrome')) browser = 'chrome';
      else if (userAgent.includes('firefox')) browser = 'firefox';
      else if (userAgent.includes('safari')) browser = 'safari';
      else if (userAgent.includes('edge')) browser = 'edge';
      
      browserStats[browser] = (browserStats[browser] || 0) + 1;
    });

    return {
      totalDownloads: downloads.length,
      dailyDownloads,
      subscriptionStats,
      browserStats
    };
  } catch (error) {
    console.error('Error fetching mod stats:', error);
    throw error;
  }
}

export async function getUploaderStats(uploaderId, timeRange = 30) {
  try {
    // Get all mods by uploader
    const modsQuery = query(
      collection(db, 'mods'),
      where('uploaderId', '==', uploaderId)
    );
    const modDocs = await getDocs(modsQuery);
    const modIds = modDocs.docs.map(doc => doc.id);

    // Get downloads for all mods
    const startDate = Timestamp.fromMillis(Date.now() - (timeRange * 24 * 60 * 60 * 1000));
    const downloadsQuery = query(
      collection(db, 'downloads'),
      where('modId', 'in', modIds),
      where('timestamp', '>=', startDate),
      orderBy('timestamp', 'desc')
    );

    const downloadDocs = await getDocs(downloadsQuery);
    const downloads = downloadDocs.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      timestamp: doc.data().timestamp.toDate()
    }));

    // Calculate statistics
    const modStats = {};
    const dailyDownloads = {};
    const subscriptionStats = { premium: 0, free: 0 };

    downloads.forEach(download => {
      // Per-mod stats
      modStats[download.modId] = (modStats[download.modId] || 0) + 1;

      // Daily downloads
      const date = download.timestamp.toISOString().split('T')[0];
      dailyDownloads[date] = (dailyDownloads[date] || 0) + 1;

      // Subscription stats
      subscriptionStats[download.subscription]++;
    });

    // Get mod details
    const modDetails = {};
    modDocs.docs.forEach(doc => {
      modDetails[doc.id] = {
        title: doc.data().title,
        thumbnailUrl: doc.data().thumbnailUrl,
        downloads: modStats[doc.id] || 0
      };
    });

    return {
      totalDownloads: downloads.length,
      dailyDownloads,
      subscriptionStats,
      mods: Object.entries(modDetails)
        .map(([id, data]) => ({ id, ...data }))
        .sort((a, b) => b.downloads - a.downloads)
    };
  } catch (error) {
    console.error('Error fetching uploader stats:', error);
    throw error;
  }
}
