import React, { useState, useEffect } from 'react';
import {
  Snackbar,
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  
  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    localStorage.setItem('analyticsConsent', 'true');
    localStorage.setItem('advertisingConsent', 'true');
    localStorage.setItem('functionalConsent', 'true');
    setOpen(false);
    window.location.reload(); // Reload to apply cookie preferences
  };

  const handleDenyAll = () => {
    localStorage.setItem('cookieConsent', 'denied');
    localStorage.setItem('analyticsConsent', 'false');
    localStorage.setItem('advertisingConsent', 'false');
    localStorage.setItem('functionalConsent', 'false');
    setOpen(false);
    window.location.reload(); // Reload to apply cookie preferences
  };

  const handleShowDetails = () => {
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#1e1e1e',
            maxWidth: '600px',
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: '#1e1e1e',
            borderRadius: '8px',
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CookieIcon sx={{ color: '#44e6f7' }} />
            <Typography variant="body1" color="white">
              We use cookies to enhance your experience
            </Typography>
          </Box>
          <Typography variant="body2" color="grey.300">
            We use cookies to analyze site traffic, personalize content, and serve targeted advertisements.
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              onClick={handleShowDetails}
              sx={{ color: '#44e6f7' }}
            >
              Cookie Settings
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleDenyAll}
              sx={{ borderColor: '#44e6f7', color: '#44e6f7' }}
            >
              Deny All
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleAcceptAll}
              sx={{ bgcolor: '#44e6f7', '&:hover': { bgcolor: '#32a852' } }}
            >
              Accept All
            </Button>
          </Box>
        </Box>
      </Snackbar>

      <Dialog
        open={showDetails}
        onClose={handleCloseDetails}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: '#1e1e1e',
            color: 'white',
          },
        }}
      >
        <DialogTitle sx={{ color: '#44e6f7' }}>Cookie Settings</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            We use different types of cookies to optimize your experience on our website.
          </Typography>
          
          <Typography variant="h6" sx={{ color: '#44e6f7', mt: 2 }}>
            Essential Cookies
          </Typography>
          <Typography variant="body2" paragraph>
            These cookies are necessary for the website to function and cannot be disabled.
          </Typography>

          <Typography variant="h6" sx={{ color: '#44e6f7', mt: 2 }}>
            Analytics Cookies
          </Typography>
          <Typography variant="body2" paragraph>
            Help us understand how visitors interact with our website by collecting and reporting information anonymously.
          </Typography>

          <Typography variant="h6" sx={{ color: '#44e6f7', mt: 2 }}>
            Advertising Cookies
          </Typography>
          <Typography variant="body2" paragraph>
            Used to provide you with relevant advertising across the internet and to measure the effectiveness of our campaigns.
          </Typography>

          <Typography variant="body2" sx={{ mt: 2 }}>
            For more information, please read our{' '}
            <Link href="/privacy" color="#44e6f7">
              Privacy Policy
            </Link>
            .
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={handleDenyAll}
            variant="outlined"
            sx={{ borderColor: '#44e6f7', color: '#44e6f7' }}
          >
            Deny All
          </Button>
          <Button
            onClick={handleAcceptAll}
            variant="contained"
            sx={{ bgcolor: '#44e6f7', '&:hover': { bgcolor: '#32a852' } }}
          >
            Accept All
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CookieConsent;
