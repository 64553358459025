import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Button,
  useTheme,
  Chip,
  Divider,
} from '@mui/material';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import { useAdmin } from '../contexts/AdminContext';
import { Edit as EditIcon } from '@mui/icons-material';
import MDEditor from '@uiw/react-md-editor';

export default function GuideDetail() {
  const { guideId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { isAdmin } = useAdmin();
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchGuide();
  }, [guideId]);

  const fetchGuide = async () => {
    try {
      const guideDoc = await getDoc(doc(db, 'guides', guideId));
      if (guideDoc.exists()) {
        setGuide({
          id: guideDoc.id,
          ...guideDoc.data()
        });
      } else {
        setError('Guide not found');
      }
    } catch (err) {
      console.error('Error fetching guide:', err);
      setError('Failed to load guide');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !guide) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Paper sx={{ p: 4, textAlign: 'center' }}>
          <Typography color="error" variant="h6">
            {error || 'Guide not found'}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate('/guides')}
            sx={{ mt: 2 }}
          >
            Back to Guides
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Cover Image */}
      {guide.coverImage && (
        <Box
          sx={{
            width: '100%',
            height: 300,
            backgroundImage: `url(${guide.coverImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: 2,
            mb: 4
          }}
        />
      )}

      <Paper sx={{ p: 4 }}>
        {/* Title Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h3" gutterBottom
            sx={{
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            {guide.title}
          </Typography>
          
          {guide.description && (
            <Typography variant="h6" color="text.secondary" paragraph>
              {guide.description}
            </Typography>
          )}

          {/* Category and Tags */}
          {guide.category && (
            <Chip
              label={guide.category}
              sx={{ mr: 1, mb: 1 }}
              color="primary"
              variant="outlined"
            />
          )}
          {guide.tags?.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              sx={{ mr: 1, mb: 1 }}
              variant="outlined"
            />
          ))}

          {/* Edit Button for Admin */}
          {isAdmin && (
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                component={Link}
                to={`/guides/edit/${guide.id}`}
              >
                Edit Guide
              </Button>
            </Box>
          )}
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Content */}
        <Box sx={{ mt: 4 }}>
          <MDEditor.Markdown 
            source={guide.content} 
            style={{ 
              background: 'transparent',
              color: theme.palette.text.primary
            }}
          />
        </Box>
      </Paper>
    </Container>
  );
}
