import React from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import {
  People as UsersIcon,
  Report as ReportIcon,
  Analytics as AnalyticsIcon,
  Extension as ExtensionIcon,
  Flag as FlagIcon,
  Settings as SettingsIcon,
  Support as SupportIcon,
} from '@mui/icons-material';
import AdminCard from '../../components/admin/AdminCard';

export default function AdminDashboard() {
  const adminCards = [
    {
      title: 'Users',
      icon: UsersIcon,
      description: 'Manage user accounts and permissions',
      path: '/admin/users',
    },
    {
      title: 'Reports',
      icon: ReportIcon,
      description: 'View and manage user reports',
      path: '/admin/reports',
    },
    {
      title: 'Support Tickets',
      icon: SupportIcon,
      description: 'Manage user support tickets',
      path: '/admin/support',
    },
    {
      title: 'Analytics',
      icon: AnalyticsIcon,
      description: 'View site analytics and statistics',
      path: '/admin/analytics',
    },
    {
      title: 'Mods Management',
      icon: ExtensionIcon,
      description: 'Manage and moderate mods',
      path: '/admin/mods-management',
    },
    {
      title: 'Content Flags',
      icon: FlagIcon,
      description: 'Review and moderate flagged content',
      path: '/admin/flags',
    },
    {
      title: 'Settings',
      icon: SettingsIcon,
      description: 'Configure admin settings',
      path: '/admin/settings',
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3, mb: 4 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Admin Dashboard
          </Typography>
        </Box>
        <Typography variant="body1" color="text.secondary" paragraph>
          Welcome to the AC Drifting Pro admin dashboard. Manage all aspects of the platform from here.
        </Typography>
      </Paper>

      <Grid container spacing={3}>
        {adminCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <AdminCard {...card} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
