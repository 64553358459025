import { 
  doc, 
  getDoc, 
  updateDoc, 
  arrayUnion, 
  arrayRemove,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { db } from '../firebase/config';

export const favoriteService = {
  async toggleFavorite(userId, modId) {
    if (!userId || !modId) {
      throw new Error('User ID and Mod ID are required');
    }

    try {
      // Reference to the user's document
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        throw new Error('User not found');
      }

      const userData = userDoc.data();
      const currentFavorites = userData.favoriteMods || [];

      // Check if mod is already in favorites
      const isFavorited = currentFavorites.includes(modId);

      // Update user's favorites
      await updateDoc(userRef, {
        favoriteMods: isFavorited 
          ? arrayRemove(modId) 
          : arrayUnion(modId)
      });

      // Update mod's favorites count
      const modRef = doc(db, 'mods', modId);
      await updateDoc(modRef, {
        favoriteCount: isFavorited 
          ? (userData.favoriteCount || 1) - 1 
          : (userData.favoriteCount || 0) + 1
      });

      return !isFavorited;
    } catch (error) {
      console.error('Error toggling favorite:', error);
      throw error;
    }
  },

  async checkIsFavorited(userId, modId) {
    if (!userId || !modId) {
      return false;
    }

    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        return false;
      }

      const userData = userDoc.data();
      const currentFavorites = userData.favoriteMods || [];

      return currentFavorites.includes(modId);
    } catch (error) {
      console.error('Error checking favorite status:', error);
      return false;
    }
  },

  async getUserFavoriteMods(userId) {
    if (!userId) {
      return [];
    }

    try {
      // Query mods that are in the user's favorites
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        return [];
      }

      const userData = userDoc.data();
      const favoriteMods = userData.favoriteMods || [];

      // If no favorites, return empty array
      if (favoriteMods.length === 0) {
        return [];
      }

      // Fetch full details of favorite mods
      const modsQuery = query(
        collection(db, 'mods'), 
        where('__name__', 'in', favoriteMods)
      );

      const modsSnapshot = await getDocs(modsQuery);
      return modsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching favorite mods:', error);
      return [];
    }
  }
};
