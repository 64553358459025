import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  Grid,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import { useAdmin } from '../contexts/AdminContext';
import { Save as SaveIcon } from '@mui/icons-material';
import MDEditor from '@uiw/react-md-editor';

export default function GuideEdit() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { guideId } = useParams();
  const { currentUser } = useAuth();
  const { isAdmin } = useAdmin();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [currentCoverImageUrl, setCurrentCoverImageUrl] = useState('');
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    content: '',
    category: '',
    tags: []
  });

  useEffect(() => {
    const fetchGuide = async () => {
      try {
        const guideDoc = await getDoc(doc(db, 'guides', guideId));
        if (!guideDoc.exists()) {
          setError('Guide not found');
          return;
        }

        const guideData = guideDoc.data();
        setFormData({
          title: guideData.title || '',
          description: guideData.description || '',
          content: guideData.content || '',
          category: guideData.category || '',
          tags: guideData.tags || []
        });
        setCurrentCoverImageUrl(guideData.coverImage || '');
      } catch (err) {
        console.error('Error fetching guide:', err);
        setError('Failed to load guide');
      } finally {
        setLoading(false);
      }
    };

    fetchGuide();
  }, [guideId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleContentChange = (value) => {
    setFormData(prev => ({
      ...prev,
      content: value || ''
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setCoverImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.title || !formData.content) {
      setError('Title and content are required');
      return;
    }

    if (!isAdmin) {
      setError('Only administrators can edit guides');
      return;
    }

    setSaving(true);
    setError('');

    try {
      // Force token refresh to ensure we have the latest claims
      await auth.currentUser.getIdToken(true);
      
      let coverImageUrl = currentCoverImageUrl;
      if (coverImage) {
        const imageRef = ref(storage, `guide-images/${Date.now()}_${coverImage.name}`);
        await uploadBytes(imageRef, coverImage);
        coverImageUrl = await getDownloadURL(imageRef);
      }

      const guideData = {
        ...formData,
        coverImage: coverImageUrl,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(doc(db, 'guides', guideId), guideData);
      setSuccess(true);
      
      // Redirect to the guide view
      setTimeout(() => {
        navigate(`/guides/${guideId}`);
      }, 1500);
    } catch (err) {
      console.error('Error updating guide:', err);
      setError(err.message || 'Failed to update guide');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom
          sx={{
            fontWeight: 700,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          Edit Guide
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Guide updated successfully! Redirecting...
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                multiline
                rows={2}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Category"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Cover Image
                </Typography>
                {currentCoverImageUrl && (
                  <Box sx={{ mb: 2 }}>
                    <img 
                      src={currentCoverImageUrl} 
                      alt="Current cover" 
                      style={{ maxWidth: '200px', borderRadius: '4px' }}
                    />
                  </Box>
                )}
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="cover-image-upload"
                />
                <label htmlFor="cover-image-upload">
                  <Button variant="outlined" component="span">
                    {currentCoverImageUrl ? 'Change Cover Image' : 'Upload Cover Image'}
                  </Button>
                </label>
                {coverImage && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected new image: {coverImage.name}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Content (Markdown)
              </Typography>
              <Box sx={{ mb: 2 }}>
                <MDEditor
                  value={formData.content}
                  onChange={handleContentChange}
                  height={400}
                  preview="edit"
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                disabled={saving}
                startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
                sx={{
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  '&:hover': {
                    background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
                  }
                }}
              >
                {saving ? 'Saving...' : 'Save Changes'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
