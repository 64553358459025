import axios from 'axios';

const PAYPAL_API_URL = process.env.REACT_APP_PAYPAL_API_URL || 'https://api-m.paypal.com';
const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_PAYPAL_CLIENT_SECRET;

// Get PayPal access token
async function getAccessToken() {
    try {
        const response = await axios.post(`${PAYPAL_API_URL}/v1/oauth2/token`, 
            'grant_type=client_credentials',
            {
                auth: {
                    username: CLIENT_ID,
                    password: CLIENT_SECRET
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
        return response.data.access_token;
    } catch (error) {
        console.error('Error getting PayPal access token:', error);
        throw new Error('Failed to authenticate with PayPal');
    }
}

// Verify subscription status with PayPal
export async function verifyPayPalSubscription(subscriptionId) {
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(
            `${PAYPAL_API_URL}/v1/billing/subscriptions/${subscriptionId}`,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error verifying PayPal subscription:', error);
        throw new Error('Failed to verify subscription with PayPal');
    }
}

// Get subscription details
export async function getSubscriptionDetails(subscriptionId) {
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(
            `${PAYPAL_API_URL}/v1/billing/subscriptions/${subscriptionId}`,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error getting subscription details:', error);
        throw new Error('Failed to get subscription details');
    }
}

// Cancel subscription
export async function cancelSubscription(subscriptionId, reason = 'Not specified') {
    try {
        const accessToken = await getAccessToken();
        await axios.post(
            `${PAYPAL_API_URL}/v1/billing/subscriptions/${subscriptionId}/cancel`,
            { reason },
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return true;
    } catch (error) {
        console.error('Error canceling subscription:', error);
        throw new Error('Failed to cancel subscription');
    }
}
