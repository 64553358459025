import { 
  collection, 
  addDoc, 
  updateDoc, 
  doc, 
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  runTransaction
} from 'firebase/firestore';
import { db } from '../firebase/config';

export const reviewService = {
  async addReview(userId, userName, modId, reviewData) {
    if (!userId || !modId) {
      throw new Error('User ID and Mod ID are required');
    }

    try {
      // Add review to reviews collection
      const reviewRef = await addDoc(collection(db, 'reviews'), {
        userId,
        userName,
        modId,
        rating: reviewData.rating,
        content: reviewData.content,
        createdAt: serverTimestamp(),
        helpfulCount: 0,
        helpfulBy: []
      });

      // Update mod's rating and review count
      const modRef = doc(db, 'mods', modId);
      await runTransaction(db, async (transaction) => {
        const modDoc = await transaction.get(modRef);
        if (!modDoc.exists()) {
          throw new Error('Mod does not exist');
        }

        const modData = modDoc.data();
        const currentRating = modData.rating || 0;
        const currentRatingCount = modData.ratingCount || 0;

        // Calculate new average rating
        const newRatingCount = currentRatingCount + 1;
        const newRating = Math.round(((currentRating * currentRatingCount) + reviewData.rating) / newRatingCount * 100) / 100;

        transaction.update(modRef, {
          rating: newRating,
          ratingCount: newRatingCount
        });
      });

      return reviewRef.id;
    } catch (error) {
      console.error('Error adding review:', error);
      throw error;
    }
  },

  async markHelpful(userId, reviewId) {
    if (!userId || !reviewId) {
      throw new Error('User ID and Review ID are required');
    }

    try {
      const reviewRef = doc(db, 'reviews', reviewId);
      
      await runTransaction(db, async (transaction) => {
        const reviewDoc = await transaction.get(reviewRef);
        if (!reviewDoc.exists()) {
          throw new Error('Review does not exist');
        }

        const reviewData = reviewDoc.data();
        const helpfulBy = reviewData.helpfulBy || [];
        const isAlreadyHelpful = helpfulBy.includes(userId);

        if (isAlreadyHelpful) {
          // Remove helpful mark
          transaction.update(reviewRef, {
            helpfulCount: Math.max(0, (reviewData.helpfulCount || 1) - 1),
            helpfulBy: arrayRemove(userId)
          });
        } else {
          // Add helpful mark
          transaction.update(reviewRef, {
            helpfulCount: (reviewData.helpfulCount || 0) + 1,
            helpfulBy: arrayUnion(userId)
          });
        }
      });

      return true;
    } catch (error) {
      console.error('Error marking review as helpful:', error);
      throw error;
    }
  }
};
