import React from 'react';
import { Box, Container, Grid, Typography, IconButton, Link as MuiLink, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DiscordIcon from '@mui/icons-material/Chat';

export default function Footer() {
  const theme = useTheme();

  const footerBackground = {
    background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, rgba(17, 17, 17, 0.95) 100%)`,
    borderTop: `1px solid rgba(255, 255, 255, 0.1)`,
    backdropFilter: 'blur(10px)',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '1px',
      background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}, transparent)`,
    }
  };

  const linkStyle = {
    color: 'text.secondary',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      transform: 'translateX(5px)',
    },
    display: 'block',
    mb: 1,
  };

  const socialIconStyle = {
    color: 'text.secondary',
    transition: 'all 0.3s ease',
    '&:hover': {
      color: theme.palette.primary.main,
      transform: 'translateY(-3px)',
    },
    mx: 1,
  };

  const sectionTitleStyle = {
    color: theme.palette.primary.main,
    fontWeight: 600,
    mb: 3,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -8,
      left: 0,
      width: '40px',
      height: '2px',
      background: `linear-gradient(90deg, ${theme.palette.primary.main}, transparent)`,
    }
  };

  return (
    <Box sx={footerBackground}>
      <Container maxWidth="lg">
        <Grid container spacing={10} sx={{ py: 6 }}>
          {/* Logo and Description */}
          <Grid item xs={12} md={3}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                mb: 2
              }}>
                AC DRIFTING PRO
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Experience the thrill of professional drifting with our premium Assetto Corsa mods. 
                Join our community of passionate drifters and unlock the ultimate drifting experience today.
              </Typography>
            </Box>
            
            {/* Social Icons */}
            <Box sx={{ display: 'flex', mb: 2 }}>
              <IconButton 
                component="a" 
                href={process.env.REACT_APP_SOCIAL_FACEBOOK} 
                target="_blank"
                rel="noopener noreferrer"
                sx={socialIconStyle} 
                aria-label="Facebook"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton 
                component="a" 
                href={process.env.REACT_APP_SOCIAL_TWITTER}
                target="_blank"
                rel="noopener noreferrer"
                sx={socialIconStyle} 
                aria-label="Twitter"
              >
                <TwitterIcon />
              </IconButton>
              <IconButton 
                component="a" 
                href={process.env.REACT_APP_SOCIAL_INSTAGRAM}
                target="_blank"
                rel="noopener noreferrer"
                sx={socialIconStyle} 
                aria-label="Instagram"
              >
                <InstagramIcon />
              </IconButton>
              <IconButton 
                component="a" 
                href={process.env.REACT_APP_SOCIAL_YOUTUBE}
                target="_blank"
                rel="noopener noreferrer"
                sx={socialIconStyle} 
                aria-label="YouTube"
              >
                <YouTubeIcon />
              </IconButton>
              <IconButton 
                component="a" 
                href={process.env.REACT_APP_SOCIAL_DISCORD}
                target="_blank"
                rel="noopener noreferrer"
                sx={socialIconStyle} 
                aria-label="Discord"
              >
                <DiscordIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={sectionTitleStyle}>
              Quick Links
            </Typography>
            <Box component="nav">
              <MuiLink component={Link} to="/" sx={linkStyle}>
                Home
              </MuiLink>
              <MuiLink component={Link} to="/premium" sx={linkStyle}>
                Premium
              </MuiLink>
              <MuiLink component={Link} to="/guidest" sx={linkStyle}>
                Guides
              </MuiLink>
              <MuiLink component={Link} to="/contact" sx={linkStyle}>
                Support
              </MuiLink>
              <MuiLink component={Link} to="/faq" sx={linkStyle}>
                FAQ
              </MuiLink>
            </Box>
          </Grid>

          {/* Mods Links */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={sectionTitleStyle}>
              Mods
            </Typography>
            <Box component="nav">
              <MuiLink component={Link} to="/mods" sx={linkStyle}>
                Featured Mods
              </MuiLink>
              <MuiLink component={Link} to="/mods/car-packs" sx={linkStyle}>
                Cars
              </MuiLink>
              <MuiLink component={Link} to="/mods/car-packs" sx={linkStyle}>
                Car Packs
              </MuiLink>
              <MuiLink component={Link} to="/mods/tracks" sx={linkStyle}>
                Tracks
              </MuiLink>
              <MuiLink component={Link} to="/upload" sx={linkStyle}>
                Submit Mod
              </MuiLink>
            </Box>
          </Grid>

          {/* Legal Links */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={sectionTitleStyle}>
              Legal
            </Typography>
            <Box component="nav">
              <MuiLink component={Link} to="/terms" sx={linkStyle}>
                Terms of Service
              </MuiLink>
              <MuiLink component={Link} to="/privacy" sx={linkStyle}>
                Privacy Policy
              </MuiLink>
            </Box>
          </Grid>
        </Grid>

        {/* Bottom Bar */}
        <Box sx={{
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          py: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}>
          <Typography variant="body2" color="text.secondary">
            {new Date().getFullYear()} AC Drifting Pro. All rights reserved.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Made with ❤️ for the drifting community
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
