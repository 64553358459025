import { 
  collection, 
  query, 
  where, 
  getDocs 
} from 'firebase/firestore';
import { db } from '../firebase/config';

export const userReportService = {
  async getUserReports(userId) {
    if (!userId) {
      throw new Error('User ID is required');
    }

    try {
      // Query reports where the user is the reporter
      const reportsQuery = query(
        collection(db, 'reports'),
        where('reportedBy', '==', userId)
      );

      const querySnapshot = await getDocs(reportsQuery);
      
      // Return an empty array if no reports found
      if (querySnapshot.empty) {
        return [];
      }

      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date()
      }));
    } catch (error) {
      // More specific error handling
      if (error.code === 'permission-denied') {
        console.error('Permission denied when fetching user reports. Please check Firestore rules.');
        throw new Error('You do not have permission to view these reports');
      } else {
        console.error('Error fetching user reports:', error);
        throw new Error('Failed to load reports. Please try again later.');
      }
    }
  }
};
