import React from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

const defaultSteps = [
  "Download the mod file",
  "Extract the downloaded file if it's a ZIP archive",
  "Copy the extracted folder to your Assetto Corsa content folder",
  "Launch Assetto Corsa and enjoy!"
];

export default function ModInstallGuide({ mod }) {
  const steps = mod.installationSteps || defaultSteps;

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Installation Guide
      </Typography>
      
      <List>
        {steps.map((step, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              {index + 1}.
            </ListItemIcon>
            <ListItemText primary={step} />
          </ListItem>
        ))}
      </List>
      
      <Button
        variant="text"
        startIcon={<ContentCopy />}
        onClick={() => {
          navigator.clipboard.writeText(steps.join('\n'));
        }}
      >
        Copy Instructions
      </Button>
    </Paper>
  );
}
