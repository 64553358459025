import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

const OnboardingContext = createContext();

export function useOnboarding() {
  return useContext(OnboardingContext);
}

export function OnboardingProvider({ children }) {
  const [onboardingState, setOnboardingState] = useState({
    isComplete: false,
    currentStep: 'welcome',
    stepsCompleted: {},
    loading: true,
  });
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      loadOnboardingState();
    } else {
      setOnboardingState(prev => ({ ...prev, loading: false }));
    }
  }, [currentUser]);

  const loadOnboardingState = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const userData = userDoc.data();
      
      const onboarding = userData?.onboarding || {};
      const isComplete = onboarding.completed || false;
      
      const stepsCompleted = {
        welcome: onboarding.welcomeTourCompleted || false,
        profile: onboarding.profileWizardCompleted || false,
        recommendations: onboarding.recommendationsViewed || false,
      };

      let currentStep = 'welcome';
      if (stepsCompleted.welcome && !stepsCompleted.profile) {
        currentStep = 'profile';
      } else if (stepsCompleted.profile && !stepsCompleted.recommendations) {
        currentStep = 'recommendations';
      }

      setOnboardingState({
        isComplete,
        currentStep,
        stepsCompleted,
        loading: false,
      });
    } catch (error) {
      console.error('Error loading onboarding state:', error);
      setOnboardingState(prev => ({ ...prev, loading: false }));
    }
  };

  const completeStep = async (step) => {
    if (!currentUser) return;

    try {
      const updates = {
        [`onboarding.${step}Completed`]: true,
        [`onboarding.${step}CompletedAt`]: new Date(),
      };

      // If this is the last step, mark onboarding as complete
      if (step === 'recommendations') {
        updates['onboarding.completed'] = true;
        updates['onboarding.completedAt'] = new Date();
      }

      await updateDoc(doc(db, 'users', currentUser.uid), updates);

      setOnboardingState(prev => ({
        ...prev,
        stepsCompleted: {
          ...prev.stepsCompleted,
          [step]: true,
        },
        isComplete: step === 'recommendations',
      }));

      // Determine next step
      if (step === 'welcome') {
        setOnboardingState(prev => ({ ...prev, currentStep: 'profile' }));
      } else if (step === 'profile') {
        setOnboardingState(prev => ({ ...prev, currentStep: 'recommendations' }));
      }
    } catch (error) {
      console.error('Error completing onboarding step:', error);
    }
  };

  const resetOnboarding = async () => {
    if (!currentUser) return;

    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        onboarding: {
          completed: false,
          welcomeTourCompleted: false,
          profileWizardCompleted: false,
          recommendationsViewed: false,
        },
      });

      setOnboardingState({
        isComplete: false,
        currentStep: 'welcome',
        stepsCompleted: {},
        loading: false,
      });
    } catch (error) {
      console.error('Error resetting onboarding:', error);
    }
  };

  const value = {
    ...onboardingState,
    completeStep,
    resetOnboarding,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
}
