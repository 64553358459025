import { 
  collection, 
  addDoc, 
  serverTimestamp 
} from 'firebase/firestore';
import { db } from '../firebase/config';

export const reportService = {
  async createReport({
    itemType, 
    itemId, 
    reportedBy, 
    reason, 
    description = ''
  }) {
    // Validate all required fields
    if (!itemType || !itemId || !reportedBy || !reason) {
      console.error('Missing report details:', { 
        itemType, 
        itemId, 
        reportedBy, 
        reason, 
        description 
      });
      throw new Error('Missing required report information');
    }

    try {
      const reportRef = await addDoc(collection(db, 'reports'), {
        itemType,
        itemId,
        reportedBy,
        reason,
        description: description || '',  // Ensure description is always a string
        status: 'pending',  // Always set status
        createdAt: serverTimestamp()  // Always set timestamp
      });

      return {
        id: reportRef.id,
        success: true,
        message: 'Report submitted successfully'
      };
    } catch (error) {
      console.error('Error creating report:', error);
      throw new Error('Failed to submit report');
    }
  }
};
