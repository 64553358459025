import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import { Box, Button, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';

const tourSteps = [
  {
    target: 'body',
    content: (
      <Box>
        <Typography variant="h6">Welcome to AC Drifting Pro! 🏎️</Typography>
        <Typography>
          Let's take a quick tour to help you get started with our platform.
        </Typography>
      </Box>
    ),
    placement: 'center',
    disableBeacon: true,
  },
  {
    target: '.search-bar',
    content: 'Search for your favorite drift mods here. Try searching by car name, track, or style!',
  },
  {
    target: '.categories-nav',
    content: 'Browse mods by categories - from cars to tracks and everything in between.',
  },
  {
    target: '.upload-button',
    content: 'Ready to share your mods? Click here to start uploading!',
  },
  {
    target: '.profile-menu',
    content: 'Access your profile, downloads, and settings from here.',
  },
  {
    target: '.subscription-status',
    content: 'Upgrade to Premium to unlock unlimited downloads and exclusive mods!',
  },
];

export default function WelcomeTour() {
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if it's the user's first visit
    const hasSeenTour = localStorage.getItem('hasSeenTour');
    if (!hasSeenTour && currentUser) {
      setRun(true);
      localStorage.setItem('hasSeenTour', 'true');
    }
  }, [currentUser]);

  const handleJoyrideCallback = async (data) => {
    const { status, index } = data;
    setStepIndex(index);

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      
      // Update user's onboarding status in Firestore
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          'onboarding.welcomeTourCompleted': true,
          'onboarding.welcomeTourCompletedAt': new Date().toISOString(),
          'onboarding.lastStep': 'profile'
        });
      }

      // Navigate to profile wizard
      navigate('/onboarding/profile');
    }
  };

  const styles = {
    tooltipContainer: {
      textAlign: 'left',
      padding: '20px',
    },
    tooltipTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    tooltipContent: {
      fontSize: '14px',
      lineHeight: '1.5',
    },
    buttonContainer: {
      marginTop: '15px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={run}
      scrollToFirstStep
      showProgress
      showSkipButton
      steps={tourSteps}
      styles={{
        options: {
          primaryColor: '#3f51b5',
          zIndex: 10000,
        },
        tooltipContainer: {
          textAlign: 'left',
        },
        buttonNext: {
          backgroundColor: '#3f51b5',
        },
        buttonBack: {
          marginRight: 10,
        },
      }}
      locale={{
        last: "Got it!",
        skip: "Skip tour",
      }}
    />
  );
}
