import React from 'react';
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function AdminCard({ title, icon: Icon, description, path }) {
  const navigate = useNavigate();

  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea 
        onClick={() => navigate(path)} 
        sx={{ 
          height: '100%',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Icon sx={{ 
              fontSize: 40, 
              mr: 2, 
              color: 'primary.main',
              transition: 'transform 0.2s',
              '.MuiCardActionArea-root:hover &': {
                transform: 'scale(1.1)',
              },
            }} />
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
