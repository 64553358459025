import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Check as ResolveIcon,
  Block as BlockIcon,
  Flag as FlagIcon,
} from '@mui/icons-material';
import { collection, query, getDocs, doc, updateDoc, deleteDoc, where, orderBy, limit, startAfter } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const ROWS_PER_PAGE = 10;

export default function Reports() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [totalReports, setTotalReports] = useState(0);
  const [selectedReport, setSelectedReport] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      try {
        const reportsQuery = query(
          collection(db, 'reports'),
          orderBy('createdAt', 'desc')
        );
        
        const snapshot = await getDocs(reportsQuery);
        const reportsList = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt instanceof Date ? data.createdAt : 
                      typeof data.createdAt === 'string' ? new Date(data.createdAt) : 
                      data.createdAt?.toDate?.() || new Date()
          };
        });
        
        setReports(reportsList);
        setTotalReports(reportsList.length);
      } catch (error) {
        console.error('Error fetching reports:', error);
        setError('Failed to load reports');
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewDetails = (report) => {
    setSelectedReport(report);
    setDetailsDialogOpen(true);
  };

  const handleResolveReport = async (reportId) => {
    try {
      await updateDoc(doc(db, 'reports', reportId), {
        status: 'resolved',
        resolvedAt: new Date().toISOString(),
        resolvedBy: currentUser.uid,
      });

      setReports(reports.map(report => 
        report.id === reportId 
          ? { ...report, status: 'resolved' }
          : report
      ));
    } catch (error) {
      console.error('Error resolving report:', error);
      setError('Failed to resolve report');
    }
  };

  const handleDeleteReport = async (reportId) => {
    try {
      await deleteDoc(doc(db, 'reports', reportId));
      setReports(reports.filter(report => report.id !== reportId));
    } catch (error) {
      console.error('Error deleting report:', error);
      setError('Failed to delete report');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Reports Management
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Content</TableCell>
                <TableCell>Reporter</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report) => (
                <TableRow key={report.id}>
                  <TableCell>
                    <Chip
                      icon={<FlagIcon />}
                      label={report.type}
                      color={report.type === 'abuse' ? 'error' : 'warning'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ maxWidth: 200 }}>
                      {report.content}
                    </Typography>
                  </TableCell>
                  <TableCell>{report.reporterName}</TableCell>
                  <TableCell>
                    <Chip
                      label={report.status}
                      color={report.status === 'resolved' ? 'success' : 'default'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(report.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleViewDetails(report)}
                      title="View details"
                    >
                      <FlagIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleResolveReport(report.id)}
                      title="Resolve report"
                      disabled={report.status === 'resolved'}
                      color="success"
                    >
                      <ResolveIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteReport(report.id)}
                      title="Delete report"
                      color="error"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={totalReports}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={ROWS_PER_PAGE}
          rowsPerPageOptions={[ROWS_PER_PAGE]}
        />

        {/* Report Details Dialog */}
        <Dialog
          open={detailsDialogOpen}
          onClose={() => setDetailsDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Report Details</DialogTitle>
          <DialogContent>
            {selectedReport && (
              <Box sx={{ pt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Type
                </Typography>
                <Typography paragraph>
                  {selectedReport.type}
                </Typography>

                <Typography variant="subtitle2" gutterBottom>
                  Content
                </Typography>
                <Typography paragraph>
                  {selectedReport.content}
                </Typography>

                <Typography variant="subtitle2" gutterBottom>
                  Reporter
                </Typography>
                <Typography paragraph>
                  {selectedReport.reporterName} ({selectedReport.reporterEmail})
                </Typography>

                <Typography variant="subtitle2" gutterBottom>
                  Reported Item
                </Typography>
                <Typography paragraph>
                  {selectedReport.itemType}: {selectedReport.itemId}
                </Typography>

                {selectedReport.status === 'resolved' && (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      Resolution
                    </Typography>
                    <Typography paragraph>
                      Resolved on {new Date(selectedReport.resolvedAt).toLocaleString()}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDetailsDialogOpen(false)}>Close</Button>
            {selectedReport && selectedReport.status !== 'resolved' && (
              <Button
                onClick={() => {
                  handleResolveReport(selectedReport.id);
                  setDetailsDialogOpen(false);
                }}
                variant="contained"
                color="primary"
              >
                Resolve Report
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
}
