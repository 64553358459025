import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Skeleton,
  Alert
} from '@mui/material';
import { collection, query, where, limit, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import ModCard from './ModCard';

const RelatedMods = ({ currentModId, category, tags = [] }) => {
  const [relatedMods, setRelatedMods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRelatedMods = async () => {
      try {
        const modsRef = collection(db, 'mods');
        let q;
        
        // If we have tags, try to find mods with matching category and tags
        if (tags && tags.length > 0) {
          q = query(
            modsRef,
            where('category', '==', category),
            where('tags', 'array-contains-any', tags.slice(0, 10)),
            where('status', '==', 'approved'),
            limit(4)
          );
          
          let querySnapshot = await getDocs(q);
          let results = querySnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(mod => mod.id !== currentModId);

          // If we don't have enough results with tags, fall back to category only
          if (results.length < 4) {
            q = query(
              modsRef,
              where('category', '==', category),
              where('status', '==', 'approved'),
              limit(4)
            );
            
            querySnapshot = await getDocs(q);
            results = querySnapshot.docs
              .map(doc => ({ id: doc.id, ...doc.data() }))
              .filter(mod => mod.id !== currentModId)
              .slice(0, 4);
          }
          
          setRelatedMods(results);
        } else {
          // If no tags, just use category matching
          q = query(
            modsRef,
            where('category', '==', category),
            where('status', '==', 'approved'),
            limit(4)
          );
          
          const querySnapshot = await getDocs(q);
          const results = querySnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(mod => mod.id !== currentModId)
            .slice(0, 4);
            
          setRelatedMods(results);
        }
      } catch (error) {
        console.error('Error fetching related mods:', error);
        setError('Failed to load related mods');
      } finally {
        setLoading(false);
      }
    };

    if (category) {
      fetchRelatedMods();
    } else {
      setLoading(false);
    }
  }, [currentModId, category, tags]);

  if (loading) {
    return (
      <Grid container spacing={2}>
        {[1, 2, 3, 4].map((item) => (
          <Grid item xs={12} key={item}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 2 }}>
              <Skeleton variant="rectangular" width={80} height={80} />
              <Box sx={{ width: '100%' }}>
                <Skeleton width="60%" />
                <Skeleton width="40%" />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  if (relatedMods.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 2 }}>
        <Typography variant="body2" color="text.secondary">
          No related mods found
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {relatedMods.map((mod) => (
        <Grid item xs={12} sm={6} md={3} key={mod.id}>
          <ModCard
            mod={mod}
            component={RouterLink}
            to={`/mods/${mod.id}`}
            compact
            sx={{
              height: '100%',
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: (theme) => theme.shadows[4]
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default RelatedMods;
